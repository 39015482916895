


import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";

import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";


import MessageEnum, {


  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End



export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  mobileOpenNotification: boolean;
  mobileOpenCompany: boolean;
  open: boolean,
  dialogType: string, 
  termsData:string,
  privacyData:string
  userDetails: string;

  // Customizable Area End
}



interface SS {
  id: any;
}



export default class ClientCompanyInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start


  getTermsConditionsApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start


    this.subScribedMessages = [

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];



    this.state = {
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      mobileOpenNotification: false,
      mobileOpenCompany:false,
      open: false,
      dialogType: '',
      termsData:"",
      privacyData:"",
      userDetails: "",
    };
    
    
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {

    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );


    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId === this.getTermsConditionsApiCallId) {     
      this.setState({termsData:responseJson?.data?.attributes?.description})      
  }   


  if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {  
    if(responseJson){
      this.setState({privacyData:responseJson?.description})

    }   

  }   

    // Customizable Area End
  }


  // Customizable Area Start

  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  async componentDidMount() {

    super.componentDidMount();
    this.getTermsCondition()
    this.getPrivacyPolicy()
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    this.handleStateChange(metaSignUpToken?.data?.attributes?.user_role,metaLoginToken?.user_role)
  }


handleClickOpen = (dialogType: string) => {
    this.setState({ open: true, dialogType });
};


handleClose = () => {

    this.setState({ open: false });
};

handleStateChange=(signup:string,login:string)=>{
  this.setState({
    userDetails: signup || login
  })
}

handleUserProfile=()=>{
  const message2: Message = 
  new Message(getName(MessageEnum.NavigationMessage));
  message2.addData(getName(MessageEnum.NavigationTargetMessage),
   "ClientSettings");
  message2.addData(getName(MessageEnum.NavigationPropsMessage), 
  this.props);

  this.send(message2)
}

handleNotifications = () => {
  const message3: Message = new Message(getName(MessageEnum.NavigationMessage));
  message3.addData(getName(MessageEnum.NavigationTargetMessage),
    "ClientNotifcation");
    
  message3.addData(getName(MessageEnum.NavigationPropsMessage)
  , this.props);
  this.send(message3);
}


handleCompanyInfo = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage),
    "ClientCompanyInformationWeb");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}
handleDashboard = () => {
  
    
    const message1: Message = new Message(getName(MessageEnum.NavigationMessage));
    message1.addData(getName(MessageEnum.NavigationTargetMessage),
    "ClientSettings");
    message1.addData(getName(MessageEnum.NavigationPropsMessage),this.props);

    this.send(message1);
}

getTermsCondition = async () => {
  

  const requestMessage = new Message(

    getName(MessageEnum.RestAPIRequestMessage)
  );


  this.getTermsConditionsApiCallId = requestMessage.messageId;


  requestMessage.addData(

    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.termsConditionEndPoint

  );



  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod

  );


  runEngine.sendMessage(requestMessage.id, requestMessage);

};
getPrivacyPolicy = async () => {
  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  
  this.getPrivacyPolicyApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.privacyPolicyEndPoint
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
  handleDrawerToggle = () => {
    this.setState({ mobileOpenNotification: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpenNotification: false });
  }

  handleDrawerToggleCompany = () => {
    this.setState({ mobileOpenCompany: true });
  }

  
  handleclosesidebarCompany = () => {
    this.setState({ mobileOpenCompany: false });
  }


  // Customizable Area End
}


