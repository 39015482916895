import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { user1,user2,user3 } from "./assets";
interface Details {
  Title: string;
  Data: string;
  Imag: string;
}
interface Task {
  dotColor:string,
  spantext:string,
  title:string,
  data:string,
  backgroundData:string,
  colordata:string,
  checkedFirst:boolean,
  checkedSecond:boolean
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: { "id": string, "type": string, "attributes": { "total_candidates": number, "sub_attributres": { "type": string, "quantity": string }[] } };
  token: string;
  errorMsg: string;
  loading: boolean;
  
  details: Details;
  DetailsUser: Details[]
  task:Task;
  TaskAll:Task[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: {"id":"0","type":"candidate","attributes":{"total_candidates":0,"sub_attributres":[{"type":"Interview with client","quantity":"0"}]}},      
      errorMsg: "",
      token: "",
      loading: false,
      details: {
        Title: "",
        Data: "",
        Imag: ""
      },
      DetailsUser: [],
      task:{
        dotColor:"",
        spantext:"",
        title:"",
        data:"",
        backgroundData:"",
        colordata:"",
        checkedFirst:false,
        checkedSecond:false
      },
      TaskAll:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getDashboardData();
    this.setState({
      DetailsUser: [
        {
          Title: "Megan Stone sent you an enquiry",
          Data: "Megan: The evidence has been double checked and will be filed today.",
          Imag: `${user1}`
        },
        {
          Title: "Maleficent Lynch sent you an enquiry",
          Data: "Maleficent: The evidence has been double checked and will be filed today.",
          Imag: `${user2}`
        },
        {
          Title: "Dominic Stuart sent you an enquiry",
          Data: "Dominic: The evidence has been double checked and will be filed today.",
          Imag: `${user3}`
        }],
      TaskAll: [
        {
          dotColor: "#355EE7",
          spantext: "12",
          title: "In Progress",
          data: "High",
          backgroundData: "#FEE2E2",
          colordata: "#DC2626",
          checkedFirst: true,
          checkedSecond: false
        },
        {
          dotColor: "#8C8C8C",
          spantext: "5",
          title: "Overdue",
          data: "Normal",
          backgroundData: "#FEF3C7",
          colordata: "#D97706",
          checkedFirst: true,
          checkedSecond: false
        }, {
          dotColor: "#059669",
          spantext: "20",
          title: "Completed",
          data: "Low",
          backgroundData: "#F5F5F5",
          colordata: "#8C8C8C",
          checkedFirst: true,
          checkedSecond: true
        }, {
          dotColor: "#F87171",
          spantext: "0",
          title: "Declined",
          data: "Low",
          backgroundData: "#F5F5F5",
          colordata: "#8C8C8C",
          checkedFirst: false,
          checkedSecond: false
        },

      ]
    })
    // Customizable Area End
  }
  
  getToken=()=>{
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({ loading: false, dashboardData: responseJson.data, errorMsg: ''})
        }
      }
      else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({ loading: false, dashboardData: {"id":"0","type":"candidate","attributes":{"total_candidates":0,"sub_attributres":[{"type":"Interview with client","quantity":"0"}]}}, errorMsg : errorReponse})
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getPercentageFun = (percentage: number) => {
    return (percentage * 100).toFixed(0) + '%'
  }
  getPercentageColor= (type: string) => {
   switch(type) {
    case 'Interview with client':
      return '#D52CD0'
    case 'Submitted for feedback':
      return '#F0E517'
    case 'Candidates expecting offer':
      return '#F44D8E'
    case 'Candidates accepted':
      return '#35C95E'
    }
  }

  handleLawfirm = () => {
    const Notimessage: Message  = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "lawfirmManagement");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(Notimessage);
  }
  handleLawyer = () => {
    const Notimessage2: Message
      = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage2.addData(getName(MessageEnum.NavigationTargetMessage),"lawyerManagement");

    Notimessage2.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);  this.send(Notimessage2);
  }
  handleService = () => {
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "serviceManagement");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }
  handleNavigationTask = () => {    
    const Notimessage1: Message = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage1.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawyer");

    Notimessage1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage1);
  }

  // Customizable Area End

}
