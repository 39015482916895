// Customizable Area Start
export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const imgHeaderBg = require('../assets/header_bg.png');
export const companyLogo = require('../assets/microsoft-logo.png');
export const profilepagebg = require('../assets/image_Header.jpg');
export const people1 = require('../assets/people1.png');
export const people2 = require('../assets/people2.png');
export const people3 = require('../assets/people3.png');
export const videobg = require('../assets/videobg.png');
export const profilebg = require('../assets/profile_bg.png');
export const profile = require('../assets/profile.png');
export const apple = require('../assets/apple.png');
export const loadingGif = require('../assets/loading.gif');
export const cardBackgroundImage = require('../assets/card_background.png');
export const right_arrow = require('../assets/right-arrow.png');
export const add_icon = require('../assets/add.png')
export const dots = require('../assets/Right_Button.png')
export const search = require('../assets/search.png')
export const boxLawyer = require("../assets/boxLawyer.png");
export const right = require("../assets/image_.png");
export const services = require("../assets/services.png");
export const remove = require("../assets/remove.png");

// icons
export const icons = {
  back: 'chevron-left',
  business: 'business',
  photo_camera: 'photo-camera',
  edit: 'edit',
  check: 'check',
  share: 'share',
  more_vert: 'more-vert',
  location_on: 'location-on',
  close: 'close',
  search: 'search',
  date_range: 'date-range',
  navigate_next: 'navigate-next',
  videocam: 'videocam',
  add_circle_outline: 'add-circle-outline',
  play_arrow: 'play-arrow',
  plus: 'plus',
  cached: 'cached',
  loop: 'loop',
  flash_off: 'flash-off',
  flash_on: 'flash-on',
  add: 'add',
  expand_more: 'expand-more',
  calendar: 'calendar'
};
// Customizable Area End
