Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.getApiMethodGet="GET";
exports.userDetalsEndPoint = "account_block/accounts/"
exports.UserEditEndPoint="bx_block_catalogue/catalogues/list_of_associated_lawyers"
exports.apimethodendpoint="bx_block_categories/categories/update_lawyerfirm_information?account_id="
exports.apiMethodTypeAddDetail= "PUT"


// Customizable Area End