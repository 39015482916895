import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import  { CountryData } from "react-phone-input-2";


// Customizable Area End

// Customizable Area Start

export interface CountryDatatype {
  id:string;
  type: string;
  attributes: {
    name: string;
};
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  open: boolean;
  opendelete: boolean;
  openchangepass: boolean;
  oldchangepassword: boolean;
  changepassword: boolean;
  showPasswordCriteria: boolean;
  errormsg: string;
  userdata: {
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    jurisdiction: string;
    firm: string;
    full_phone_number: string;
    profile_image: string;
  };
  changeimage: string;
  loadData: boolean;
  apiImage:File|null;
  TokenMain: string | undefined;
  prevCountry:string,
  countryData:CountryDatatype[],
  userDetails: string;
  lawfirmNameDisabled:boolean 
   passwordError1: string,
   countryCode:string,
  countryCodeMisMatch:boolean,
  oldpassword1:string,
  error_shown:boolean;
  popShow:boolean;
  responseShown:{
    error:string,
    success:string
  },
  settingNav:boolean
  deleteNavi:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ChangepasswordApiCallId: string = "";
  DeleteApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  UpdateUserDetailsApiCallId:string ="";
  getCountryApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      open: false,
      opendelete: false,
      openchangepass: false,
      oldchangepassword: false,
      changepassword: false,
      showPasswordCriteria: false,
      errormsg: "",
      userdata: {
        first_name: "",
        last_name: "",
        email: "",
        country_code: "",
        full_phone_number: "",
        jurisdiction: "",
        firm: "",
        profile_image:""
      },
      changeimage: '',
      loadData: true,
      apiImage:null,
      countryCode:"",
      countryCodeMisMatch:false,
      TokenMain: "",
      prevCountry:"in",
      countryData:[],
      userDetails: "",
      lawfirmNameDisabled:false,
      passwordError1: "",
      oldpassword1:'',
      error_shown:false,
      popShow:false,
      responseShown:{
        error:"",
        success:""
      },
      settingNav:false,
      deleteNavi:false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    await this.getUserProfile()
    this.getCountryList()
    this.checkLawyer()
    // Customizable Area End
  }
    // Customizable Area Start


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.ChangepasswordApiCallId) {
      if (responseJson?.errors) {
        this.asyncChangeApiMethod1(responseJson?.errors[0]?.profile)
      }
      if (responseJson?.message) {
        this.setState({ popShow:true,responseShown:{error:"",success:responseJson.message},
          passwordError1: "", oldpassword1: '',changepassword:false,oldchangepassword:false, openchangepass: false, errormsg: "", showPasswordCriteria: false, error_shown: false })
      }
    }

    if (apiRequestCallId === this.getCountryApiCallId) {
      this.setState({countryData:responseJson?.data})
     }

    if (apiRequestCallId === this.DeleteApiCallId) {
      this.asyncDeleteApiMethod1(responseJson?.errors,responseJson?.meta?.message)
    }

    if (apiRequestCallId === this.getUserProfileApiCallId) {
      if (responseJson?.errors) {
        this.setState({ popShow:true,responseShown:{error:responseJson?.errors,success:""},})
      }
      else {
        this.setState({userdata: responseJson?.user?.data?.attributes, loadData: false, changeimage:responseJson?.user?.data?.attributes?.profile_image?.url })
      }
    }
    if (apiRequestCallId === this.UpdateUserDetailsApiCallId) {
      this.asyncUpdateUser(responseJson)
    }
    // Customizable Area End
  }
   // Customizable Area End
  // Customizable Area Start

  getToken=()=>{}
  asyncUpdateUser=(responseJson:any)=>{
    if (responseJson?.message) {
      this.setState({ popShow:true,responseShown:{error:"",success:responseJson.message},settingNav:true})
      setTimeout(() => {
        this.getUserProfile()
      },500)
    }
    else if(responseJson?.errors){
      this.setState({ popShow:true,responseShown:{error:responseJson.errors[0]?.message,success:""}})
    }
  }
  asyncDeleteApiMethod1=(errors:string,message:string)=>{
    if (errors) {
      this.setState({ opendelete: false,popShow:true,responseShown:{error:errors,success:""} });
    }

    else {
      this.setState({ opendelete: false ,popShow:true,responseShown:{error:"",success:message},deleteNavi:true});
      removeStorageData("token");
      removeStorageData("userdetails");
    }
  }

  checkLawyer = () => {
    if (this.state.userDetails=="lawyer") {
      this.setState({ lawfirmNameDisabled: true })
    }
  }
  handleStateChange=(signup:string,login:string)=>{
    this.setState({
      userDetails: signup || login
    })
  }
  handleErrorBox=(a:boolean,b:any,c:string)=>{
    return a?b:c
  }
  handleRemoveError1=()=>{
    this.setState({passwordError1:""})
  }

  handleErrorChange=(errors:{ password?: string; email?: string })=>{
    if(errors.password){
    this.setState({error_shown:true})
    }
    else{
      this.setState({error_shown:false})
    }
  }

  handleFocusError=()=>{
    this.setState({ showPasswordCriteria: true , error_shown:false })
  }
  
  asyncChangeApiMethod1=(errors:string)=>{
    if (errors) {
      this.setState({ errormsg: errors })
    } 
    if (errors=="New password cannot match with old password"){
      this.setState({ passwordError1: errors })
    }
    if (errors =="Account not found"){
      this.setState({ popShow:true,responseShown:{error:errors,success:""} });

    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  showoldpassicon = () => {
    this.setState({ oldchangepassword: !this.state.oldchangepassword });
  }
  showchangepassicon = () => {
    this.setState({ changepassword: !this.state.changepassword });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleClickOpendelete = () => {
    this.setState({ opendelete: true });
  };
  handleClickOpenchangepass = () => {
    this.setState({ openchangepass: true });
  };


  handleCloseDelete = () => {
    this.setState({ opendelete: false });
  };

  handleClosechangepass = () => {
    this.setState({ openchangepass: false,showPasswordCriteria: false,errormsg:"",error_shown:false,oldchangepassword:false,changepassword:false});
  };
  handlelogout = () => {
    removeStorageData("token");
    removeStorageData("userdetails");
    this.handleLogIn()
  }
  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml']; 
  
    if (files && files[0] && allowedTypes.includes(files[0].type)) {
      this.setState({ changeimage: URL.createObjectURL(files[0]), apiImage: files[0] });
    } 
  };

  handleLogIn = () => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }
  handleDashboard = () => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "Settings");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }
  handleSettings = () => {
    
    let navigationTarget = 'DashBoard';
  
    if (this.state.userDetails === 'lawyer') {
      navigationTarget = 'LawyerDashboard';
    }
    const message1: Message = new Message(getName(MessageEnum.NavigationMessage));
    message1.addData(getName(MessageEnum.NavigationTargetMessage),
    navigationTarget);
    message1.addData(getName(MessageEnum.NavigationPropsMessage),this.props);

    this.send(message1);
  }

  handleCompanyInfo = () => {
    const message1: Message =
      new Message(getName(MessageEnum.NavigationMessage));

    message1.addData(getName(MessageEnum.NavigationTargetMessage),
      "CompanyInformation");
    message1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message1);
  }

  handleNotifications = () => {
    const Notimessage: Message
      = new Message(getName(MessageEnum.NavigationMessage));
    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "SettingsNotification");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(Notimessage);
  }



  handleUserProfile = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleUserProfiles = () => {
    const message2: Message = new Message(getName(MessageEnum.NavigationMessage));
    message2.addData(getName(MessageEnum.NavigationTargetMessage), "catalouge");
    message2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message2);
  }

  handleChangePassword = async (values: { oldpassword: string, password: string }) => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.TokenMain
    };

    this.setState({oldpassword1:values.password})
    const httpBody = {
      "data": {
        "current_password": values.oldpassword,
        "new_password": values.password
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.ChangepasswordApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/passwords"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleDeleteUser = async () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.TokenMain
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.DeleteApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },  metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ):  { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (
      metaSignUpToken?.data?.id
    ) {
      if (
        metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id
      ) {
        return metaLoginToken; }
      return metaSignUpToken; }

    if (metaLoginToken?.id) {    return metaLoginToken;  }
 return null; 
  };
  handleCountryChange(
    phone: string,  country: CountryData | {} 
  ): string {
    const { prevCountry, 
      countryCode } = this.state;
    if ('countryCode' in country && country.countryCode !== prevCountry) {
      this.setState(
        { prevCountry: country.countryCode }
      );
      return "+" + country.dialCode; 
    }
    return phone; 
  }
  

  handleCountryCode = (id:string)=>{
    this.setState(
      {countryCode:id.toLowerCase(),countryCodeMisMatch:false}
    )
  }


  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };
    this.handleStateChange(metaSignUpToken?.data?.attributes?.user_role,metaLoginToken?.user_role)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${ maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ TokenMain: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token });
  };

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  UpdateUserData = async (values:{
      firstname: string,
      lastname: string,
      country: string,
      contactNumber: string,
      email: string,
      lawfirm: string,
    }) => {
      const signupToken: string = await getStorageData("userdetails");
      const loginToken: string = await getStorageData("token");
      const metaSignUpTokens = JSON.parse(signupToken);
      const metaLoginTokens = JSON.parse(loginToken);
  
      const maintokens = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
    const header = {
      "token": maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token
    };

    const formData = new FormData();
      formData.append("first_name", values.firstname);
      formData.append("last_name", values.lastname);
      formData.append("jurisdiction", values.country);
      formData.append("full_phone_number",values.contactNumber);
      formData.append("email",values.email);
      formData.append("firm",values.lawfirm);
      if (this.state.lawfirmNameDisabled) {
        formData.append("is_shared", "true");
      }
      if(this.state.apiImage){
        formData.append("image",this.state.apiImage)
      }
      else{
        formData.append("image","")
      }
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.UpdateUserDetailsApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetalsEndPoint}${maintokens?.id || maintokens?.data?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}