//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPostData();
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Typography variant="h6">{this.state.productData.name}</Typography>
            <div>
              <img
                // key={index}
                src={dummyProduct}
                alt={`Product`}
                style={{ width: "100%", height: "300px" }}
              />
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div>
                <Typography
                  variant="h3"
                  style={{ fontWeight: 400, fontSize: "28px" }}
                  component="legend"
                >
                  Product Description
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 400,
                    fontSize: "25px",
                    color: "#ed2e0cfa",
                  }}
                  component="legend"
                >
                  <strong>
                    {" "}
                    Price :{" "}
                    <span style={{ fontSize: "22px" }}>
                      ${this.state.productData.price}
                    </span>
                  </strong>
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <div>
              <Typography
                // variant="p"
                style={{ fontWeight: 200, fontSize: "18px" }}
                component="legend"
              >
                {this.state.productData.description}
              </Typography>
            </div>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
