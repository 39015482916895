import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import StarIcon from '@material-ui/icons/Star';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Box, Typography, Button, TextField,
  Select, MenuItem,
  FormControl,
  InputAdornment,
  Chip,
  ListItemAvatar,
  Divider,
  ListItemIcon,
  Popover
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from '@material-ui/icons/Close';
import {arrow} from "./assets"
import EuroIcon from '@material-ui/icons/Euro';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorSuccessPopUp from "../../../components/src/ErrorSuccessPopUp.web";
import Avatar from '@material-ui/core/Avatar';
import { logo } from "../../catalogue/src/assets";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

// Customizable Area End

import AddEditServicesController, { Props } from "./AddEditServicesController";

const CustomSelect = styled(Select)({
    '& .MuiSelect-icon': {
      display: 'none',
    },
  });

export default class AddEditServices extends AddEditServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer = () => (

    <DrawerBox 
    style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div 
      className="toolbar" />
      <Divider />
      <div 
      className="logobox">
        <img 
        src={logo} alt="logo" className="logoimag" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div data-test-id="backbtn" className="settingbox" 
      onClick={this.handleServiceManagement}>
        <ChevronLeftIcon 
        className="lefticon" />
        <Typography 
        className="settingtext">Back</Typography>
      </div>
      <List style={{ padding: '8px 13px' }}>
        <ListItem className="listbox" 
        onClick={this.handleAdminLawyer}>
          <ListItemIcon><PermIdentityIcon 
          className="icondrawer2" /></ListItemIcon>
          <ListItemText 
          className="listtile">
            Lawyer Management
            <Typography 
            className="listtext" style={{ whiteSpace: 'none !important' }}>
              Settings related to your personal information and account credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem 
        className="listbox" 
        onClick={this.handleLawFirmManagement}>
          <ListItemIcon><PeopleAltOutlinedIcon className="icondrawer3" /></ListItemIcon>
          <ListItemText className="listtile">
            Lawfirm Management
            <Typography className="listtext">
              View Digip’s Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox1" data-test-id="ServiceManagement" onClick={this.handleServiceManagement}>
          <ListItemIcon><BusinessCenterOutlinedIcon className="icondrawer1" style={{marginBottom:"25px"}} /></ListItemIcon>
          <ListItemText className="listtile1">
            Services Management
            <Typography className="listtext">
              Manage all services here
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  )
  topbox = () => (
    <Box className="topbox">
      <Typography className="titleuser" data-test-id="Lawfirm_Management">
      {this.state.title}
      </Typography>
      <Button 
        type="submit" 
        data-test-id={!this.state.editToggel ? "editBtn" : "saveBtn"} 
        variant="outlined" 
        className="titlbtn1" 
        style={{ display: this.state.mobileOpen ? "none" : "" }}
    >
        {!this.state.editToggel ? "Save Changes" : "Save"}
    </Button>
    </Box>
  )

  addEditServices = () => {
    return (
      <Box className="rightbar" data-test-id="rightbar">  
          <Formik
          data-test-id="userupdateFormik"
          enableReinitialize={!this.state.editToggel}
            validationSchema={this.validationSchemaUser()}
            initialValues={{
              serviceType: this.state.catalougeData?.attributes?.legel_service_type_ids || [],
              serviceName: this.state.catalougeData?.attributes?.service_name || "",
              serviceDescription: this.state.catalougeData?.attributes?.service_description || "",
              additionalInformation: this.state.catalougeData?.attributes?.additional_information || "",
              minValue:this.state.catalougeData?.attributes?.start_price || "",
              maxValue:this.state.catalougeData?.attributes?.end_price || "",
              estimateddays:this.state.catalougeData?.attributes?.expected_time_estimate || "",
              associatedLawyers: this.state.selectedLawyers || [] 
            }}
            onSubmit={(values) => {
                if (this.state.editToggel) {
                    this.addNewServices(values);
                } else {
                    this.updateServices(values);
                }
            }}
          >
            {({ values, errors, handleSubmit, handleChange, touched,setFieldValue  }) => {
              return (
                <form className="thirdForm"
                  onSubmit={handleSubmit}>
                  {this.topbox()}
                  {this.errorBox(errors)}
                  <Box className="userdetailbox">
                    <Box>
                      <Box className="detailsbox">
                        <Box className="personaldetailtext">
                          <Typography>Services details</Typography>
                        </Box>
                        <Box className="personalform" style={{ marginBottom: '20px' }}>
                          <Box className="lawFirm">
                          <FormControl variant="outlined" style={{width:"100%",marginBottom: '20px',display: this.state.mobileOpen ? "none" : ""}}>
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                             Legal Service Type <span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <CustomSelect
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              inputProps={{ "data-test-id": "serviceType" }}
                              style={{ display: this.state.mobileOpen ? "none" : "",borderRadius: '8px' }}
                              name="serviceType"
                              type="text"
                              fullWidth
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                                open: this.state.isDropdownOpen,
                                getContentAnchorEl: null, 
                              }}
                              multiple
                              placeholder="Select legal services"
                              endAdornment={
                                <InputAdornment position="end" onClick={this.handleToggleDropdown} style={{cursor:'pointer'}}>
                                  <ExpandMoreIcon />
                                </InputAdornment>
                              }
                              value={values.serviceType}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onOpen={this.handleToggleDropdown}
                              onClose={this.handleToggleDropdown}
                              error={touched.serviceType && Boolean(errors.serviceType)}
                            >
                            {this.state.listOfServices.map((service:any) => (
        <StyledMenuItem key={service.id} value={service.id}>
          {service.legal_service_name}
        </StyledMenuItem>
      ))}
                            </CustomSelect>
                            {touched.serviceType && errors.serviceType && (
    <Typography variant="body2" style={{ color: '#DC2626'}}>
      {errors.serviceType}
    </Typography>
  )}
                            </FormControl>
                            
                          </Box>
                          <Box className="Email" style={{marginBottom: '20px'}}>
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Service Name<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <TextField
                              inputProps={{ "data-test-id": "serviceName" }}
                              InputProps={{
                                style: {
                                  borderRadius: '8px'
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="serviceName"
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="Write here lawfirm name"
                              value={values.serviceName}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              helperText={touched.serviceName && errors.serviceName}
                              error={touched.serviceName && Boolean(errors.serviceName)}
                            />
                          </Box>
                          <Box className="Email" style={{marginBottom: '20px',position: 'relative', display: this.state.mobileOpen ? "none" : "",}}>
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Service Description<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <TextareaAutosize
        aria-label="minimum height"
        data-test-id="serviceDescription"
        style={{
          display: this.state.mobileOpen ? "none" : "",
          borderRadius: '8px',
          width: "100%",
          fontSize: '16px',
          height: "98px",
          fontFamily: 'DM Sans',
          fontWeight: 400,
          lineHeight: '24px',
          border: touched.serviceDescription && errors.serviceDescription 
            ? "1px solid #DC2626" 
            : this.state.isFocused 
              ? "2px solid #3f51b5" 
              : "1px solid #D9D9D9",
          paddingLeft: "10px",
          paddingTop: "9px",
          outline: 'none',
        }}
        name="serviceDescription"
        minRows={3}
        maxLength={100}
        placeholder="Describe here"
        value={values.serviceDescription}
        onChange={(event) => handleChange(event)}
        onFocus={this.handleFocus} 
        onBlur={this.handleBlur}
      />
                              {touched.serviceDescription && errors.serviceDescription && (
    <Typography variant="body2" style={{ color: '#DC2626', marginTop: '4px' }}>
      {errors.serviceDescription}
    </Typography>
  )}
                              <div style={{
    position: 'absolute',
    right:'0px',
    top: '130px',
    fontSize: '12px',
    fontFamily:'Inter',
    fontWeight:400,
    lineHeight:'18px',
    color: '#334155',
  }}>
    {values.serviceDescription.length}/100
  </div>
                          </Box>
                          <Box className="Email" style={{marginBottom: '20px'}}>
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Additional information<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <TextField
                              inputProps={{ "data-test-id": "additionalInformation" }}
                              InputProps={{
                                style: {
                                  borderRadius: '8px'
                                }
                              }}
                              style={{ display: this.state.mobileOpen ? "none" : "" }}
                              name="additionalInformation"
                              type="text"
                              fullWidth
                              variant="outlined"
                              placeholder="Write additional information"
                              value={values.additionalInformation}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              helperText={touched.additionalInformation && errors.additionalInformation}
                              error={touched.additionalInformation && Boolean(errors.additionalInformation)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="detailsbox" style={{marginBottom: '20px'}}>
          
          <Box className="personaldetailtext">
            <Typography>Pricing</Typography>
          </Box>
          <div style={{display:'flex',flexDirection:'column',paddingBottom: '20px',width:'80%'}}>
          <div style={{width:'100%'}}>
          <Typography variant="subtitle1" className="firstNamePlaceHolder">
          Price Range<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            <Box className="accountsactions1">
  <Box className="priceRange">
    <Typography variant="subtitle1" className="personaldetailtext">
      From
    </Typography>
    <TextField
      inputProps={{ "data-test-id": "minValue",
        min: 0,  
        style: { 
            appearance: 'none', 
            MozAppearance: 'textfield',
            WebkitAppearance: 'none' 
          } 
      }}
      InputProps={{
        startAdornment: (
            <InputAdornment position="start">
              <EuroIcon style={{color:"#D9D9D9"}}/>
            </InputAdornment>
          ),
        style: {
          borderRadius: '8px'
        }
      }}
      style={{ display: this.state.mobileOpen ? "none" : "" }}
      name="minValue"
      type="number"
      fullWidth
      variant="outlined"
      placeholder="Min"
      value={values.minValue}
      onChange={(event) => {
        handleChange(event);
      }}
      helperText={touched.minValue && errors.minValue}
      error={touched.minValue && Boolean(errors.minValue)}
    />
    <Box style={{ margin: '0 50px', display: 'flex', alignItems: 'center' }}>
      <img src={arrow} alt="Arrow" style={{ width: '24px', height: '24px' }} />
    </Box>
    <Typography variant="subtitle1" className="personaldetailtext">
      To
    </Typography>
    <TextField
       inputProps={{ "data-test-id": "maxValue",
        min: 0,  
        style: { 
            appearance: 'none', 
            MozAppearance: 'textfield',
            WebkitAppearance: 'none' 
          } 
      }}
      InputProps={{
        startAdornment: (
            <InputAdornment position="start">
              <EuroIcon style={{color:"#D9D9D9"}}/>
            </InputAdornment>
          ),
        style: {
          borderRadius: '8px'
        }
      }}
      style={{ display: this.state.mobileOpen ? "none" : "" }}
      name="maxValue"
      type="number"
      fullWidth
      variant="outlined"
      placeholder="Max"
      value={values.maxValue}
      onChange={(event) => {
        handleChange(event);
      }}
      helperText={touched.maxValue && errors.maxValue}
      error={touched.maxValue && Boolean(errors.maxValue)}
    />
  </Box>
</Box>

          </div>
          <div>
         

          <Box className="Email">
                            
                            <FormControl fullWidth variant="outlined" style={{ display: this.state.mobileOpen ? "none" : "", marginRight: '3px' }}>
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Expected Time Estimated<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
  <CustomSelect
    labelId="week-select-label"
    id="week-select"
    name="estimateddays"
    onClick={this.handleToggleDropdown1}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      open:this.state.isDropdownOpen1,
      getContentAnchorEl: null, 
    }}
    onOpen={this.handleToggleDropdown1}
    onClose={this.handleToggleDropdown1}
    inputProps={{ "data-test-id": "estimateddays" }}
    endAdornment={
      <InputAdornment position="end" >
        <AccessTimeIcon style={{ color: "#8C8C8C",cursor:'pointer' }} onClick={this.handleToggleDropdown1}/>
      </InputAdornment>
    }
    style={{ borderRadius: '8px' }}
    value={values.estimateddays}
    onChange={(event) => {
      handleChange(event);
    }}
    error={touched.estimateddays && Boolean(errors.estimateddays)}
  >
    {Array.from({ length: 9 }, (_, index) => (
      <StyledMenuItem key={index + 1} value={index + 1}>
      {index + 1} Week{index > 0 ? "s" : ""}
      </StyledMenuItem>
    ))}
    <MenuItem value="10+">10+ Weeks</MenuItem>
  </CustomSelect>
  {touched.estimateddays && errors.estimateddays && (
    <Typography variant="body2" style={{ color: '#DC2626'}}>
      {errors.estimateddays}
    </Typography>
  )}
</FormControl>
                          </Box>
                          </div>
                          </div>
        </Box>
        <Box className="accountsbox">
          <Box className="accountdetailtext">
            <Typography>Associated Lawyers</Typography>
          </Box>
          <Box className="Email" style={{display: this.state.mobileOpen ? "none" : "",}}>
                            <Typography variant="subtitle1" className="firstNamePlaceHolder">
                            Associated Lawyers<span style={{ color: '#DC2626' }}>*</span>
                            </Typography>
                            {touched.associatedLawyers && Boolean(errors.associatedLawyers) && (
    <Typography variant="body2" style={{ color: '#DC2626' }}>
      {errors.associatedLawyers}
    </Typography>
  )}
                            {this.state?.selectedLawyers.map((lawyer:any, index:any) => (
  <Chip
    key={index}
    deleteIcon={<CloseIcon />}
    label={lawyer.full_name}
    onDelete={()=>this.removeLawyer(lawyer.account_id,setFieldValue)}
    avatar={<Avatar src={lawyer.image} />} 
    style={{
      backgroundColor: '#F1F5F9',
      fontFamily: 'DM Sans',
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '14.32px',
    }}
  />
))}

                            <Button startIcon={<AddCircleOutlineIcon />} style={{color:"#8C8C8C",textTransform:"none",fontFamily:'DM Sans',fontSize:'14px',fontWeight:700,lineHeight:'18.23px'}} onClick={this.handleClick}>
                      Add
                    </Button>
                    <Popover
  open={this.state.open}
  anchorEl={this.state.anchorEl}
  onClose={this.handleClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  PaperProps={{
    style: { width: '300px', borderRadius: '8px' },
  }}
>
  <div style={{ padding: '20px' }}>
    <IconButton
      edge="end"
      color="inherit"
      style={{ position: 'absolute', right: 18, top: 8 }}
      onClick={this.handleClose}
    >
      <CloseIcon />
    </IconButton>

    <h4 style={{ margin: 0, marginBottom: '16px', fontFamily: 'DM Sans', fontSize: '22px', fontWeight: 400, lineHeight: '28.64px', color: '#434343' }}>
      Add Lawyers
    </h4>

    <TextField
      variant="outlined"
      placeholder="Search..."
      fullWidth
      value={this.state.searchQuery}
      onChange={(e) => this.setState({ searchQuery: e.target.value })}
      style={{ marginBottom: '16px', borderRadius: '8px', fontFamily: 'DM Sans' }}
    />

    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
      <List>
        {this.state.associatedLawyers
          .filter((user:any) => {
            const fullName = user.attributes.associated_lawyer_details.full_name;
            return (
              fullName &&
              fullName.toLowerCase().includes(this.state.searchQuery.toLowerCase())
            );
          })
          .map((user:any) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar src={user.attributes.associated_lawyer_details.image} />
              </ListItemAvatar>
              <ListItemText primary={user.attributes.associated_lawyer_details.full_name} />
              <IconButton>
              {this.state?.selectedLawyers?.some((selected:any) => selected.account_id === user.attributes.associated_lawyer_details.account_id) ? (
  <CheckCircleOutlinedIcon
    style={{ color: '#355EE7',  fontWeight: 400,
      fontFamily: "DM Sans", }}
    onClick={() => this.removeLawyer(user.attributes.associated_lawyer_details.account_id,setFieldValue)}
  />
) : (
  <AddCircleOutlineIcon onClick={() => this.addLawyer(user,setFieldValue)} />
)}

              </IconButton>
            </ListItem>
          ))}
      </List>
    </div>
  </div>
</Popover>


                          </Box>
        </Box>
        {!this.state.editToggel && <Box className="accountsbox">
          <Box className="accountdetailtext">
            <Typography>Ratings</Typography>
          </Box>
          <Box className="accountsactions2">
            <Box className="logouttxt" data-test-id="logoutdBtn" >
              <Typography style={{ color: '#434343', fontWeight: 700 }}>Rate</Typography>
              <StarIcon style={{ color: '#8C8C8C', fontSize: '18px' }} />
            </Box>
            <Box className="deletetxt" >
              <Typography style={{ color: '#434343', fontWeight: 700 }}>Average Response Rate</Typography>
              <HourglassEmptyOutlinedIcon style={{ color: '#8C8C8C', fontSize: "18px" }} />
            </Box>
          </Box>
        </Box>}
        
                </form>
              )
            }}
          </Formik>
      </Box>
    )
  }

  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )
  errorBox = (errors: any) => {
    return (
      <Box mb={2}>
       {(errors.serviceType || errors.serviceName || errors.serviceDescription || errors.additionalInformation || errors.minValue|| errors.maxValue || errors.estimateddays || errors.associatedLawyers) && (
        <Box className="statusError" borderRadius={4} textAlign="center">
            <ErrorOutlineIcon style={{ marginRight: '10px' }} />
            {"Please complete your profile with valid information to proceed."}
        </Box>
        )}
      </Box>
    )
  }

  validationSchemaUser = (): Yup.ObjectSchema<any> => {
    return Yup.object().shape({
      serviceType: Yup.string().required('Service type is required'),
      serviceName: Yup.string().required('Service name is required'),
      serviceDescription: Yup.string().required('Service description is required'),
      additionalInformation: Yup.string().required('Additional information is required'),
      minValue: Yup.number().min(0, 'Minimum value must be greater than or equal to 0').required('Minimum value is required'),
      maxValue: Yup.number().min(Yup.ref('minValue'), 'Write a Valid Max Value').required('Maximum value is required'),
      estimateddays: Yup.string().required('Expected Time Estimated is required'),
      associatedLawyers: Yup.array().min(1, 'At least one associated lawyer is required')
  });
  };




  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainDiv data-test-id="catalougeMain"
        style={{
          maxHeight: this.state.mobileOpen ? '100vh' : '',
          overflow: this.state.mobileOpen ? "hidden" : ""
        }}>
             <Box className="sidebar">
          {this.drawer()}
          {this.buttonMenu()}
          {this.state.mobileOpen &&
            <>
              <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                {this.drawer()}
              </div>
            </>
          }
        </Box>
        {this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}
        {this.addEditServices()}

        {this.state.popUpShoww && <ErrorSuccessPopUp
                  showPop={this.state.popUpShoww}
                  setShowPop={(newState: boolean) => this.setState({ popUpShoww: newState })}
                  showResponse={this.state.ShowResponsee}
                  termfunction={  () => {}}
                  deletePop={this.state.LawfirmNav}
                  loginNav={this.handleLawFirmManagement}
                />}
      </MainDiv>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainDiv = styled('div')({
  width: '100%', height: '100%', display: 'flex',
  "@media (max-width:991px)": {    display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {  backgroundColor: "#fff"
  },
  "& .sidebar": {
    width: '355px',  height: '100vh',
    position: 'fixed',  top: '0',  left: '0',  borderRight: "1px solid #C3D1FF",
    "@media (max-width: 1100px)": {    width: "355px"
    },
    "@media (max-width: 991px)": {    width: '100vw',    height: '80px'
    }
  },
  "& .offcanvasbackdrop": {
    position: "fixed",  top: '0px',
    left: '355px',  width: 'calc(100% - 355px)',
    height: '100vh',  zIndex: 999,
    backgroundColor: '#000',  opacity: 0.5,
  },
  "& .rightbar": {
    width: 'calc(100% - 355px)',
    padding: "20px 170px",
    marginLeft: '355px',
    marginTop: '100px',
    "@media (max-width: 1100px)": {
      padding: '20 40px',
      width: 'calc(100% - 355px)',
      marginLeft: '355px',
    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px',
      marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: 'auto',
    margin: "0px auto",
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn1": {
    height: '44px',
    width: '163px',
    border: '1px solid #355EE7',
    borderRadius: '8px',
    padding: '10px 12px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#fff',
    backgroundColor: "#355EE7",
    fontFamily: 'DM Sans',
    textTransform: "none",
    '&:hover': {
      border:'2px solid #B9C5FF',
      boxShadow:'none !important'
    },
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px',
      padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex',
    marginTop: '20px',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 600px)": {
      display: 'block'
    },
  },
  "& .personaldetailtext": {
    width: '20%',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    color: '#8C8C8C',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '80%',
    "@media (max-width: 600px)": {
      width: '100%'
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    marginLeft: "0px"
  },
  "& .accountsbox": {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '30px',
    paddingBottom: '20px',
    borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 600px)": {
      display: 'block',
      marginTop: '10px',
    },
  },
  "& .accountdetailtext": {
    width: '20%',
    color: "#8C8C8C",
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '20px'
    },
  },
  "& .accountsactions1": {
    width: '100%',
    display: 'flex',
    gap: '80px',
    "@media (max-width: 850px)": {
      display: 'flex',
      justifyContent: 'space-between',
    },
    "@media (max-width: 600px)": {
      width: '100%',
      display: 'block'
    },
  },
  "& .accountsactions2": {
    width: '80%',
    display: 'flex',
    flexDirection:'row',
    gap: '80px',
    "@media (max-width: 850px)": {
      display: 'flex',
      justifyContent: 'space-between',
    },
    "@media (max-width: 600px)": {
      width: '100%',
      display: 'block'
    },
  },
  "& .priceRange": {
    display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: '20px',
    width: '100%',
    "@media (max-width: 850px)": {
      display: 'flex',
      justifyContent: 'space-between',
    },
    "@media (max-width: 600px)": {
      width: '100%',
      display: 'block'
    },
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '355px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .statusError": {
    display: 'flex', alignItems: 'center',
    fontFamily: "DM Sans", fontWeight: "400", fontSize: "16px", textAlign: "left",
    color: "#DC2626", marginBottom: "20px"

  },
  "& .statusErrorLogin": {
    backgroundColor: "#FEE2E2",
    padding: "20px",
    marginBottom: "20px",
    borderLeft: "4px solid #F87171",
    color: "#F87171",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400

  },
  "& .statusErrorLoginAlert": {
    backgroundColor: "#FEE2E2",
    borderLeft: "4px solid #F87171",
    color: "#F87171",
    borderRadius: '4px',
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    width: "404px",
    position: "absolute",
    left: "30px",
    bottom: "8px"
  },
})

const StyledMenuItem = styled(MenuItem)({
  "&:hover": {
    backgroundColor: "#ECF1FF",
  },
});

const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "355px",
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px", marginTop: '20px',
    marginLeft: '20px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingtext": {
    color: '#262626', fontSize: '22px',
    marginLeft: '6px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
    cursor: 'pointer'
  },

  "& .lefticon":
  {
    marginTop: '4px',
    marginLeft: '30px',
  },
  "& .listbox": {
    height: '110px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .icondrawer3":
      {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .listtile": {
        color: "#355EE7",
      }
    },
  },
  "& .listbox1": {
    height: '110px',
    backgroundColor: '#ECF1FF',
    borderRadius: '8px',
  },
  "& .icondrawer1": {
    marginBottom: '45px',
    color: '#355EE7',
    marginLeft: '18px',
  },
  "& .icondrawer3": {
    marginLeft: '18px',
    marginBottom: '25px',
    color: '#434343',
  },
  "& .icondrawer2": {
    marginLeft: '18px',
    marginBottom: '42px',
    color: '#434343',
  },

  "& .listtext": {
    fontSize: '14px',
    color: '#334155',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  "& .listtile1": {
    fontSize: "16px",
    color: '#355EE7',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer'
  },
  "& .listtile":
  {
    fontSize: "16px",
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer',
    color: '#434343',
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '355px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '355px',
    display: 'none',
  },
});

const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })

// Customizable Area End
