import React from "react";
// Customizable Area Start
import { TextField, Box, CssBaseline,  Divider,  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import {group,userClient1,userClient2,userClient3,Services,Peoples} from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PieChart, Pie, Cell } from "recharts";
import Calendar from 'react-calendar';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close' 
import { Gavel, Star, StarBorder } from "@material-ui/icons";
import EuroIcon from '@material-ui/icons/Euro';

// Customizable Area End
import ClientDashboardController, {
  Props
} from "./ClientDashboardController";

export default class ClientDashboard extends ClientDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    displayedData = []
    displayedDataLeagalService = []

    mainErrorBox = () => {
        return (<>
            {
                (this.state.Error.legalServices || this.state.Error.rating || this.state.Error.jurisdiction || this.state.Error.price) &&
                <Box className="statusError" p={2} borderRadius={4} boxShadow={2} textAlign="center">
                    {this.state.Error.isError}
                </Box>
            }
        </>);
    }

    legalServiceErrorBox = () => {
        return (<>
            {
                this.state.Error.legalServices &&
                <Box className="statusErrorCustom" style={{ color: 'red' }}>
                    {this.state.Error.legalServices}
                </Box>
            }
        </>)
    }

    jurisdictionErrorBox = () => {
        return (<>
            {
                this.state.Error.jurisdiction &&
                <Box className="statusErrorCustom" style={{ color: 'red' }}>
                    {this.state.Error.jurisdiction}
                </Box>
            }
        </>)
    }

    ratingErrorBox = () => {
        return (<>
            {
                this.state.Error.rating &&
                <Box className="statusErrorCustom" style={{ color: 'red' }}>
                    {this.state.Error.rating}
                </Box>
            }
        </>)
    }

    popUp = () => {
        return (<>
            <PopUp className="active noscroll">
                <Box className="popUpBody">
                    <Box
                        onClick={this.handleClosePopUp}
                        style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <CloseIcon
                            style={{ fontSize: '30px' }} />
                    </Box>
                    <Box style={{ fontSize: "24px", marginBottom: "20px", marginTop: '-20px' }}>Filter</Box>
                    {
                        this.mainErrorBox()
                    }
                    <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Legal Service Type</Typography><Box style={{ color: "red" }}>*</Box></Box>
                    <div
                        data-test-id="SelectlegalServicesId"
                        onClick={this.toggleArr}
                        style={{
                            border: this.state.Error.legalServices ? '1px solid red' : '1px solid #E0E0E0',
                            padding: '10px',
                            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='${this.state.isArrowUp ? '6 15 12 9 18 15' : '6 9 12 15 18 9'}'/></svg>")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPositionX: '97%',
                            backgroundPositionY: 'center',
                            backgroundSize: '20px 20px',
                            borderRadius: "10px",
                            marginBottom: "20px",
                            color: '#868282',
                            fontSize: '1rem'
                        }}
                    >Select legal Services</div>
                    {this.state.isArrowUp && (
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative', zIndex: '99999' }}>
                            <Box style={{
                                width: '95%',
                                height: '200px',
                                backgroundColor: "#fafafa",
                                borderRadius: "10px", position: 'absolute', top: '-15px',
                                padding: '0px 40px',

                            }}>
                                <Box style={{ width: '100%', marginTop: '20px' }}>
                                    <TextField
                                        onChange={this.handleInputChangeLegalService}
                                        value={this.state.legalServiceDataInputValue}
                                        InputProps={{
                                            style: {
                                                borderRadius: '10px',
                                            },
                                        }}
                                        style={{ width: '100%', borderRadius: '10px' }}
                                        variant="outlined" />
                                </Box>
                                <Box className="noscroll" style={{ overflow: "scroll", width: '100%', height: '62%' }}>
                                    {this.displayedDataLeagalService.map((item: any) => (
                                        <Box
                                            onClick={()=>this.handleSelectedLegalService(item)}
                                            key={item.id}
                                            className="hoverCountrydata"
                                            style={{
                                                display: 'flex',
                                                fontSize: '20px',
                                                padding: '10px',
                                                gap: '10px',
                                            }}>
                                            <Box> <Gavel /></Box>
                                            <Box>{item.label}</Box>
                                        </Box>)
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {
                            this.state.legalServiceDataInputValueStack.length > 0 &&
                            <Box className="noscroll" style={{
                                width: "100%",
                                height: "50px",
                                display:'flex',
                                flexWrap:'wrap',
                                overflow:'scroll',
                                gap:'5px',
                                padding:'10px',
                                margin:'-20px 0px 10px 0px'
                            }}>
                                {this.state.legalServiceDataInputValueStack.map((x:any)=>
                                    <Box 
                                    key={x.label}
                                    style={{
                                        backgroundColor: 'rgba(145, 183, 221, 0.21)',
                                        fontSize: '1.5rem',
                                        padding: '2px 5px',
                                        display: 'flex',
                                        gap: '25px',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        borderRadius: '5px',
                                    }}>
                                        <Box style={{
                                            display:'flex',
                                            gap:'10px'
                                        }}>
                                            <Box><Gavel/></Box>
                                            <Box style={{
                                                display:'flex',
                                                alignItems:'center',
                                                fontSize: '15px'
                                            }}>{x.label}</Box>
                                        </Box>
                                        <Box onClick={()=>this.handleCancelLegalServices(x.label)}><CloseIcon  style={{padding:'3px'}} /></Box>
                                    </Box>
                                )}
                            </Box>
                            // 
                        }
                    {
                    this.legalServiceErrorBox()
                    }
                    <Box>
                        <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Jurisdictions</Typography><Box style={{ color: "red" }}>*</Box></Box>
                        <div
                            data-test-id="SelectJurisdictionId"
                            onClick={this.toggleArr2}
                            style={{
                                border: this.state.Error.jurisdiction ? '1px solid red' : '1px solid #E0E0E0',
                                padding: '10px',
                                backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='${this.state.isArrowUp2 ? '6 15 12 9 18 15' : '6 9 12 15 18 9'}'/></svg>")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPositionX: '97%',
                                backgroundPositionY: 'center',
                                backgroundSize: '20px 20px',
                                borderRadius: "10px",
                                marginBottom: "20px",
                                color: '#868282',
                                fontSize: '1rem'
                            }}
                        >{
                        "Select Jurisdiction"}</div>
                        {this.state.isArrowUp2 && (
                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative', zIndex: '99999' }}>
                                <Box style={{
                                    width: '95%',
                                    height: '200px',
                                    backgroundColor: "#fafafa",
                                    borderRadius: "10px", position: 'absolute', top: '-15px',
                                    padding: '0px 40px',

                                }}>
                                    <Box style={{ width: '100%', marginTop: '20px' }}>
                                        <TextField
                                            onChange={this.handleInputChange}
                                            value={this.state.countryDataInputValue}
                                            InputProps={{
                                                style: {
                                                    borderRadius: '10px',
                                                },
                                            }}
                                            style={{ width: '100%', borderRadius: '10px' }}
                                            variant="outlined" />
                                    </Box>
                                    <Box className="noscroll" style={{ overflow: "scroll", width: '100%', height: '62%' }}>
                                        {this.displayedData.map((item: any) => (
                                            <Box
                                                onClick={() => this.handleSelectedCountry(item)}
                                                key={item.attributes.emoji_flag}
                                                className="hoverCountrydata"
                                                style={{
                                                    display: 'flex',
                                                    fontSize: '25px',
                                                    padding: '10px',
                                                    gap: '10px',
                                                }}>
                                                <Box>{item.attributes.name}</Box>
                                            </Box>)
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {
                            this.state.countryDataInputValueStack.length > 0 &&
                            <Box className="noscroll" style={{
                                width: "100%",
                                height: "50px",
                                display:'flex',
                                flexWrap:'wrap',
                                overflow:'scroll',
                                gap:'5px',
                                padding:'10px',
                                margin:'-20px 0px 10px 0px'
                            }}>
                                {this.state.countryDataInputValueStack.map((x:any)=>
                                    <Box 
                                    key={x.attributes.emoji_flag}
                                    style={{
                                        backgroundColor: 'rgba(145, 183, 221, 0.21)',
                                        fontSize: '1.5rem',
                                        padding: '2px 5px 2px 10px',
                                        display: 'flex',
                                        gap: '25px',
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                        borderRadius: '5px',
                                    }}>
                                        <Box style={{
                                            display:'flex',
                                            gap:'10px'
                                        }}>
                                            <Box style={{
                                                display:'flex',
                                                alignItems:'center',
                                                fontSize: '15px'
                                            }}>{x.attributes.name}</Box>
                                        </Box>
                                        <Box onClick={()=>this.handleCancelSelectedCountry(x.attributes.emoji_flag)}><CloseIcon  style={{padding:'3px'}} /></Box>
                                    </Box>
                                )}
                            </Box>
                            // 
                        }
                        {
                            this.jurisdictionErrorBox()
                        }
                    </Box>
                    <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Rating</Typography><Box style={{ color: "red" }}>*</Box></Box>
                    <div
                        data-test-id="RatingId"
                        onClick={this.toggleArr3}
                        style={{
                            border: this.state.Error.rating ? '1px solid red' : '1px solid #E0E0E0',
                            padding: '10px',
                            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='${this.state.isArrowUp3 ? '6 15 12 9 18 15' : '6 9 12 15 18 9'}'/></svg>")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPositionX: '97%',
                            backgroundPositionY: 'center',
                            backgroundSize: '20px 20px',
                            borderRadius: "10px",
                            marginBottom: "20px",
                            color: '#868282',
                            fontSize: '1rem'
                        }}
                    >Select Rating</div>
                    {this.state.isArrowUp3 && (
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative', zIndex: '99999' }}>
                            <Box style={{
                                width: '95%',
                                height: '200px',
                                backgroundColor: "#fafafa",
                                borderRadius: "10px", position: 'absolute', top: '-15px',
                                padding: '0px 40px',

                            }}>
                                <Box className="noscroll" style={{ overflow: "scroll", width: '100%', height: '62%' }}>
                                    {this.state.ratingOptions.map((item: any) => (
                                        <Box
                                            data-test-id={`ratingId`+item.rating}
                                            onClick={() => this.handleSelectedrating(item.rating)}
                                            key={item.id}
                                            className="hoverCountrydata"
                                            style={{
                                                display: 'flex',
                                                fontSize: '20px',
                                                padding: '10px',
                                                gap: '10px',
                                            }}>
                                            <Box style={{ display: 'flex', gap: "10px" }}>
                                                <Box style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                                                }}>
                                                    {Array.from({ length: item.rating }, (_, index) => (
                                                        <Star key={index} style={{ color: 'gold', fontSize: '40px' }} />
                                                    ))}
                                                    {Array.from({ length: (5 - item.rating) }, (_, index) => (
                                                        <StarBorder key={index} style={{ fontSize: '40px' }} />
                                                    ))}
                                                </Box>
                                                <Box style={{ padding: '10px' }}>& up</Box>
                                            </Box>
                                        </Box>)
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {
                        this.state.ratingInputValue > 0 &&
                        <Box style={{
                            display: 'flex', alignItems: 'center',
                            backgroundColor: 'rgba(115, 147, 179, 0.19)',
                            width: '35%',
                            gap: '25px',
                            height: '41px',
                            borderRadius: '5px',
                            marginBottom: '10px',
                            padding:'0 10px',
                            justifyContent:'space-between'
                        }}>
                            <Box>
                                {Array.from({ length: this.state.ratingInputValue }, (_, index) => (
                                    <Star key={index} style={{ color: 'gold', fontSize: '25px' }} />
                                ))}
                                {Array.from({ length: (5 - this.state.ratingInputValue) }, (_, index) => (
                                    <StarBorder key={index} style={{ fontSize: '25px' }} />
                                ))}
                            </Box>
                            <Box onClick={this.handleRatingCancel}><CloseIcon style={{ marginTop: '2px', padding: '2px' }} /></Box>
                        </Box>
                    }
                    {
                    this.ratingErrorBox()
                    }
                    <Box sx={{ width: '100%' }}>
                    <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Price</Typography><Box style={{ color: "red" }}>*</Box></Box>
                        <input 
                        data-test-id="sliderID"
                        style={{ outline:'none',width: '100%', borderRadius: '10px', backgroundColor: '#355EE7' }} type="range" id="slider" min="0" max={5000}
                            onChange={this.handleChangesliderbar}
                            value={this.state.SliderBarValue}></input>
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px',
                        }}>
                            <Box style={{
                                border: '1px solid #d2cfcf',
                                borderRadius: '5px',
                                padding: '10px',
                                minWidth: '60px',
                                display: 'flex',
                            }}>
                                <Box style={{display:'flex',alignItems:'center',color:'gray'}}><EuroIcon style={{fontSize:'18px',color:'#ccc5c5'}}/>{this.state.intalSlideBarValue}</Box>
                            </Box>

                            <Box style={{
                                border: '1px solid #d2cfcf',
                                borderRadius: '5px',
                                padding: '10px',
                                minWidth: '60px',
                                display: 'flex',
                            }}>
                                <Box style={{display:'flex',alignItems:'center',color:'gray'}}><EuroIcon style={{fontSize:'18px',color:'#ccc5c5'}}/> {5000}</Box>
                            </Box>
                        </Box>
                        {this.state.Error.price &&
                            <Box className="statusErrorCustom" style={{ color: 'red' }}>
                                {this.state.Error.price}
                            </Box>
                        }
                        <hr style={{ border: '0.5px solid #d2cfcf' }} />
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px 10px 0px' }}>
                            <button className="browseBtn" data-test-id="applyFilterId"
                                onClick={this.handleApplyFilter}>Apply Filter</button>
                        </Box>
                    </Box>
                </Box>
            </PopUp>
        </>)
    }
    
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {countryDataInputValue,filteredData,countryData} = this.state;
    this.displayedData = countryDataInputValue ? filteredData : countryData;

    const {legalServiceDataInputValue,filteredDataLegalService,optionsShow} = this.state;
    this.displayedDataLeagalService = legalServiceDataInputValue ? filteredDataLegalService : optionsShow;


    const getRandomDates = (
        start: string | number | Date,
        end: string | number | Date,
        count: number
      ): string[] => {
        const dates: string[] = [];
        const startDate = new Date(start);
        const endDate = new Date(end);
      
        const getRandomDate = (): Date => {
          const randomBuffer = new Uint32Array(1);
          window.crypto.getRandomValues(randomBuffer);
    
          const randomValue = randomBuffer[0] / (0xffffffff + 1);
      
          const randomTime = startDate.getTime() + randomValue * (endDate.getTime() - startDate.getTime());
          return new Date(randomTime);
        };
      
        while (dates.length < count) {
          const randomDate = getRandomDate();
          const formattedDate = randomDate.toISOString().split('T')[0];
          
          if (!dates.includes(formattedDate)) {
            dates.push(formattedDate);
          }
        }
      
        return dates;
      };
      
        const getStartAndEndOfMonth = () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return { start, end };
      };
    const { start, end } = getStartAndEndOfMonth();
    const dotDates = getRandomDates(start, end, 10);
    
    const tileClassName = ({ date }: { date: Date }): string | null => {
        const dateStr = date.toISOString().split('T')[0];
        return dotDates.includes(dateStr) ? 'dot-date' : null;
      };
      
    // Customizable Area End
    return (
         // Customizable Area Start
        <MainContainer data-test-id="ClientDashboarddMain">
            <Grid container spacing={0}>
                <Grid item lg={2} md={3} sm={1} xs={1} >
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                </Grid>
                <Grid 
                item lg={10} md={9} sm={11} xs={11} 
                className="repsonsive"
                >
                    <Box className="titleBox">
                        <Typography className="titleTxt" data-test-id="title">Dashboard</Typography>
                        <Box className="notificationbox">
                            <NotificationsOutlinedIcon className="notificationIcon" />
                            <FiberManualRecordIcon className="dotIcon" />
                            <button onClick={this.handleNavigationProject} className="browseBtn" data-test-id="new_tasks">New tasks <ControlPointIcon style={{ color: "white", marginLeft: "2px" }} /></button>
                        </Box>
                    </Box>
                    <Grid container spacing={4} data-test-id="graphId">
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Box className="MainSubBox1" onClick={this.handleNavigation} >
                                <Box className="SubBoxaa">
                                    <Typography className="SubBoxP1" >
                                        <FolderOpenOutlinedIcon className="foldericon" />
                                        <Box className="SubBoxP2" >
                                            <Typography className="smallBox">Tasks</Typography>
                                            <Typography style={{ color: "#8C8C8C", fontSize: "10px", }} variant="caption" >
                                                Add more tasks and manage freely
                                            </Typography>
                                        </Box>
                                    </Typography>
                                    <Typography className="SubBoxP3">
                                        <FilterListIcon />
                                    </Typography>
                                </Box>
                                <Box className="SubBox2">
                                    <Box className="SubBox2P1">
                                        <Box style={{ width: "120%", height: "120%" }}>
                                            <div className="SubBox2P2">
                                                <PieChart width={140} height={140}>
                                                    <Pie
                                                        data={this.state?.MainData}
                                                        cx={62.5}
                                                        cy={62.5}
                                                        innerRadius={40}
                                                        outerRadius={60}
                                                        fill="#8884d8"
                                                        paddingAngle={2}
                                                        dataKey="value"
                                                    >
                                                        {this.state.MainData?.map((entry, index) => (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={this.state.COLORS[index % this.state.COLORS.length]}
                                                            />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                                <Typography className="SubBox2P3">
                                                    {this.state.MainData?.reduce((acc, task) => acc + task.value, 0)}
                                                    <Typography className="SubBox2P4">Tasks</Typography>
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Box
                                      className="repsonsiveCard"
                                    >
                                        {this.state.MainData?.map((task, index) => (
                                            <Box
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "132px",
                                                    height: "16px",
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        marginBottom: '3px',
                                                        width: "12px",
                                                        height: "12px",
                                                        backgroundColor: this.state.COLORS[index],
                                                        borderRadius: "12px",
                                                    }}
                                                ></Typography>
                                                <Typography
                                                    style={{
                                                        fontWeight: "400",
                                                        fontSize: "12px",
                                                        marginRight: "11px",
                                                    }}
                                                >
                                                    {task.name}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        display: "flex",
                                                        fontWeight: "700",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {task.value} Tasks
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box className="SubBox3aa">
                                    <Box className="SubBox3P1">
                                        <span >People Involved</span>
                                        <Typography>
                                            <img className="SubBox3Img" src={Peoples} alt="" />
                                        </Typography>
                                    </Box>
                                    <Box className="SubBox3P2">
                                        <Typography className="SubBox3P3">See More</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Box className="MainBox2" data-test-id="servicesId">
                                <Box className="SubBoxMain1">
                                    <Typography className="SubBoxP1" >
                                        <img src={Services} />
                                        <Box className="SubBoxP2" >
                                            <Typography className="smallBox">Services </Typography>
                                            <Typography className="SubBoxSmall" variant="caption">
                                                What legal help do you need today?
                                            </Typography>
                                        </Box>
                                    </Typography>
                                    <Typography className="SubBoxP3">
                                        <FilterListIcon />
                                    </Typography>
                                </Box>
                                <Box className="SubBoxMain2">
                                    <Box className="SubBoxMain3">
                                        <Box className="SubBoxMain4">
                                            <Typography className="SubBoxSmall2">Contract Cases</Typography>
                                            <Typography className="subSmallBox"> 5 Lawfirms </Typography>
                                        </Box>
                                        <Box className="SubBoxMain4">
                                            <Typography className="SubBoxSmall2">Kayma Cases </Typography>
                                            <Typography className="subSmallBox">5 Lawfirms</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="SubBoxMain3" >
                                        <Box className="SubBoxMain4" >
                                            <Typography className="SubBoxSmall2"> Family Cases </Typography>
                                            <Typography className="subSmallBox"> 5 Lawfirms </Typography>
                                        </Box>
                                        <Box className="SubBoxMain4" >
                                            <Typography className="SubBoxSmall2"> Contract Cases</Typography>
                                            <Typography className="subSmallBox">5 Lawfirms </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="SubBoxMain3" >
                                        <Box className="SubBoxMain4" >
                                            <Typography className="SubBoxSmall2" >Labour Cases </Typography>
                                            <Typography className="subSmallBox">5 Lawfirms</Typography>
                                        </Box>
                                        <Box className="SubBoxMain4">
                                            <Typography className="SubBoxSmall2" >Civil Cases</Typography>
                                            <Typography className="subSmallBox">5 Lawfirms</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="boxSmallP1" >
                                        <Box style={{ display: "flex", alignItems: "center", }}>
                                            <Typography style={{ fontSize: "11px", display: "flex", color: "#4F46E5", }} >See More</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Box className="MainBox31" data-test-id="messageId">
                                <Box className="SubBox11">
                                    <Typography className="SubBoxP1">
                                        <ChatBubbleOutlineOutlinedIcon className="foldericon1" />
                                        <Box className="SubBoxP2">
                                            <Typography className="smallBox" > Recent Messages</Typography> </Box>
                                    </Typography>
                                    <Typography className="MainBox3P1">
                                        <FilterListIcon />
                                    </Typography>
                                </Box>
                                <Box className="MainBoxSu3">
                                    <Box className="SubBoxa">
                                        <Box className="SubBoxb">
                                            <Box className="SubBoxc">
                                                <img className="usersub" src={userClient1} alt="" />
                                                <Box className="SubBoxd">
                                                    <Typography className="SubBoxe"> Megan Smith <FiberManualRecordIcon style={{ color: '#355EE7', fontSize: '15px', marginLeft: '5px' }} /></Typography>
                                                    <Typography className="SubBoxf">
                                                        I’ve just received an e-mail fro...
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography className="SubBoxg"> 3h
                                                <Box className="SubBoxh" >
                                                    <Typography className="SubBoxi">2</Typography>
                                                </Box>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className="SubBoxa">
                                        <Box className="SubBoxb">
                                            <Box className="SubBoxc">
                                                <img className="usersub" src={userClient2} alt="" />
                                                <Box className="SubBoxd">
                                                    <Typography className="SubBoxj">Christiana Walsh </Typography>
                                                    <Typography className="SubBoxf">
                                                        You: Do you know where do w...
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{ marginTop: "5px", }}>
                                                <Typography className="SubBoxk"> 3h </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className="SubBoxa">
                                        <Box className="SubBoxb">
                                            <Box className="SubBoxc">
                                                <img className="usersub" src={userClient3} alt="" />
                                                <Box className="SubBoxd">
                                                    <Typography className="SubBoxe">Megan Smith</Typography>
                                                    <Typography className="SubBoxf">
                                                        You: Great, thanks
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography className="SubBoxg">3h
                                                <Box className="SubBoxh">
                                                    <Typography className="SubBoxi">2</Typography>
                                                </Box>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="SubBoxmlk" style={{ marginTop: '25px' }}>
                        <Grid container spacing={4}>
                            <Grid item lg={8} md={12} xs={12}>
                                <CssBaseline />
                                <Box className="TableBoxCss">
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "10px" }}>
                                        <Typography className="TableContentBox">
                                            <img src={group} alt="invoice" style={{ marginLeft: '20px', marginTop: "5px" }} />
                                            <span style={{
                                                marginTop: "5px"
                                            }}>Invoices</span>
                                        </Typography>
                                        <Typography style={{
                                            marginRight: "25px"
                                        }}><FilterListIcon /></Typography>
                                    </Box>
                                    <TableContainer className="SubTableBox">

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="TableTitleText">Invoice Id</TableCell>
                                                    <TableCell className="TableTitleText">Lawfirm/Lawyer</TableCell>
                                                    <TableCell className="TableTitleText">Amount</TableCell>
                                                    <TableCell className="TableTitleText">Status</TableCell>
                                                    <TableCell className="TableTitleText"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.rows?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className="SubBoxy">{row.id}</TableCell>
                                                        <TableCell className="tableImgBox">
                                                            <img src={row.img} alt="avatar" className="SubUserImg" />
                                                            <Box style={{ display: 'inline-block' }}>
                                                                <Typography className="SubDivA">{row.lawfirm}</Typography>
                                                                <Typography className="SubDivB" >{row.task}</Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="SubDivC">{row.amount}</TableCell>
                                                        <TableCell >
                                                            <Typography className="SubDivI" style={{ color: `${row.color}`, background: `${row.background}`, width: 'fit-content' }}>{row.status}</Typography> </TableCell>
                                                        <TableCell>
                                                            <Typography style={{ textAlign: 'right' }}><MoreVertIcon /></Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box className="SubDivJ">
                                    <Box style={{ height: "40px", display: "flex", }}>
                                        <Typography className="SubBoxp">
                                            <Box className="SubBoxq">
                                                <Typography style={{ display: "flex", justifyContent: "flex-start", }}>
                                                    <RemoveRedEyeIcon className="foldericon3" /> <span style={{ marginTop: '10px', marginLeft: '5px' }}>Explore</span>
                                                </Typography>
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <Box className="SubDivK">
                                        <Box className="SubDivL">
                                            <PermIdentityOutlinedIcon />
                                            <Typography style={{ marginTop: '5px' }}> Lawyer Catalogue</Typography>
                                        </Box>
                                        <Typography data-test-id="openFilterPopUpId" onClick={this.handleClosePopUp}><KeyboardArrowRightIcon /></Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={12} xs={12}>
                                <Box className="SubBoxMCal">
                                    <Box className="SubDivN">
                                        <Box className="SubBoxo">
                                            <Typography className="SubBoxp">
                                                <CalendarTodayIcon className="foldericon2" />
                                                <Box className="SubBoxq">
                                                    <Typography className="SubBoxr">Calender</Typography>
                                                </Box>
                                            </Typography>
                                            <Typography className="SubBoxs"><MoreVertIcon /></Typography>
                                        </Box>
                                        <Calendar tileClassName={tileClassName} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Box>{this.state.openFilterPopUp && this.popUp()}</Box>
        </MainContainer >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled('div')({
    backgroundColor:'#fff',
    fontFamily:'DM Sans',
    "& .react-calendar": {
        border: "none !important",
        lineHeight: "1.9rem !important",
        backgroundColor: "none !important",
        textDecoration: "none !important",
        width:'97%'
    },
    "& .react-calendar__tile--now": {
        background: "none !important",
        border: "1px solid #F87171 !important",
        borderRadius: "50px",
    },
    "& .react-calendar__tile--active": {
        border: "1px solid #000000 !important",
        borderRadius: "50px",
        color: "#000000 !important",
        background: "none !important",
    },
    "& .react-calendar__tile": {
        padding: "6px 8.6667px !important",
        background: "none !important",
        position: "relative"
    },
    "& .react-calendar__month-view__weekdays__weekday > abbr": {
        textDecoration: "none",
    },
    "& .react-calendar__navigation__arrow_react-calendar__navigation___prev2-button": {
        display: "none",
    },
    "& .react-calendar__month-view__weekdays": {
            textTransform: "none",
            fontWeight: 400,
            fontSize: "14px !important",
            color: "#595959 !important",
    },
    "& .foldericon": {
        padding: '7px',
        borderRadius: '50%',
        color: '#fff',
        background: '#355EE7',
        fontSize: '37px'
    },
    "& .foldericon1": {
        padding: '7px',
        borderRadius: '50%',
        color: '#fff',
        background: '#4872FF',
        fontSize: '37px'
    },
    "& .foldericon2": {
        padding: '7px',
        borderRadius: '50%',
        color: '#fff',
        background: '#2348C5',
        fontSize: '37px',
        zIndex: 3
    },
    "& .foldericon3": {
        padding: '7px',
        borderRadius: '50%',
        color: '#fff',
        background: '#355EE7',
        fontSize: '37px',
        zIndex: 3
    },
    "& .MainSubBox1": {
        background: "white",
        height: "290px",
        padding: "10px, 20px, 10px, 20px",
        borderRadius: "16px",
        boxShadow: "2px 3px 6px 3px #0000000F",
        fontStyle: "DM Sans",
        marginLeft:'15px',
        "@media (max-width: 959px)": {
            marginRight:'30px',
            width:"390px"
        },
    },
    "& .SubBox11": {
        height: "44px",
        display: "flex",
        "@media (max-width: 959px)": {
            marginRight:'50px',
            width:"390px",
        },
    },
    "& .SubBoxaa": {
        height: "44px",
        display: "flex",
        "@media (max-width: 959px)": {
            marginRight:'50px',
            width:"390px",
        },
    },
    "& .SubBoxP1": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "12px",
        marginLeft: "10px",
        padding: "6px",
        width: "30rem",
        marginTop: "10px",
    },
    "& .SubBoxP2": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    "& .smallBox": {
        display: "flex",
        justifyContent: "flex-start",
    },
    "& .SubBoxP3": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "20px",
    },
    "& .SubBox2": {
        height: "100px",
        padding: "",
        display: "flex",
        marginTop: "12px",
    },
    "& .SubBox2P1": {
        width: "300px",
        height: "128px",
        overflow: "hidden",
        marginLeft: "7px",
    },
    "& .SubBox2P2": {
        position: "relative",
        width: "140px",
        height: "140px",
    },
    "& .SubBox2P3": {
        position: "absolute",
        top: "50%",
        left: "48%",
        transform: "translate(-50%, -50%)",
        margin: 0,
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "400",
    },
    "& .SubBox2P4": {
        fontSize: "12px",
        fontWeight: "400",
        color: "#595959",
    },
    "& .SubBox3aa": {
        height: "52px",
        padding: "20px",
        marginTop: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    "& .SubBox3P1": {
        display: "flex",
        justifyContent: "flex-start",
        fontSize: "11px",
        fontWeight: "400",
        color: "#8C8C8C",
        flexDirection: "column",
        alignItems: "start",
        width: "15rem",
    },
    "& .SubBox3Img": {
        width: "92px",
        height: "48px",
        borderRadius: "30px",
    },
    "& .SubBox3P2": {
        display: "flex",
        justifyContent: "flex-end",
        justifyItems: "center",
        alignItems: "center",
    },
    "& .SubBox3P3": {
        fontSize: "11px",
        display: "flex",
        color: "#4F46E5",
        marginTop:"30px",
        marginLeft:'45px'
    },
    "& .MainBox2": {
        backgroundColor: "white",
        height: "290px",
        padding: "10px, 20px, 10px, 20px",
        borderRadius: "16px",
        boxShadow: "2px 3px 6px 3px #0000000F",
        "@media (max-width: 959px)": {
            width:"390px",
            marginLeft:'15px'
        }
    },
    "& .SubBoxMain1": {
        height: "44px",
        display: "flex",
        "@media (max-width: 959px)": {
            marginRight:'50px',
            width:"390px",
        },
    },
    "& .SubBoxSmall": {
        fontSize: "10px",
        color: "#8C8C8C",
    },
    "& .SubBoxMain2": {
        height: "290px",
        padding: "0px",
        display: "flex",
        marginTop: "1rem",
        flexDirection: "column",
        gap: "10px",
        margin:'auto'
    },
    "& .SubBoxMain3": {
        display: "flex",
        gap: "1rem",
        justifyContent:'center'
    },
    "& .SubBoxMain4": {
        width: "40%",
        height: "55px",
        boxShadow: "2px 3px 6px 3px #0000000F",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "6px",
        borderLeft: "4px solid #355EE7",
        padding: "10px 10px 10px 30px",
    },
    "& .SubBoxSmall2": {
        fontSize: "11px",
        fontWeight: "400",
        color: "#2348C5",
    },
    "& .subSmallBox": {
        fontSize: "11px",
        fontWeight: "400",
        color: "#8C8C8C",
    },
    "& .boxSmallP1": {
        height: "52px",
        padding: "0px",
        marginTop: "-10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .MainBox31": {
        backgroundColor: "white",
        height: "290px",
        padding: "10px, 20px, 10px, 20px",
        borderRadius: "16px",
        boxShadow: "2px 3px 6px 3px #0000000F",
        marginRight:"15px",
        "@media (max-width: 1279px)": {
            marginLeft:'15px',
            width:"390px",
        }
    },
    "& .MainBox3P1": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        marginTop: "7px",
        
    },
    "& .MainBoxSu3": {
        height: "290",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
    },
    "& .SubBoxa": {
        display: "flex",
        gap: "1rem",
        position:'relative'
    },
    "& .SubBoxb": {
        height: "44px",
        display: "flex",
        justifyContent:'space-between',
        "@media (max-width: 1279px)": {
            width:"330px",
        }
    },
    "& .SubBoxc": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "7px",
        marginLeft: "10px",
        padding: "6px",
        marginTop: "10px",
        "@media (max-width: 1279px)": {
            width:"330px",
        }
    },
    "& .usersub": {
        width: "44px",
        height: "44px",
        borderRadius: '50%'
    },
    "& .SubBoxd": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    "& .SubBoxe": {
        display: "flex",
        justifyContent: "flex-start",
        color: "#355EE7",
        fontWeight: "700",
        fontSize: "14px",
    },
    "& .SubBoxf": {
        fontWeight: "400",
        color: "#595959",
        fontSize: "14px",
        display: "flex",
        justifyContent: "flex-start",
    },
    "& .SubBoxg": {
        display: "flex",
        justifyContent: "center",
        alignItems: "right",
        marginTop: "7px",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: "400",
        flexDirection: "column",
        position:'absolute',
        right:'10px'
    },
    "& .SubBoxh": {
        width: "20px",
        height: "20px",
        borderRadius: "12px",
        backgroundColor: "#DCECFF",
    },
    "& .SubBoxi": {
        fontSize: "14px",
        fontWeight: "700",
        color: "#355EE7",
        marginLeft: "6px",
    },
    "& .SubBoxj": {
        display: "flex",
        justifyContent: "flex-start",
        fontWeight: "700",
        fontSize: "14px",
    },
    "& .SubBoxk": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: "400",
        flexDirection: "column",
        position:'absolute',
        right:'13px'
    },
    "& .SubBoxMCal": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
        flexDirection: "column",
    },
    "& .SubBoxmlk": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
        flexDirection: "column",
    },
    "& .SubBoxo": {
        height: "44px",
        display: "flex",
        justifyContent:'space-between'
    },
    "& .SubBoxp": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
    },
    "& .SubBoxq": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    "& .SubBoxr": {
        display: "flex",
        justifyContent: "flex-start",
    },
    "& .SubBoxs": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "7px",
    },
    "& .SubBoxy": {
        fontWeight: "400",
        fontSize: "11px",
    },
    "& .tableImgBox":{
        fontSize: '11px', 
        display: 'flex', 
        alignItems: 'center',
        "@media (max-width: 1279px)": {
            display:'block'
        }
    },
    "& .SubUserImg": {
        width: "32px",
        height: "32px",
        borderRadius: '50%'

    },
    "& .SubDivA": {
        fontWeight: "400",
        fontSize: "11px",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "start"
    },
    "& .SubDivB": {
        fontWeight: "400",
        fontSize: "11px",
        color: "#8C8C8C",
    },
    "& .SubDivC": {
        fontWeight: "400",
        fontSize: "11px",
    },
    "& .SubDivD": {
        fontWeight: "400",
        fontSize: "11px",
        marginRight: "142px",
        padding: '2px 8px',
        background: '#F5F5F5',
        color: '#8C8C8C',
        borderRadius: '40px'
    },
    "& .SubDivE": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
        justifyItems: "center",
        gap: "1rem",
        marginTop: "5px",
    },
    "& .SubDivF": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        gap: "1rem", padding: "",
        fontWeight: "400",
        fontSize: "11px",
    },
    "& .SubDivG": {
        fontWeight: "400",
        fontSize: "11px",
        marginRight: "142px",
        padding: '2px 8px',
        background: '#D1FAE5',
        color: '#059669',
        borderRadius: '40px'
    },
    "& .SubDivH": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        gap: "1rem",
        padding: "",
        fontWeight: "400",
        fontSize: "11px",
    },
    "& .SubDivI": {
        fontWeight: "400",
        fontSize: "11px",
        padding: '2px 8px',
        borderRadius: '40px',
    },
    "& .SubDivJ": {
        marginTop:'10px',
        backgroundColor: "white",
        height: "115px",
        padding: "10px 20px",
        marginLeft:'15px',
        borderRadius: "16px",
        boxShadow: "2px 3px 6px 3px #0000000F",
        "@media (max-width: 1279px)": {
            marginRight:'30px',
        },
    },
    "& .SubDivK": {
        padding: "10px 20px",
        height: "40px",
        display: "flex",
        marginTop: "4px",
        backgroundColor: "#ECF1FF",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        "@media (max-width: 1279px)": {
            marginRight:'30px',
        },
    },
    "& .SubDivL": {
        display: "flex",
        alignItems: "center",
        color: "#595959",
        fontWeight: "400",
        fontSize: "14px"
    },
    "& .SubDivN": {
        backgroundColor: "white",
        height: "455px",
        padding: "20px",
        borderRadius: "16px",
        boxShadow: "1px 2px 7px 6px #0000000F",
        marginLeft:'0px',
        width:'97%',
        "@media (max-width: 1279px)": {
            marginRight:'30px',
            width:"390px",
            marginLeft:'15px'
        },
    },
    "& .titleBox":{
      display:'flex',
      justifyContent:'space-between',
      padding:'20px 40px'
    },
    "& .titleTxt":{
      fontSize:'32px',
      fontFamily:'DM Sans',
      fontWeight:700,
      color:'#000000',
      position:'relative'
    },
    "& .notificationbox":{
      position:'relative',
      display:'flex'
    },
    "& .notificationIcon":{
      padding:'10px',
      border:'1px solid #C3D1FF',
      borderRadius:'8px',
      color:'#355EE7',
      fontSize:'47px'
    },
    "& .dotIcon":{
      position:'absolute',
      top:'-3px',
      left:'38px',
      color:'#355EE7',
      fontSize:'14px'
    },
    "& .browseBtn":{
        height:"46px",
        background:'#355EE7',
        padding:'0px 16px',
        marginLeft:'20px',
        color:"#fff",
        borderRadius:'8px',
        textTransform:'none',
        fontSize:'14px',
        fontWeight:400,
        fontFamily:'DM Sans',
        boxShadow:"none",
        border:"none",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    "& .TableBoxCss":{
        boxShadow: "1px 2px 7px 6px #0000000F",
        backgroundColor:"white",
        marginLeft:'15px',
        borderRadius:'16px',
        paddingTop:'2px',
        "@media (max-width: 1279px)": {
            marginRight:'30px',
        },
        "@media (max-width: 500px)": {
            width:"390px",
            overflowX:'scroll'
        },
    },
    "& .SubTableBox": {
        borderRadius: '16px ',
        padding: '20px',
        alignItems: 'center',
        gap: '10px',
        paddingBottom: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    "& .TableTitleText":{
        fontWeight: 700, 
        fontSize: '14px',
        color:'#8C8C8C',
        fontFamily:'DM Sans',
        lineHeight:'1'
    },
    "& .TableContentBox":{
        display:'flex',
        alignItems:'center',
        gap:"10px"
    },
    "& .repsonsive":{
        padding:'30px',
        height:'100vh',
        overflowY:'scroll',
        overflowX:'hidden',
        "@media (max-width: 1280px)": {
          padding:'50px',
          height:'100vh',
          overflowY:'scroll',
          overflowX:'hidden'
        },
        "@media (max-width: 1366px)": {
          padding:'50px',
          height:'100vh',
          overflowY:'scroll',
          overflowX:'hidden'
        }
      },
      "& .repsonsiveCard":{
        width: "131px",
        height: "123px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        marginTop: "12px",
        marginRight: "35px",
        "@media (max-width: 1280px)": {
            width: "85px",
        },
        "@media (max-width: 1366px)": {
            width: "85px",
        }
      },
    
});
const PopUp = styled('div')({
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
    fontFamily:'DM Sans',
    paddingTop:'3rem',
    overflow:'scroll',

    "& .noscroll" : {
    overflowY:"hidden",
    scrollbarWidth:"none",
    },
    "& .noscroll::-webkit-scrollbar": {
        display:"none",
    },

    '&.active': {
        opacity: 1,
        visibility: 'visible',
    },

    '& .popUpBody': {
        backgroundColor: "white",
        width: "650px",
        borderRadius: "20px",
        padding: "20px 40px",
    },

    '& .hoverCountrydata:hover': {
        backgroundColor: "rgba(145, 183, 221, 0.21)",
    },

    "& .browseBtn":{
        height:"46px",
        background:'#355EE7',
        padding:'30px 40px',
        marginLeft:'20px',
        color:"#fff",
        borderRadius:'8px',
        textTransform:'none',
        fontSize:'20px',
        fontWeight:400,
        fontFamily:'DM Sans',
        boxShadow:"none",
        border:"none",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },

    '& #slider':{
        accentColor:"#355EE7"
    },

    "& .statusError": {
    borderLeft: "4px solid #F87171", fontFamily: "DM Sans", fontWeight: "400", fontSize: "12px", textAlign: "left", 
    backgroundColor: "#FEE2E2", color: "#DC2626", boxShadow: "none", marginBottom: "20px"
  },

  "& .statusErrorCustom": {

    fontFamily: "DM Sans", fontWeight: "400", fontSize: "14px", textAlign: "left",
    color: "red", boxShadow: "none", marginBottom: "20px"

  },
  "& .errorOutLine":{
    border:'1px solid red',
  }

});

// Customizable Area End
