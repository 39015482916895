
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleUpdateMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
// Customizable Area End
