import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    mobileOpen: boolean;
    open: boolean;
    passwordError1: string,
    oldpassword1:string,
    opendelete: boolean;
    openchangepass: boolean;
    oldchangepassword: boolean;
    changepassword: boolean;
    showPasswordCriteria: boolean;
    errormsg: string;
    client_data: {
      [x: string]: any;
      first_name: string;
      last_name: string;
      email: string;
      country_code: string;
      jurisdiction: string;
      firm: string;
      full_phone_number: string;
      profile_image: {
        url: string
      };
    };
    componentId: string;
    TokenMain: string | undefined;
    loadingImg:boolean;
    userDetails: {data:{attributes:{user_role:string}}};
    navigationlawyer:string;
    error_shown:boolean;
    popUpShow:boolean;
    responseShow:{
      success:string,
      error:string},
    DeletePop:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientSettingsController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  DeleteApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        txtInputValue: "",
        txtSavedValue: "A",
        enableField: false,
        // Customizable Area Start
        mobileOpen: false,
        open: false,
        passwordError1: "",
        opendelete: false,
        oldpassword1:'',
        openchangepass: false,
        oldchangepassword: false,
        changepassword: false,
        showPasswordCriteria: false,
        errormsg: "",
        client_data: {
          first_name: "",
          last_name: "",
          email: "",
          country_code: "",
          full_phone_number: "",
          jurisdiction: "",
          firm: "",
          profile_image: {
            url: ''
          }
        },
        componentId: '1',
        TokenMain: "",
        loadingImg:false,
        navigationlawyer:"",
        error_shown:false,
        userDetails: {data:{
        attributes:{
          user_role:""
        }
        }},
        popUpShow:false,
        responseShow:{
          success:"",
          error:""},
        DeletePop:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
);

const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
);


if (apiRequestCallId === this.DeleteApiCallId) 
  
  {
    this.asyncDeleteApiMethod1(responseJson?.errors,responseJson?.meta?.message)
  
}
if (apiRequestCallId === this.getUserProfileApiCallId) {

  if (responseJson?.errors) {
  this.setState({popUpShow:true,responseShow:{error:responseJson?.errors,success:""}}) 
  }

  else {
    
    this.setState({ client_data: responseJson.client_data})
  }
}
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    this.getUserProfile()
  }

  asyncDeleteApiMethod1=(errors:string,message:string)=>{
    if (errors) {
      this.setState({ opendelete: false, popUpShow:true,responseShow:{error:errors,success:""}});
    }

    else {
      this.setState({ opendelete: false ,popUpShow:true,responseShow:{error:"",success:message},DeletePop:true});
      removeStorageData("token");
      removeStorageData("userdetails");

    }
  }


  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }



  handleclosesidebar = () => 
    {this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {this.setState({ open: true })

  };

  handleClose = () => 
    { this.setState({ open: false });

  };


  handleClickOpendelete = () =>
     { this.setState({ opendelete: true });
  };


  handleCloseDelete = () => {
    this.setState({ opendelete: false });
  };


  handlelogout = () => {
    removeStorageData("clientTokes");
    removeStorageData("roleData");
    this.handleLogIn()
  }

  handleLogIn = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleSetting = async () => {
    let user_role = "";

    if (this.state.navigationlawyer === "lawyer") {
      user_role = "lawyer";
    }

    let navigationTarget = 'DashBoard';
  
    const token = localStorage.getItem('clientTokes');

    if (token) {
      navigationTarget = 'ClientDashboard'; 
    } else if (user_role === 'lawyer') {
      navigationTarget = 'LawyerDashboard'; 
    }

    const message1 = new Message(getName(MessageEnum.NavigationMessage));
    message1.addData(getName(MessageEnum.NavigationTargetMessage), navigationTarget);
    message1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
 
    this.send(message1);
  };
  

  handleCompanyInfo1 = () => 
    {const message1: Message =
      new Message(getName(MessageEnum.NavigationMessage));


    message1.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientCompanyInformationWeb");

    message1.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message1);
  }

  handleNotifications1 = () => 
    {
    const Notimessage: Message
      = new Message(getName(MessageEnum.NavigationMessage));

    Notimessage.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientNotification");

    Notimessage.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(Notimessage);

  }

  handleUserProfile1 = () => 
    {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");


    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);

  }
  handleStateChange=(signup:string,login:string)=>{
    this.setState({
      navigationlawyer: signup || login
    })
  }


  handleDeleteUser1 = async () => {
    const tokes: string = await getStorageData("clientTokes");

    
    const header = {
      "Content-Type": "application/json",
      "token": tokes
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.DeleteApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserApi
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    removeStorageData("clientTokes");
  };
  
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  getUserProfile = async () => {
    
    const loginTokens: string = await getStorageData("clientTokes");
  

    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": loginTokens
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
