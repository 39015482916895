

import React from "react";

// Customizable Area Start



import CompanyInformationController, { Props } from "./ComapnyInformationController.web";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@material-ui/core/styles';
import { logo } from "./assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Box, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

// Customizable Area End



export default class CompanyInformationWeb extends CompanyInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  drawer = () => (


    <DrawerBox data-test-id="drawer" style={{ display: this.state.mobileOpenNotification ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" />
      </div>
      {this.state.mobileOpenNotification && <CloseIcon style={{ position: 'fixed', top: '30px', left: '260px', cursor: 'pointer' }} onClick={this.handleclosesidebarCompany} />}
      <div className="settingbox" onClick={this.handleDashboard} data-test-id="settingDashboard">
        <ChevronLeftIcon className="lefticon" />
        <Typography className="settingtext">Setting</Typography>
      </div>
      <List>
        <ListItem className="listbox" onClick={this.handleUserProfile}>
          <ListItemIcon><PermIdentityIcon className="icondrawer1" /></ListItemIcon>
          <ListItemText className="listtile">
            User Profile
            <Typography className="listtext">
              Settings related to your personal information and account credentials
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox1" onClick={this.handleCompanyInfo}>
          <ListItemIcon><InfoIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile1">
            Company information
            <Typography className="listtext">
              View Digip’s Terms and Conditions and Privacy Policy
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleNotifications}>
          <ListItemIcon><NotificationsIcon className="icondrawer3" /></ListItemIcon>
          <ListItemText className="listtile">
            Notifications
            <Typography className="listtext">
              All settings related to notifications
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </DrawerBox>
  )

  // Customizable Area End


  render() {


    // Customizable Area Start


    const buttonMenu = (
      <ButtonBox className="buttonbox">
        <IconButton
          className="iconbtn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={this.handleDrawerToggleCompany}
          style={{ marginRight: '24px', color: '#000' }}
        >
          <MenuIcon />

        </IconButton>
      </ButtonBox>
    )



    const { open, dialogType,
      termsData, privacyData } = this.state;
    
      return (

      <>


        <MainDiv data-test-id="SettingUserProfile"
          style={{
            maxHeight: this.state.mobileOpenNotification ? '100vh' : '',
            overflow: this.state.mobileOpenNotification ? "hidden" : ""
          }}>
          <Box className="sidebar">
            {this.drawer()}
            {buttonMenu}
            {this.state.mobileOpenNotification &&
              <>
                <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                  {this.drawer()}
                </div>
              </>
            }
          </Box>
          {this.state.mobileOpenNotification && <div className="offcanvasbackdrop" onClick={this.handleclosesidebarCompany}></div>}
          <Box className="rightbar" data-test-id="Companyinformation">
            <Box className="notifcationbox">
              <Typography className="TitleNotification">Company information</Typography>
              <Box className="notificationlist">
                <Box className="list">
                  <Typography data-test-id="terms" className="contenttxt">Terms and Conditions</Typography>
                  <Box className="viewtext" onClick={() => this.handleClickOpen('terms')}>View</Box>
                </Box>
                <Box className="list1">
                  <Typography data-test-id="privacy" className="contenttxt">Privacy Policy</Typography>
                  <Box className="viewtext1" onClick={() => this.handleClickOpen('privacy')}>View</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </MainDiv>
        <Dialog PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "20px"
          },
        }} className='customDialog' onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle   >
            <Typography style={{
              fontSize: "22px",
              fontWeight: 400,
              fontFamily: "DM Sans",
              lineHeight: "28px",
              color: "black",
              padding: "10px"
            }}>{dialogType === 'terms' ? 'Terms and Conditions' : 'Privacy Policy'}</Typography>
            <IconButton
              aria-label="close"
              onClick={this.handleClose}
              data-test-id="close"
              style={{
                position: 'absolute',
                right: 14,
                top: 35,
                color: '#000',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {dialogType === 'terms' ? (
              <><Typography className="dialogText" style={{ marginTop: "20px" }} gutterBottom>
                {termsData?.replace(/[\r\n]+/g, '')}
              </Typography></>
            ) : (
              <><Typography style={{ marginTop: "20px" }} className="dialogText" gutterBottom>
                {privacyData?.replace(/[\r\n]+/g, '')}</Typography></>

            )}
          </DialogContent>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}



// Customizable Area Start



const MainDiv = styled('div')({
  height: '100%',
  width: '100%',display: 'flex',
  "@media (max-width:991px)": 
  {
    display: "block",
  },
  "& .sidebar": 
  {
    width: '350px',height: '100vh',
    position: 'fixed',top: '0',
    left: '0',
    borderRight: "1px solid #C3D1FF",
    "@media (max-width: 1100px)": 
    {
      width: "300px"
    },
    "@media (max-width: 991px)": 
    {
      height: '80px',
      width: '100vw',
    }
  },
  "& .rightbar": {
    width: 'calc(100% - 350px)',
    marginLeft: '350px',
    marginTop: '100px',
    padding: "20px 70px",
    "@media (max-width: 1100px)": 
    {
      width: 'calc(100% - 300px)',
      padding: '20 40px',
      marginLeft: '300px',
    },
    "@media (max-width: 991px)": {
      marginLeft: '0px',
      width: 'auto !important',
      padding: "10px 50px",
      marginTop: '50px',
    },
    "@media (max-width: 490px)": 
    {
      marginLeft: '0px',
      width: 'auto !important',
      padding: "10px 20px",
      marginTop: '50px',
    }
  },
  "& .offcanvascss": 
  {
    position: 'fixed',
    bottom: '0px !important',
    top: '0px !important',
    width: '300px !important',
    left: '0% !important',
    backgroundColor: '#fff',
    height: '100vh !important',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    top: '0px',
    position: "fixed",
    width: 'calc(100% - 300px)',
    left: '300px',
    backgroundColor: '#000',
    opacity: 0.5,
    height: '100vh',
    zIndex: 999,
  },
  "& .TitleNotification": {
    fontWeight: 400,
    fontSize: "22px",
    color: '#000',
    fontFamily: "DM Sans",
  },
  "& .notifcationbox": 
  {
    paddingLeft: "50px",
    "@media (max-width: 991px)": 
    {
      paddingLeft: "30px"
    },
    "@media (max-width: 490px)": 
    {
      paddingLeft: "10px"
    }
  },
  "& .notificationlist": 
  {
    marginTop: '65px',
    "@media (max-width: 991px)":
     {
      marginTop: '50px'
    }
  },
  "& .list": {
    display: 'flex',
    borderBottom: '1px solid #E2E8F0',
    alignItems: "center",
    padding: '16px 8px'
  },
  "& .list1": {
    display: 'flex',
    alignItems: "center",
    padding: '16px 8px'
  },
  "& .contenttxt": {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8C8C8C',
    fontFamily: "DM Sans",
  },
  "& .viewtext": {
    fontSize: '16px',
    fontWeight: 400,
    color: '#355EE7',
    fontFamily: "DM Sans",
    marginLeft: '110px',
    cursor: "pointer"
  },
  "& .viewtext1": {
    fontSize: '16px',
    fontWeight: 400,
    color: '#355EE7',
    fontFamily: "DM Sans",
    marginLeft: '172px',
    cursor: "pointer"
  }

})



const DrawerBox = styled('div')(
  {

  width: "350px",
  minHeight: '100vh',
  "& .logoimag":  {
    width: '118px',
    height: '42px' ,
  },
  "& .logobox": 
  {
    display: 'flex', justifyContent: "start",
    height: "76px",marginTop: '20px',
    marginLeft: '20px'
  },
  "& .settingbox": {
    display: 'flex',
    height: "65px",
    cursor:"pointer"
  },
  "& .settingtext": 
  {
    cursor:"pointer",
    fontSize: '22px',
    color: '#262626',
    fontFamily: 'DM Sans',
    marginLeft: '6px',
    fontWeight: 400,
  },
  "& .lefticon": 
  {
    marginTop: '4px',
    marginLeft: '30px',
    cursor:"pointer"
  },
  "& .listbox": {
    height: '110px',
    "&:hover": 
    {
      backgroundColor: "#DCECFF",
      color: "#355EE7",
      "& .icondrawer3": 
      {
        backgroundColor: "#DCECFF",
        color: "#355EE7",
      },
      "& .icondrawer1": 
      {
        backgroundColor: "#DCECFF",
        color: "#355EE7",
      },
    },
  },
  "& .icondrawer1": {
    marginLeft: '18px',
    marginBottom: '45px'
  },
  "& .listbox1": 
  {
    height: '110px', backgroundColor: '#DCECFF',
  },
  "& .icondrawer3": 
  {
    marginLeft: '18px',
    marginBottom: '25px'
  },
  "& .icondrawer2": 
  {
    color: "#355EE7", marginLeft: '18px',
    marginBottom: '45px',
  },
 
  "& .listtext": 
  {
    color: '#334155',fontSize: '14px',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  "& .listtile": 
  {
    fontWeight: 400,
    fontSize: "16px",
    cursor: 'pointer',
    fontFamily: 'DM Sans',
  },
  "& .listtile1": 
  {
    fontSize: "16px",
    color: '#355EE7',
    fontFamily: 'DM Sans',
    fontWeight: 400,
    cursor: 'pointer'
  },
  
  "@media (max-width: 1100px)":
   {
    zIndex: 999,
    width: '300px',
  },
  "@media (max-width: 991px)": 
  {
    zIndex: 999,
    width: '300px',
    display: 'none'
  },
});


const ButtonBox = styled('div')({

  display: 'none',
  "@media (max-width: 991px)": {
    display: 'block',
    minHeight: '70px',
    width: '100%',
    "& .iconbtn": 
    {
      marginTop: '10px',marginLeft: '10px'
    },
    "& .buttonbox": 
    {
      height: '70px',width: '100vw'
    },
   
  }
})

// Customizable Area End

