Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "bx_block_projecttemplates/project_templates";
exports.postAPiEndMethod="bx_block_projecttemplates/project_templates"
exports.getApiMethodGet="GET";
exports.putApiMethodPut="PUT";
exports.postApiMethodPost="POST";
exports.tasksApiEndPoint="bx_block_projecttemplates/project_templates"
exports.btnExampleTitle = "CLICK ME";

// Customizable Area End