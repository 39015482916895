export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backarrow = require("../assets/backarrow.png");
export const cardblank = require("../assets/cardblank.png");
export const fillstar = require("../assets/fillstar.png");
export const empty = require("../assets/empty.png");
export const logo = require("../assets/image_logo.svg");
export const userpic = require("../assets/user.jpg");
export const HomeLogo = require("../assets/homeLogo.png");
export const right = require("../assets/image_.png");
export const cross = require("../assets/cross.png");