import React from "react";
// Customizable Area Start

import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
  styled,
} from "@material-ui/core"

import {
  bag,
} from "./assets";

import { logo, userpic } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItemText from "@material-ui/core/ListItemText";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import MenuIcon from '@material-ui/icons/Menu';
import FilterListIcon from '@material-ui/icons/FilterList';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  ExpandLess,
  Star, 
  StarBorder, 
  StarRate, 
  VpnLock, 
  WorkOutline,
  AttachMoney,
  Euro,
  FiberManualRecord,
  Gavel
} from "@material-ui/icons";
import EuroIcon from '@material-ui/icons/Euro';

// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? "block" : "" }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" />
      </div>
      {this.state.mobileOpen && (
        <CloseIcon
          style={{
            position: "fixed",
            top: "30px",
            left: "260px",
            cursor: "pointer",
          }}
          onClick={this.handleclosesidebar}
        />
      )}
      <div
        className="settingbox"
        data-test-id="settingid"
        onClick={this.handleNavigationClientDashboard}
      >
        <ChevronLeftIcon className="lefticon" />
        <Box ><Typography className="settingtext">Dashboard</Typography></Box>
      </div>
      <List style={{ padding: "8px 13px" }}>
        <ListItem
          className="listbox1"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ListItemIcon>
            <PermIdentityIcon style={{ color: '#294DC8' }} className="icondrawer2" />
          </ListItemIcon>
          <ListItemText style={{ marginTop: '-10px', color: '#294DC8' }}>
            Lawfirms
            <Typography
              className="listtext"
              style={{ whiteSpace: "none !important" }}
            >
              View All Lawfirms
            </Typography>
          </ListItemText>
          <ListItemIcon >
            <ExpandLess style={{ color: '#294DC8' }} className="icondrawer2" />
          </ListItemIcon>
        </ListItem>
      </List>
    </DrawerBox>
  );

  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: "24px", color: "#000" }}
      >
        <MenuIcon />
      </IconButton>
    </ButtonBox>
  );

  userMultiValues = (attributes: any) => {
    return (
      <div className="userbox2">
        <Box
          style={{ display: "flex", marginBottom: "7px", alignItems: "center" }}
        >
          <img src={bag} alt="Bag" className="bagCss" />
          <Typography className="servicesLegalCss">Legal Services</Typography>
        </Box>
        <Box style={{ display: "flex" }}>
          {!attributes?.legel_service_type ||
          attributes.legel_service_type.length == 0 ? (
            <Typography className="FamilyTextCss" style={{ padding: "3px 5px" }}>
              Family Law
            </Typography>
          ) : (
            <>
              {attributes.legel_service_type
                .slice(0, 2)
                .map((e: { legal_service_name: string }, i: number) => (
                  <Typography
                    key={i}
                    className="FamilyTextCss"
                    style={{ padding: "3px 5px", marginRight: "7px" }}
                  >
                    {e.legal_service_name || "Family Law"}
                  </Typography>
                ))}
              {attributes.legel_service_type.length > 2 && (
                <Typography
                  className="FamilyTextCss"
                  style={{ padding: "3px 5px" }}
                >
                  +{attributes.legel_service_type.length - 2}
                </Typography>
              )}
            </>
          )}
        </Box>
      </div>
    );
  };
  
  topbox = () => {
    return (
      <Box className="topbox">
        <Box style={{ width: '70vw', display: 'flex', flexDirection: 'column', gap: "10px", marginTop:'1.5rem'}}>
                <Box className="addnewTask" style={{ display: 'flex', justifyContent: 'space-between', width: "95%", alignItems: "center" }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Box style={{fontSize: "16px"}}>Lawfirms</Box>
                        <Box style={{ display: 'flex', fontSize: '20px', gap: '10px' }}>
                            <Box style={{fontSize: "16px",color:'#595858'}}>Business Lawfirms {this.state.msg.message}</Box>
                            <Box style={{fontSize: "16px", color:'#8e8e8e'}}>({this.state.userdata.length}{"   Lawfirms"})</Box>
                        </Box>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', maxWidth: "200px" }}>
                        <Box style={{display:'flex',position:'relative',cursor:'pointer'}}
                        data-test-id="openFilterPopUpId" 
                        onClick={this.handleClosePopUp}
                        >
                          <FilterListIcon />
                          <FiberManualRecord style={{position:'absolute',top:'-3px',fontSize:'12px',right:'-8px',color:'#355EE7'}}/>
                        </Box>
                        <Box style={{cursor:'pointer', width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px 10px 0px' }}>
                            <button className="browseBtn" data-test-id="addNewTask"
                            >Add New Task</button>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className="parentFlex">
                    <Box style={{ fontSize: '16px', textWrap: "nowrap" }} className="filterText">Filter By : </Box>
                    <Box style={{width:'100%',height:'100%',display:'flex',gap:'1.5rem'}} className="noscroll">
                    <Box className="classFlex">
                        <Box><WorkOutline /></Box>
                        <Box style={{textWrap: "nowrap"}}>Legal Service</Box>
                    </Box>
                    <Box className="classFlex">
                        <Box><VpnLock /></Box>
                        <Box>Jurisdiction</Box>
                    </Box>
                    <Box className="classFlex">
                        <Box><Star /></Box>
                        <Box>Rating</Box>
                    </Box>
                    <Box className="classFlex">
                        <Box><AttachMoney /></Box>
                        <Box>Price</Box>
                    </Box>
                    </Box>
                </Box>
                </Box>
      </Box>
    );
  };
  
  UserMapBox = () => {
    return (
      <Box
        className="userContainer"
        style={{ display: "flex", flexWrap: "wrap", gap: "30px" ,cursor:'pointer'}}
        data-test-id='mUseMap'
      >
        {this.state.userdata.map((data: any, index: number) => {
          const attributes =
            data.attributes.lawfirm_details.data.attributes;
          const priceRange = data.attributes.price_range;
          const lawyersDetails = data.attributes.lawyer_details;
          const isOpen = false;
          return (
            <Box key={index} data-test-id={`mapId${index}`} className="userBoxData"
             style={{ zIndex: `${this.state.mobileOpen ? '-1' : '1'}` }} 
             data-test-is="handleDetailsPopUpId"
            onClick={(e)=>{
              this.handleDetailsPopUp(0)
            }}
           >
              <Box className="userbox1">
                <Box className="userProfile">
                  <Box>
                    <img
                      src={attributes?.profile_image?.url || userpic}
                      alt="Profile"
                      className="imageBox"
                    />
                  </Box>
                  <Box style={{ display: 'flex', gap: '60px' }}>
                    <Box style={{ marginTop: "4px", display: 'flex', flexDirection: 'column' }}>
                      <Typography style={{ fontSize: '1.2rem' }}>
                        {attributes.firm || "Maya Diab"}
                      </Typography>
                      <Typography style={{ fontSize: '14px', textWrap: 'nowrap' }}>
                        Business Law
                      </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Box ><StarRate style={{ fontSize: '30px', color: 'gold' }} /></Box>
                      <Box>4.2</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="userbox3">
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Box style={{ fontSize: '15px' }}>Price Range</Box>
                  <Box style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <Box style={{ color: '#8e8e8e', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>{"From "}<Euro style={{ color: '#d7cdcd' }} /></Box>
                    <Box>{priceRange.start_price}</Box>
                    <Box style={{ color: '#8e8e8e', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>{" to "}<Euro style={{ color: '#d7cdcd' }} /></Box>
                    <Box>{priceRange.end_price}</Box>
                  </Box>
                </Box>
              </Box>
              <Box className="userbox4">
                Associated Lawyers
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                <Box className="cardImgP">
                  {
                    lawyersDetails.images.slice(0, 3).map((x: any, i: number) => {
                      return (
                        <Box className={`cardImg${i + 1}`} key={i}>
                          <img style={{ borderRadius: '20px' }} src={x.length < 5 ? userpic : x} width={'100%'} height={'100%'} />
                        </Box>
                      );
                    })
                  }
                  {
                    !(lawyersDetails.images.length >= 1 && lawyersDetails.images.length <= 3) &&
                    <Box className={`${lawyersDetails.images.length == 0 ? 'cardImg1' : 'cardImg4'}`} 
                    style={{ backgroundColor: 'gray', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {
                        lawyersDetails.images.length > 1 && lawyersDetails.images.length <= 3 ? '' :
                          lawyersDetails.images.length == 0 ? '0' : `+${lawyersDetails.images.length - 3}`}
                    </Box>
                  }

                </Box>
                <Box data-test-id="seeMoreId" onClick={()=>this.handleLawyerCatalogueNavigation(attributes.firm)} 
                style={{ color: '#355EE7', fontSize: '15px', cursor:'pointer' }}>{`${lawyersDetails.images.length > 0 ? 'see more' : ''}`}</Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };
  
  userBox = () => {
    return (
      <>
        {"" === "" ? (
          <>
            {this.topbox()}
            {this.state.userdata.length > 0 ? (
              <>
                <Box marginTop={"100px"}>{this.UserMapBox()}</Box>
              </>
            ) : (
              <span
                style={{
                  display: "flex",
                  margin: "0 300px 0 0",
                  alignItems: "center",
                  height: "70vh",
                  justifyContent: "center",
                }}
              >
                "No lawfirms found"
              </span>
            )}
          </>
        ) : (
          <Typography className="nouserdatacss">
            {"this.state.noUserData"}
          </Typography>
        )}
      </>
    );
  };

  displayedData = []
  displayedDataLeagalService = []
  currentMedia = {url:'',mimeType:''}

  mainErrorBox = () => {
    return (<>
      {
        (this.state.Error.legalServices || this.state.Error.rating || this.state.Error.jurisdiction || this.state.Error.price) &&
        <Box className="statusError" p={2} borderRadius={4} boxShadow={2} textAlign="center">
          {this.state.Error.isError}
        </Box>
      }
    </>);
  }

  legalServiceErrorBox = () => {
    return (<>
      {
        this.state.Error.legalServices &&
        <Box className="statusErrorCustom" style={{ color: 'red' }}>
          {this.state.Error.legalServices}
        </Box>
      }
    </>)
  }

  jurisdictionErrorBox = () => {
    return (<>
      {
        this.state.Error.jurisdiction &&
        <Box className="statusErrorCustom" style={{ color: 'red' }}>
          {this.state.Error.jurisdiction}
        </Box>
      }
    </>)
  }

  ratingErrorBox = () => {
    return (<>
      {
        this.state.Error.rating &&
        <Box className="statusErrorCustom" style={{ color: 'red' }}>
          {this.state.Error.rating}
        </Box>
      }
    </>)
  }

  popUp = () => {
    return (<>
      <PopUp className="active">
        <Box className="popUpBody">
          <Box
            onClick={this.handleClosePopUp}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <CloseIcon
              style={{ fontSize: '30px' }} />
          </Box>
          <Box style={{ fontSize: "24px", marginBottom: "20px", marginTop: '-20px' }}>Filter</Box>
          {
            this.mainErrorBox()
          }
          <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Legal Service Type</Typography><Box style={{ color: "red" }}>*</Box></Box>
          <div
            data-test-id="SelectlegalServicesId"
            onClick={this.toggleArr}
            style={{
              border: this.state.Error.legalServices ? '1px solid red' : '1px solid #E0E0E0',
              padding: '10px',
              backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='${this.state.isArrowUp ? '6 15 12 9 18 15' : '6 9 12 15 18 9'}'/></svg>")`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '97%',
              backgroundPositionY: 'center',
              backgroundSize: '20px 20px',
              borderRadius: "10px",
              marginBottom: "20px",
              color: '#868282',
              fontSize: '1rem'
            }}
          >Select legal Services</div>
          {this.state.isArrowUp && (
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative', zIndex: '99999' }}>
              <Box style={{
                width: '95%',
                height: '200px',
                backgroundColor: "#fafafa",
                borderRadius: "10px", position: 'absolute', top: '-15px',
                padding: '0px 40px',

              }}>
                <Box style={{ width: '100%', marginTop: '20px' }}>
                  <TextField
                    onChange={this.handleInputChangeLegalService}
                    value={this.state.legalServiceDataInputValue}
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                      },
                    }}
                    style={{ width: '100%', borderRadius: '10px' }}
                    variant="outlined" />
                </Box>
                <Box className="noscroll" style={{ overflow: "scroll", width: '100%', height: '62%' }}>
                  {this.displayedDataLeagalService.map((item: any) => (
                    <Box
                      onClick={() => this.handleSelectedLegalService(item)}
                      key={item.id}
                      className="hoverCountrydata"
                      style={{
                        display: 'flex',
                        fontSize: '20px',
                        padding: '10px',
                        gap: '10px',
                      }}>
                      <Box> <Gavel /></Box>
                      <Box>{item.label}</Box>
                    </Box>)
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {
            this.state.legalServiceDataInputValueStack.length > 0 &&
            <Box className="noscroll" style={{
              width: "100%",
              height: "50px",
              display: 'flex',
              flexWrap: 'wrap',
              overflow: 'scroll',
              gap: '5px',
              padding: '10px',
              margin: '-20px 0px 10px 0px'
            }}>
              {this.state.legalServiceDataInputValueStack.map((x: any) =>
                <Box
                  key={x.label}
                  style={{
                    backgroundColor: 'rgba(145, 183, 221, 0.21)',
                    fontSize: '1.5rem',
                    padding: '2px 5px',
                    display: 'flex',
                    gap: '25px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '5px',
                  }}>
                  <Box style={{
                    display: 'flex',
                    gap: '10px'
                  }}>
                    <Box><Gavel /></Box>
                    <Box style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px'
                    }}>{x.label}</Box>
                  </Box>
                  <Box onClick={() => this.handleCancelLegalServices(x.label)}><CloseIcon style={{ padding: '3px' }} /></Box>
                </Box>
              )}
            </Box>
            // 
          }
          {
            this.legalServiceErrorBox()
          }
          <Box>
            <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Jurisdictions</Typography><Box style={{ color: "red" }}>*</Box></Box>
            <div
              data-test-id="SelectJurisdictionId"
              onClick={this.toggleArr2}
              style={{
                border: this.state.Error.jurisdiction ? '1px solid red' : '1px solid #E0E0E0',
                padding: '10px',
                backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='${this.state.isArrowUp2 ? '6 15 12 9 18 15' : '6 9 12 15 18 9'}'/></svg>")`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: '97%',
                backgroundPositionY: 'center',
                backgroundSize: '20px 20px',
                borderRadius: "10px",
                marginBottom: "20px",
                color: '#868282',
                fontSize: '1rem'
              }}
            >{
                "Select Jurisdiction"}</div>
            {this.state.isArrowUp2 && (
              <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative', zIndex: '99999' }}>
                <Box style={{
                  width: '95%',
                  height: '200px',
                  backgroundColor: "#fafafa",
                  borderRadius: "10px", position: 'absolute', top: '-15px',
                  padding: '0px 40px',

                }}>
                  <Box style={{ width: '100%', marginTop: '20px' }}>
                    <TextField
                      onChange={this.handleInputChange}
                      value={this.state.countryDataInputValue}
                      InputProps={{
                        style: {
                          borderRadius: '10px',
                        },
                      }}
                      style={{ width: '100%', borderRadius: '10px' }}
                      variant="outlined" />
                  </Box>
                  <Box  className="noscroll" style={{ overflow: "scroll", width: '100%', height: '62%' }}>
                    {this.displayedData.map((item: any) => (
                      <Box
                        onClick={() => this.handleSelectedCountry(item)}
                        key={item.attributes.emoji_flag}
                        className="hoverCountrydata"
                        style={{
                          display: 'flex',
                          fontSize: '25px',
                          padding: '10px',
                          gap: '10px',
                        }}>
                        <Box>{item.attributes.name}</Box>
                      </Box>)
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {
              this.state.countryDataInputValueStack.length > 0 &&
              <Box className="noscroll" style={{
                width: "100%",
                height: "50px",
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'scroll',
                gap: '5px',
                padding: '10px',
                margin: '-20px 0px 10px 0px'
              }}>
                {this.state.countryDataInputValueStack.map((x: any) =>
                  <Box
                    key={x.attributes.emoji_flag}
                    style={{
                      backgroundColor: 'rgba(145, 183, 221, 0.21)',
                      fontSize: '1.5rem',
                      padding: '2px 5px 2px 10px',
                      display: 'flex',
                      gap: '25px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '5px',
                    }}>
                    <Box style={{
                      display: 'flex',
                      gap: '10px'
                    }}>
                      <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '15px'
                      }}>{x.attributes.name}</Box>
                    </Box>
                    <Box onClick={() => this.handleCancelSelectedCountry(x.attributes.emoji_flag)}><CloseIcon style={{ padding: '3px' }} /></Box>
                  </Box>
                )}
              </Box>
              // 
            }
            {
              this.jurisdictionErrorBox()
            }
          </Box>
          <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Rating</Typography><Box style={{ color: "red" }}>*</Box></Box>
          <div
            data-test-id="RatingId"
            onClick={this.toggleArr3}
            style={{
              border: this.state.Error.rating ? '1px solid red' : '1px solid #E0E0E0',
              padding: '10px',
              backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='${this.state.isArrowUp3 ? '6 15 12 9 18 15' : '6 9 12 15 18 9'}'/></svg>")`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '97%',
              backgroundPositionY: 'center',
              backgroundSize: '20px 20px',
              borderRadius: "10px",
              marginBottom: "20px",
              color: '#868282',
              fontSize: '1rem'
            }}
          >Select Rating</div>
          {this.state.isArrowUp3 && (
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative', zIndex: '99999' }}>
              <Box style={{
                width: '95%',
                height: '200px',
                backgroundColor: "#fafafa",
                borderRadius: "10px", position: 'absolute', top: '-15px',
                padding: '0px 40px',

              }}>
                <Box  className="noscroll" style={{ overflow: "scroll", width: '100%', height: '62%' }}>
                  {this.state.ratingOptions.map((item: any) => (
                    <Box
                      data-test-id={`ratingId` + item.rating}
                      onClick={() => this.handleSelectedrating(item.rating)}
                      key={item.id}
                      className="hoverCountrydata"
                      style={{
                        display: 'flex',
                        fontSize: '20px',
                        padding: '10px',
                        gap: '10px',
                      }}>
                      <Box style={{ display: 'flex', gap: "10px" }}>
                        <Box style={{
                          display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                          {Array.from({ length: item.rating }, (_, index) => (
                            <Star key={index} style={{ color: 'gold', fontSize: '40px' }} />
                          ))}
                          {Array.from({ length: (5 - item.rating) }, (_, index) => (
                            <StarBorder key={index} style={{ fontSize: '40px' }} />
                          ))}
                        </Box>
                        <Box style={{ padding: '10px' }}>& up</Box>
                      </Box>
                    </Box>)
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {
            this.state.ratingInputValue > 0 &&
            <Box style={{
              display: 'flex', alignItems: 'center',
              backgroundColor: 'rgba(115, 147, 179, 0.19)',
              width: '35%',
              gap: '25px',
              height: '41px',
              borderRadius: '5px',
              marginBottom: '10px',
              padding: '0 10px',
              justifyContent: 'space-between'
            }}>
              <Box>
                {Array.from({ length: this.state.ratingInputValue }, (_, index) => (
                  <Star key={index} style={{ color: 'gold', fontSize: '25px' }} />
                ))}
                {Array.from({ length: (5 - this.state.ratingInputValue) }, (_, index) => (
                  <StarBorder key={index} style={{ fontSize: '25px' }} />
                ))}
              </Box>
              <Box onClick={this.handleRatingCancel}><CloseIcon style={{ marginTop: '2px', padding: '2px' }} /></Box>
            </Box>
          }
          {
            this.ratingErrorBox()
          }
          <Box sx={{ width: '100%' }}>
            <Box style={{ display: "flex", marginBottom: "10px" }}><Typography>Price</Typography><Box style={{ color: "red" }}>*</Box></Box>
            <input
              data-test-id="sliderID"
              style={{ outline:'none',width: '100%', borderRadius: '10px', backgroundColor: '#355EE7' }} type="range" id="slider" min="0" max={5000}
              onChange={this.handleChangesliderbar}
              value={this.state.SliderBarValue}></input>
            <Box style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
            }}>
              <Box style={{
                border: '1px solid #d2cfcf',
                borderRadius: '5px',
                padding: '10px',
                minWidth: '60px',
                display: 'flex',
              }}>
                <Box style={{ display: 'flex', alignItems: 'center' ,color:'gray'}}><EuroIcon style={{ color:'#ccc5c5',fontSize: '18px' }} />{this.state.intalSlideBarValue}</Box>
              </Box>

              <Box style={{
                border: '1px solid #d2cfcf',
                borderRadius: '5px',
                padding: '10px',
                minWidth: '60px',
                display: 'flex',
              }}>
                <Box style={{ display: 'flex', alignItems: 'center',color:'gray' }}><EuroIcon style={{ color:'#ccc5c5',fontSize: '18px' }} /> {5000}</Box>
              </Box>
            </Box>
            {this.state.Error.price &&
              <Box className="statusErrorCustom" style={{ color: 'red' }}>
                {this.state.Error.price}
              </Box>
            }
            <hr style={{ border: '0.5px solid #d2cfcf' }} />
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px 10px 0px' }}>
              <button className="browseBtn" data-test-id="applyFilterId"
                onClick={this.handleApplyFilter}>Apply Filter</button>
            </Box>
          </Box>
        </Box>
      </PopUp>
    </>)
  }

  renderItems() {
    const { currentIndex } = this.state;

    return this.state.mediaArray.slice(currentIndex, currentIndex + 3).map((item:any, index:number) => (
      <div key={index} style={{ width: '100px', height: '100px', overflow: 'hidden', borderRadius:'20px' }}>
        {item.type === 'video/mp4' ? (
          <video width="100" height="100" controls src={item.url} style={{borderRadius:'20px'}}>
          </video>
        ) : (
          <img src={item.url} alt={`item-${index}`} style={{ width: '100%', height: '100%',borderRadius:'20px' }} />
        )}
      </div>
    ));
  }

  DetailsPopUp = () =>{
    return(
      <>
        <PopUp className="active">
          <Box className="popUpBody2">
            <Box
             
              style={{ display: 'flex', justifyContent:'space-between' }}>
               <Box style={{display:'flex',alignItems:'center',gap:'10px'}}>
               <Box style={{width:'40px',height:'40px',borderRadius:'50%'}}>
                  <img src={this.state.showdetails.profileUrl ? this.state.showdetails.profileUrl : userpic} width={'100%'} height={'100%'} style={{borderRadius:'50%'}}/>
                </Box>
                <Box style={{fontSize:'1.5rem'}}>{this.state.showdetails.firmName}</Box>
               </Box>
              <CloseIcon
                style={{ fontSize: '30px' }}  onClick={this.handleCloseDetailsPopUp} />
            </Box>
            <Box style={{margin:'0 -40px'}}>
            <hr style={{ height: '1px', border: 'none', backgroundColor: '#d0cbcb' }} />
            </Box>
            <Box style={{ display: 'flex' }}>
              <Box style={{padding:'0 30px',marginTop:'1rem',color:'gray',minWidth:'25%'}}>Law Firm Details</Box>
              <Box style={{display:'flex',flexDirection:'column',gap:'20px',marginTop:'1rem'}}>
                <Box className="txtHeading">Lawfirm Name</Box>
                <Box className="textHeadingValue" style={{marginLeft:'0.5rem'}}>{this.state.showdetails.firmName}</Box>
                <Box className="txtHeading" style={{maxWidth:'90%'}}>Website URL</Box>
                <Box className="textHeadingValue" style={{marginLeft:'0.5rem'}}>{this.state.showdetails.webUrl}</Box>
                <Box style={{
                  display:'flex',
                  flexDirection:'column',
                  gap:'10px',
                  backgroundColor:'#e1e1f0',
                  padding:'5px',
                  borderRadius:'10px',
                  maxWidth:'80%'
                  }}>
                  <Box style={{display:'flex',gap:'1rem',alignItems:'center',padding:'10px'}}>
                    <Gavel style={{color:'white',backgroundColor:'blue',borderRadius:'50%',padding:'10px'}}/> 
                    <Box style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                    <Box>Legal Service type Value</Box>
                    <Box style={{fontSize:'12px',color:'#403d3d'}}>Legal Service type Value</Box>
                    </Box>
                  </Box>
                  <Box style={{padding:'0 10px 5px 10px',fontSize:'13px'}}>Legal service type description</Box>
                </Box>
                <Box style={{display:'flex',width:'150%',justifyContent:'space-between',margin:'0 10px',maxWidth:'90%'}}>
                  <Box style={{display:'flex',flexDirection:'column',gap:'0.5rem'}}>
                    <Box>Rate</Box>
                    <Box  className="textHeadingValue" style={{display:'flex',alignItems:'center',gap:'0.5rem'}}><Star style={{color:'gold',fontSize:'1.5rem'}}/> <Box>{this.state.showdetails.rate}</Box></Box>
                  </Box>
                  <Box style={{display:'flex',flexDirection:'column',gap:'0.5rem',marginRight:'20px'}}>
                    <Box>average Response Rate</Box>
                    <Box  className="textHeadingValue" style={{display:'flex',alignItems:'center',gap:'0.5rem'}}><HourglassEmptyIcon style={{color:'blue'}}/> <Box>6hrs</Box></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{marginTop:'20px'}}>
              <Box style={{marginBottom:'10px'}}>Photos/videos</Box>
              <Box style={{ display: 'flex', alignItems: 'center', padding: '10px',justifyContent:'center' }}>
                <Box onClick={this.handlePrev} style={{display:'flex',justifyContent:'center',alignItems:'center', marginRight:'40px'}}>
                  <ArrowBackIosIcon />
                </Box>
                <Box display='flex' >
                  <div style={{ display: 'flex', overflow: 'hidden',gap:'10px' }}>
                    {this.renderItems()}
                  </div>
                </Box>
                <Box onClick={this.handleNext} style={{marginLeft:'40px'}}>
                  <ArrowForwardIosIcon />
                </Box>
              </Box>
            </Box>
            <Box style={{margin:'0 -40px'}}>
            <hr style={{ height: '1px', border: 'none', backgroundColor: '#d0cbcb' }} />
            </Box>
            <Box onClick={()=>this.handleLawyerCatalogueNavigation(this.state.showdetails.firmName)} style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0px 10px 0px' }}>
              <button className="browseBtn" data-test-id="applyFilterId"
              >View Associatd Lawyers</button>
            </Box>
          </Box>
        </PopUp>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {countryDataInputValue,filteredData,countryData} = this.state;
    this.displayedData = countryDataInputValue ? filteredData : countryData;

    const {legalServiceDataInputValue,filteredDataLegalService,optionsShow} = this.state;
    this.displayedDataLeagalService = legalServiceDataInputValue ? filteredDataLegalService : optionsShow;

    this.currentMedia = this.state.mediaArray[this.state.currentIndex];
    return (
      <>
        <MainDiv
          data-test-id="ClientFilterresult"
          onClick={this.handleCloseOption}
          style={{
            maxHeight: this.state.mobileOpen ? "100vh" : "",
            overflow: this.state.mobileOpen ? "hidden" : "",
          }}
        >
          <Box className="sidebar">
            {this.drawer()}
            {this.buttonMenu()}
            {this.state.mobileOpen && (
              <>
                <div
                  className="offcanvascss"
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  {this.drawer()}
                </div>
              </>
            )}
          </Box>
          {this.state.mobileOpen && <div className="offcanvasbackdrop"></div>}
          <Box
            className="rightbar"
            data-test-id="rightbar"
            onClick={this.handleCloseBox}
          >
            {this.userBox()}
          </Box>
          <Box>{this.state.openFilterPopUp && this.popUp()}</Box>
          <Box>{this.state.detailsPopUp && this.DetailsPopUp()}</Box>
        </MainDiv>
      </>  
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainDiv = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "scroll",
  userSelect:'none',

  " ::-webkit-scrollbar": {
    width: "10px !important",
  },
  " ::-webkit-scrollbar-track": {
    background: "red !important",
  },
  " ::-webkit-scrollbar-thumb": {
    background: "red !important",
  },
  " ::-webkit-scrollbar-thumb:hover": {
    background: "red !important",
  },
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .MuiSelect-selectMenu:focus": {
    backgroundColor: "#fff",
  },
  "& .sidebar": {
    width: "355px",
    height: "100vh",
    position: "fixed",
    top: "0",
    left: "0",
    borderRight: "1px solid #C3D1FF",
    "@media (max-width: 1100px)": {
      width: "355px",
    },
    "@media (max-width: 991px)": {
      width: "100vw",
      height: "80px",
    },
  },
  "& .rightbar": {
    width: "calc(100% - 410px)",
    padding: "20px 70px",
    marginLeft: "410px",
    marginTop: "50px",
    "@media (max-width: 1100px)": {
      padding: "20 40px",
      width: "calc(100% - 410px)",
      marginLeft: "410px",
    },
    "@media (max-width: 991px)": {
      width: "auto !important",
      marginLeft: "0px",
      marginTop: "25px",
      padding: "10px 50px",
    },
  },
  "& .topbox": {
    display: "flex",
    width: "auto",
    height: "70px",
    marginBottom: "20px",
    justifyContent: "space-between",
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    marginLeft: "6px",
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: "#000",
    "@media (max-width: 450px)": {
      fontSize: "16px",
    },
  },
  "& .titlbtn": {
    height: "44px",
    width: "163px",
    border: "1px solid #355EE7",
    borderRadius: "8px",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#355EE7",
    fontFamily: "DM Sans",
    "@media (max-width: 450px)": {
      height: "40px",
      width: "140px",
      padding: "7px 9px",
    },
  },
  "& .titlbtn1": {
    height: "44px",
    width: "163px",
    border: "1px solid #355EE7",
    borderRadius: "8px",
    padding: "10px 12px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#fff",
    backgroundColor: "#355EE7",
    fontFamily: "DM Sans",
    textTransform: "none",
    "&:hover": {
      border: "2px solid #B9C5FF",
      boxShadow: "none !important",
    },
    "@media (max-width: 450px)": {
      height: "40px",
      width: "140px",
      padding: "7px 9px",
    },
  },

  "& .offcanvascss": {
    position: "fixed",
    top: "0px !important",
    bottom: "0px !important",
    left: "0% !important",
    width: "355px !important",
    height: "100vh !important",
    backgroundColor: "#fff",
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: "0px",
    left: "355px",
    width: "calc(100% - 355px)",
    height: "100vh",
    zIndex: 999,
    backgroundColor: "#000",
    opacity: 0.5,
  },
  "& .userBoxData": {
    boxShadow: "2px 20px 26px 6px #06050615",
    padding: "20px",
    borderRadius: "16px",
    width: "280px",
  },
  "& .userbox1": {
    marginBottom: "10px",
  },
  "& .userProfile": {
    display: "flex",
    width: "252px",
    position: "relative",
  },
  "& .imageBox": {
    width: "56px !important",
    height: "56px !important",
    borderRadius: "50% !important",
    border: "1px solid #F0E5FF",
    marginRight: "10px",
  },
  "& .userName": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    lineHeight: "18px",
    marginBottom: "5px",
    marginLeft: "2px",
  },
  "& .userContent": {
    fontFamily: "DM Sans",
    fontSize: "11px",
    fontWeight: 400,
    color: "#355EE7",
    padding: "2px 6px",
    background: "#ECF1FF",
    borderRadius: "6px",
    position: "relative",
  },
  "& .starIconCss": {
    fontSize: "14px",
    color: "#F59E0B",
    position: "absolute",
    top: "3px",
  },
  "& .tripledotcss": {
    position: "absolute",
    fontSize: "30px",
    color: "#595959",
    top: "7px",
    right: "8px",
  },
  "& .userbox2": {
    marginBottom: "15px",
  },
  "& .bagCss": {
    width: "18px",
    height: "18px",
  },
  "& .servicesLegalCss": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#1F1F1F",
    marginLeft: "10px",
  },
  "& .FamilyTextCss": {
    border: "1px solid #8C8C8C",
    borderRadius: "6px",
    color: "#8C8C8C",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
  },
  "& .Jurisditincss": {
    border: "1px solid #8C8C8C",
    borderRadius: "6px",
    color: "#8C8C8C",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
    width: "max-content",
  },
  "& .userbox3": {
    marginBottom: "15px",
  },
  "& .text4css": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#8C8C8C",
    marginLeft: "7px",
    marginTop: "3px",
  },
  "& .yearExpCss": {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#595959",
    marginLeft: "3px",
  },
  "& .activebtnCss": {
    textTransform: "capitalize",
    marginTop: "15px",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "#fff",
    background: "#4872FF",
    borderRadius: "6px",
    padding: "5px 15px",
    border: "2px solid #4872FF",
    boxShadow: "none !important",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid #B9C5FF",
      boxShadow: "none !important",
    },
  },
  "& .inactivebtnCss": {
    textTransform: "capitalize",
    marginTop: "15px",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 400,
    color: "  #8C8C8C",
    background: "#D9D9D9",
    borderRadius: "6px",
    padding: "5px 15px",
    border: "2px solid #D9D9D9",
    boxShadow: "none !important",
    cursor: "pointer",
  },
  "& .EditBtnCss": {
    width: "155px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    color: "#595959",
    borderRadius: "8px",
    border: "none",
    background: "#fff",
    "&:hover": {
      background: "#ECF1FF",
    },
  },
  "& .optionCss": {
    width: "155px",
    padding: "8px",
    position: "absolute",
    top: "30px",
    left: "200px",
    borderRadius: "8px",
    boxShadow: "2px 3px 6px 3px #0000000F",
    zIndex: 9999,
    background: "#fff",
  },
  "& .nouserdatacss": {
    height: "84vh",
    fontFamily: "DM Sans",
    fontSize: "29px",
    fontWeight: 400,
    color: "grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .browseBtn":{
      height:"46px",
      background:'#355EE7',
      padding:'0px 16px',
      marginLeft:'20px',
      color:"#fff",
      borderRadius:'8px',
      textTransform:'none',
      fontSize:'14px',
      fontWeight:400,
      fontFamily:'DM Sans',
      boxShadow:"none",
      border:"none",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
  },
  "& .classFlex":{
      display:'flex',
      width:'150px',
      border:'1px solid #355EE7',
      color:'#355EE7',
      borderRadius:'30px',
      padding:'5px 20px',
      alignItems:"center",
      gap:'10px',
      "@media (max-width: 1350px)":{
        fontSize:"12px",
      }
  },
  "& .parentFlex": {
      display:"flex",
      maxWidth: "90%"
  },
  "& .filterText": {
      fontSize:"12px",
  },
  "& .cardStyle":{
      width:"25%",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.25)",
      padding:'10px',
      borderRadius:'10px',
      display:'flex',
      flexDirection:'column',
      gap:"10px",
  },
  "& .cardbox1":{
      display:"flex",
      gap:'10px'
  },
  "& .cardImgP":{
      position:"relative",
  },
  "& .cardImg":{
      width:'40px',
      height:"40px",
      borderRadius:'50%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
  },
  "& .cardImg1":{
      width:'40px',
      height:"40px",
      position:'relative',
      zIndex:25,
  },
  "& .cardImg2":{
      width:'40px',
      height:"40px",
      position:'absolute',
      zIndex:26,
      left:"20px",
      top:'0px'
  },
  "& .cardImg3":{
      width:'40px',
      height:"40px",
      position:'absolute',
      zIndex:27,
      left:"40px",
      top:'0px'
  },
  "& .cardImg4":{
      width:'40px',
      height:"40px",
      position:'absolute',
      borderRadius:'50%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      zIndex:28,
      left:"60px",
      top:'0px'
  },
  "& .userContainer": {
    "@media (max-width:699px)": {
      margin:'0 auto',
      justifyContent:'center'
    },
  },
  "& .addnewTask":{
    "@media (min-width:699px)": {
      maxWidth:'80%'
    },
  },
});

const DrawerBox = styled("div")({
  minHeight: "100vh",
  width: "355px",
  "& .logobox": {
    display: "flex",
    justifyContent: "start",
    height: "76px",
    marginTop: "20px",
    marginLeft: "20px",
  },
  "& .logoimag": {
    height: "42px",
    width: "118px",
  },
  "& .settingtext": {
    color: "#262626",
    fontSize: "22px",
    marginLeft: "6px",
    fontWeight: 400,
    fontFamily: "DM Sans",
  },
  "& .settingbox": {
    display: "flex",
    height: "65px",
    cursor: "pointer",
  },

  "& .lefticon": {
    marginTop: "4px",
    marginLeft: "30px",
  },
  "& .listbox": {
    height: "110px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2": {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
        cursor: "pointer",
      },
      "& .icondrawer3": {
        backgroundColor: "#ECF1FF",
        color: "#355EE7",
      },
      "& .listtile": {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: "110px",
    backgroundColor: "#ECF1FF",
    borderRadius: "8px",
  },
  "& .icondrawer1": {
    marginBottom: "45px",
    color: "#355EE7",
    marginLeft: "18px",
    cursor: "pointer",
  },
  "& .icondrawer3": {
    marginLeft: "18px",
    marginBottom: "25px",
    color: "#434343",
  },
  "& .icondrawer2": {
    marginLeft: "18px",
    marginBottom: "42px",
    color: "#434343",
    cursor: "pointer",
  },

  "& .listtext": {
    fontSize: "14px",
    color: "#334155",
    fontFamily: "DM Sans",
    fontWeight: 400,
  },
  "& .listtile1": {
    fontSize: "16px",
    color: "#355EE7",
    fontFamily: "DM Sans",
    fontWeight: 400,
    cursor: "pointer",
  },
  "& .listtile": {
    fontSize: "16px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    cursor: "pointer",
    color: "#434343",
  },
  "@media (max-width: 1100px)": {
    zIndex: 999,
    width: "355px",
  },
  "@media (max-width: 991px)": {
    zIndex: 999,
    width: "355px",
    display: "none",
  },
});

const ButtonBox = styled("div")({
  display: "none",
  "@media (max-width: 991px)": {
    width: "100%",
    display: "block",
    minHeight: "70px",
    "& .buttonbox": {
      height: "70px",
      width: "100vw",
    },

    "& .iconbtn": {
      marginTop: "10px",
      marginLeft: "10px",
    },
  },
});

const Mapbox = styled("div")({
  
});

const PopUp = styled('div')({
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  fontFamily:'DM Sans',
  paddngTop:'3rem',
  overflow:'scroll',

  "& .noscroll" : {
    overflowY:"hidden",
    overflowX:"hidden",
    scrollbarWidth:"none",
  },
  "& .noscroll::-webkit-scrollbar": {
    display:"none",
  },

  '&.active': {
      opacity: 1,
      visibility: 'visible',
  },

  '& .popUpBody': {
      backgroundColor: "white",
      width: "550px",
      borderRadius: "20px",
      padding: "20px 40px",
  },

  '& .popUpBody2':{
      backgroundColor: "white",
      width: "600px",
      borderRadius: "20px",
      padding: "20px 40px",
  },

  '& .hoverCountrydata:hover': {
      backgroundColor: "rgba(145, 183, 221, 0.21)",
  },

  "& .browseBtn":{
      height:"46px",
      background:'#355EE7',
      padding:'30px 40px',
      marginLeft:'20px',
      color:"#fff",
      borderRadius:'8px',
      textTransform:'none',
      fontSize:'20px',
      fontWeight:400,
      fontFamily:'DM Sans',
      boxShadow:"none",
      border:"none",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
  },

  '& #slider':{
      accentColor:"#355EE7"
  },

  "& .statusError": {
  borderLeft: "4px solid #F87171", fontFamily: "DM Sans", fontWeight: "400", fontSize: "12px", textAlign: "left", 
  backgroundColor: "#FEE2E2", color: "#DC2626", boxShadow: "none", marginBottom: "20px"
},

"& .statusErrorCustom": {

  fontFamily: "DM Sans", fontWeight: "400", fontSize: "14px", textAlign: "left",
  color: "red", boxShadow: "none", marginBottom: "20px"

},
"& .errorOutLine":{
  border:'1px solid red',
},

"& .txtHeading":{
  color:'#423f3f',
  fontWeight:'bold'
},

"& .textHeadingValue" : {
  color:'gray'
}

});

// Customizable Area End
