import React from "react";

// Customizable Area Start
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { styled } from '@material-ui/core/styles';
import { logo,userpic} from "./assets";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Box, Typography} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer=()=>(

    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
        <div className="toolbar" />
        <Divider />
        <div className="logobox">
          <img src={logo} alt="logo" className="logoimag" data-test-id="logoImg"/>
        </div>
           {this.state.mobileOpen && <CloseIcon style={{position:'fixed',top:'30px',left:'210px',cursor:'pointer'}} onClick={this.handleclosesidebar}/>}
        <div className="settingbox">
        </div>
        <List className="MainListBox">
          <ListItem className="listbox1">
            <ListItemIcon><HomeOutlinedIcon className="icondrawer1" /></ListItemIcon>
            <ListItemText className="listtile1" data-test-id="Dashboard">
              Dashboard
            </ListItemText>
          </ListItem>
          <ListItem className="listbox" onClick={this.handleSettingIn}>
            <ListItemIcon><SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
            <ListItemText className="listtile" data-test-id="Setting">
              Settings
            </ListItemText>
          </ListItem>
        </List>
        <Box className="userProfile">
          <Box><img src={this.state.userdata?.profile_image?.url||userpic} className="imageBox"/></Box>
          <Box>
        <Typography className="userName">{this.state.userdata?.first_name} {this.state.userdata?.last_name}</Typography>
          <Typography className="userContent">Side subnav item</Typography>
          </Box>
        </Box>
      </DrawerBox>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    const buttonMenu = (
      <ButtonBox className="buttonbox">
        <IconButton
          className="iconbtn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={this.handleDrawerToggle}
          style={{ marginRight: '24px', color: '#000' }}
        >
          <MenuIcon />
  
        </IconButton>
      </ButtonBox>
    )
    return (
      <MainDiv data-test-id="NavigationMenu"
          style={{
            maxHeight: this.state.mobileOpen ? '100vh' : '',
            overflow: this.state.mobileOpen ? "hidden" : ""
          }}>
          <Box className="sidebar">
            {this.drawer()}
            {buttonMenu}
            {this.state.mobileOpen &&
              <>
                <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                  {this.drawer()}
                </div>
              </>
            }
          </Box>
          {this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>}
        </MainDiv>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "252px" ,
  borderRight:'1px solid #E2E8F0',
  "& .logobox": {
    display: 'flex',justifyContent: "start",
    height: "76px",
    padding:'17px 117px 17px 35px'
  },
  "& .logoimag": 
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox": 
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox":{
    padding:'0px 16px 8px 16px',
    position:'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius:'8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2": 
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display:'flex',
    justifyContent:'center',
    textAlign:'center',
    borderRadius:'8px',
    marginBottom:'10px'
  },
  "& .icondrawer1": {
    marginBottom: '7px',
    marginLeft: '32px',
    color:'#355EE7'
  },
  "& .icondrawer2": {
    marginLeft: '32px',
    marginBottom: '2px',
  },
  "& .listtile1": {
    fontSize: "12px",
    color: '#355EE7',
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor:'pointer',
    marginRight:'38px'
  },
  "& .listtile": 
  {
    fontSize: "12px",
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor:'pointer',
    marginLeft:'10px'
  },
  "& .userProfile":{
    display: "flex",
    width:'252px',
    position:'absolute',
    bottom:'10px',
    left:'32px'
  },
  "& .imageBox":{
    width:'32px',
    height:'32px',
    borderRadius:'50%',
    border:'1px solid #F0E5FF',
    marginRight:'10px',
  },
  "& .userName":{
    fontFamily:'DM Sans',
    fontSize:'14px',
    fontWeight:700,
    color:'#0F172A'
  },
  "& .userContent":{
    fontFamily:'DM Sans',
    fontSize:'11px',
    fontWeight:400,
    color:'#8C8C8C'
  },
  "@media (max-width: 1100px)": 
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
   {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
  display: 'none',
  "@media (max-width: 991px)": 
  {
    width: '100%',
    display: 'block',minHeight: '70px',
    "& .buttonbox": 
    {
      height: '70px',width: '100vw'
    },

    "& .iconbtn": 
    {
      marginTop: '10px',marginLeft: '10px'
    }
  }
})
const MainDiv = styled('div')({
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },  
})
// Customizable Area End
