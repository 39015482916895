import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean,
  showConfirmPassword: boolean,
  showPasswordCriteria: boolean,
  passwordError: string,
  errormsg:string,
  oldpassword:string,
  forgotToken:string,
  showPopUP:boolean,
  ResponseShoW:{
    error:string,
    success:string
  }
  loginNav:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  UserResetPasswordApiCallId: string = "";
  ForgotpasswordApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      showPasswordCriteria: false,
      passwordError: "",
      errormsg:"",
      oldpassword:'',
      forgotToken:"",
      showPopUP:false,
      ResponseShoW:{
        error:"",
        success:""
      },
      loginNav:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.UserResetPasswordApiCallId) {
      if(responseJson?.error=="Old password can not be reused"){
        this.setState({ passwordError: responseJson?.error })
      }
      else if(responseJson?.error){
        this.setState({showPopUP:true,ResponseShoW:{error:responseJson.error,success:""}})
      }
      else{
        this.setState({ loginNav:true,passwordError: "",oldpassword:'',showPopUP:true,ResponseShoW:{error:"",success:responseJson?.message} })
      }
    }
    if (apiRequestCallId === this.ForgotpasswordApiCallId) {
      if (responseJson?.message=="Email address not found") {
        this.setState({ errormsg: responseJson.message })

      } else {
        this.setState({ errormsg: "",showPopUP:true,ResponseShoW:{error:"",success:responseJson?.message} })
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    super.componentDidMount();

    this.handleUrlData()
  }
  handleUrlData =()=>{
    const urlParams = new URLSearchParams(window.location.search);

    const TokenReset = urlParams.get('token');

    if(TokenReset!= null||""){

      this.setState({ forgotToken: `${TokenReset}`})

    }
  }
  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }));
  };
  handleLogIn = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  handleRemoveError=()=>{
    this.setState({passwordError:""})
  }
  
  resetPassword = async (values: { password: string, confirmPassword: string }) => {
   
    const header = {
      "Content-Type": "application/json",
    };
      this.setState({oldpassword:values.password})

    const httpBody = {
      "data": {
        "new_password": values.password,
        "confirm_password": values.confirmPassword,
        "token": this.state.forgotToken,
      }

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UserResetPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/passwords"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleforgotpassword = async (values: { email: string}) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ForgotpasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/passwords/forgot_password_email?email=${values.email}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
