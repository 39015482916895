// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const logo = require("../assets/image_logo.svg");
export const userpic = require("../assets/user.jpg");
export const HomeLogo = require("../assets/homeLogo.png");
export const right = require("../assets/image_.png");
export const cross = require("../assets/cross.png");
export const file = require("../assets/file.png");
export const filter = require("../assets/filter.png");
export const backk = require("../assets/backk.png");
export const Services = require("../assets/services.png");
export const request = require("../assets/request.png");
// Customizable Area End