import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList ,  IGroup,
  IAccount,
  IGroupAccount,
  Status,
  Priority,} from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface TaskProfile {
  isSelected: boolean;
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: any;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    id: string;
    account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
  };
}
export interface LegalServiceType {
  id: number,
  name: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose:boolean
  handleRejectClose:boolean
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  taskList: ITask[];
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];

  
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  taskView:{
  [x: string]: any;
  ids: string;
  attributes: {
    task_name: string;
    legel_service_type: LegalServiceType;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    jurisdiction:string,
    deadline:string,
    description:string
    created_at:string
  };
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  isExpanded: boolean,  
  shouldShowSeeMore: boolean, 
  isContentLong: boolean,
  acceptTask:boolean,
  rejectTask:boolean,
  accepted:boolean,
  isTaskPosted:boolean,
  loading:boolean
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallIdd=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      groupList: [],
      selectedAccounts: [],
      accountsData: [],
      statusList: [
        { id: 1, name: "to_do" },
        { id: 2, name: "in_progress" },
        { id: 3, name: "complete" },
      ],
      priorityList: [
        { id: 1, name: "low" },
        { id: 2, name: "medium" },
        { id: 3, name: "high" },
      ],
      name: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      taskView:{
        ids:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:""
            }
        },
      taskViewId:"",
      isExpanded: false, 
      shouldShowSeeMore: false, 
      isContentLong: false,
      acceptTask:false,
      rejectTask:false,
      accepted:false,
      isTaskPosted:false,
      loading:true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTaskLists();
  }



  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

    handleEditProject = () => {
      const message: Message = new Message(getName(MessageEnum.NavigationProjectTemplatesMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationProjectTemplatesWithId), this.state.taskViewId);
  
      this.send(message);

    }

  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawyer");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleAcceptTask = () => {
    this.setState((prevState) => ({
      accepted: !prevState.accepted,
      drawerOpen: false
    }));
  };  
  handleClosePopup = () => {
    this.setState({ accepted: false});
}
handleClosed = () => {
  this.setState({rejectTask: false });
}

 handleRejectTask = () => {
    this.setState((prevState) => ({
      rejectTask: !prevState.rejectTask,
      drawerOpen: false
    }));
  };

  handleNavigateDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "LawyerDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
  );

  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    if (apiRequestCallId === this.getTaskListsApiCallId) {
      if (responseJson.data) {
        this.setState({ taskProfile: responseJson.data,
          loading:false
        });
      } else {
        this.setState({ taskProfile: [], });
      }
    } 
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson.data) {
        const status = responseJson.data.attributes.status;
        this.setState({ taskView: responseJson.data,
          isTaskPosted: status === "task_posted"
        });
      } else{
        this.setState({ 
          isTaskPosted: false
          
        });
      }
    }

  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hideAssignModal = () => {
    this.setState({ isVisibleAssignModal: false });
  };

  handleInputTitle = (title: string) => {
    this.setState({ title });
  };

  handleInputDescription = (description: string) => {
    this.setState({ description });
  };

  toggleAssignModal = () => {
    this.setState({
      dropdownAccount: false,
      dropdownGroup: false,
      isVisibleAssignModal: !this.state.isVisibleAssignModal,
      editMode: true,
    });
  };

  showAddModal = () => {
    this.setState({
      title: "",
      description: "",
      priority: "",
      status: "",
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandGroupView = () => {
    this.setState({
      dropdownGroup: !this.state.dropdownGroup,
    });
  };

  expandStatusView = () => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus,
    });
  };

  expandPriorityView = () => {
    this.setState({
      dropdownPriority: !this.state.dropdownPriority,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccount: !this.state.dropdownAccount });
  };

  handleAccountSelect = (dataId: string) => {
    let newData = this.state.accountsData.map((account: IAccount) => {
      if (account.id === dataId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ accountsData: newData });
  };

  handleSelectPriority = (item: Priority) => {
    this.setState({ priority: item.name, dropdownPriority: false });
  };

  handleSelectStatus = (item: Status) => {
    this.setState({ status: item.name, dropdownStatus: false });
  };

  handleAssignToModal = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    if (item.attributes.assigned_to) {
      this.setState({
        assign_to:
          item.attributes.assigned_to.type === "group"
            ? item.attributes.assigned_to.attributes.name
            : item.attributes.assigned_to.attributes?.first_name || "",
        assign_to_type: item.attributes.assigned_to.type,
      });
    }
    this.toggleAssignModal();
  };

  handleEditTask = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    this.showEditModal();
  };

  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallIdd = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addTask = () => {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
        priority: this.state.priority,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  editTask = (taskId: string) => {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
        priority: this.state.priority,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  deleteTask = (taskId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleAssignTo = (
    taskId: string,
    assignToType: string,
    assignToId: string
  ) => {
    if (
      this.isStringNullOrBlank(taskId) ||
      this.isStringNullOrBlank(assignToId)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        assigned_to_type: assignToType,
        assigned_to_id: assignToId,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.assignGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}` + "/assign"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };



  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  handleClose = () => {
    this.setState({ open: false });

  };

  toggleDrawer = (task:{id:string}) => {   
    const taskId = String(task.id); 
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen, 
      taskViewId: taskId, 
    }));
    this.lawyerTasks()
  };


  handleSeeMoreClick = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false ,isExpanded:false}); 
  };



  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
 
  lawyerTasks = async () => {
    const signupTokens: string = await getStorageData("userdetails");    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
