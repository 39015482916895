Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.updatLawfirmEndPoint = "/account_block/accounts/";
exports.apiMethodTypeUpdate = "PUT";
exports.apiMethodTypeAdd = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.inviteLinkEndPoint ="bx_block_email_notifications/email_notifications/send_signup_invite";
exports.getApiMethod = 'GET';
exports.PutAPiMethod = 'PUT';
exports.getUserDataEndPoint ="account_block/accounts/";
exports.getApiEndPoint ="bx_block_catalogue/catalogues/list_of_associated_lawyers";
exports.PUTAPIDeleteEndPoint ="bx_block_categories/categories/remove_associated_lawyer?account_id=";
exports.PostBulkUploadEndPoint = "bx_block_bulk_uploading/attachments";
exports.getClientLayerCatalogueEndpoint ="bx_block_catalogue/catalogues/lawfirm_lawyers_catalogue?firm_name=";

// Customizable Area End