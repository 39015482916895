import React from "react";
import { styled } from '@material-ui/core/styles';
import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Define the props interface
interface ErrorSuccessPopUpProps {
    showPop: boolean
    setShowPop: (newState: boolean) => void;
    showResponse: {
        error: string;
        success: string;
    };
    termfunction: () => void;
    deletePop:boolean;
    loginNav:()=>void;
}

const ErrorSuccessPopUp: React.FC<ErrorSuccessPopUpProps> = ({ showPop, setShowPop, showResponse ,termfunction,deletePop,loginNav }) => {
    const handleClosePopUp = () => {
        setShowPop(false);
        if (showResponse.success != "") {
            termfunction()
        }
        if(deletePop){
            loginNav()
        }
    };

    return (
        <>
            <Dialog
                open={showPop}
                onClose={handleClosePopUp}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                        minWidth:'430px',
                        textAlign:'center'
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'right' }}>
                    <CloseIcon onClick={handleClosePopUp} style={{cursor:'pointer'}} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography style={{ textAlign: 'center' }}>
                            {showResponse.success? <CheckCircleIcon style={{
                                color: '#34D399',
                                fontSize: '40px',
                                fontWeight: 500,
                                marginBottom: '20px'
                            }} />:
                            <ErrorOutlineIcon style={{
                                color: '#DC2626',
                                fontSize: '40px',
                                fontWeight: 500,
                                marginBottom: '20px'
                            }} />}
                        </Typography>
                        <Typography
                            style={{
                                color: '#262626',
                                fontSize: '16px',
                                fontWeight: 400,
                                fontFamily: 'DM Sans',
                                marginBottom: '10px'
                            }}
                        >
                            {showResponse?.error||showResponse?.success} 
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '20px', borderTop: '1px solid #E2E8F0' }}>
                    <ButtonBox2
                    onClick={handleClosePopUp}
                    className="logtxtdialog2"
                    autoFocus
                        style={{
                            backgroundColor: "#355EE7",
                            color: '#ffffff',
                            borderRadius: "8px",
                            fontSize: '16px',
                            fontWeight: 400,
                            fontFamily: 'DM Sans',
                            marginLeft: '15px',
                            boxShadow: 'none !important',
                            border: '2px solid #355EE7 ',
                            textDecoration: 'none',
                            width:'100%',
                            padding:'10px 2px',
                            cursor:'pointer'
                        }}
                    >
                        Ok
                    </ButtonBox2>
                </DialogActions>
            </Dialog>
        </>
    );
};

const ButtonBox2 = styled('button')({
      '&:hover': {
        border:'2px solid #B9C5FF !important',
        boxShadow:'none !important'
      },
    })

export default ErrorSuccessPopUp;
