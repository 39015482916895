// @ts-nocheck
import React from "react";

// Customizable Area Start
import { styled } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import { Field, Formik } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { logo, userpic, right } from "./assets";
import * as Yup from "yup"
import Select from "react-select";
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import { CountryDatatype } from './ProjectTemplatesController'

import {
  Typography,
  // Customizable Area Start
  Container,
  Grid
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  drawer = () => (
    <DrawerBox style={{ display: this.state.mobileOpen ? 'block' : '' }}>
      <div className="toolbar" />
      <Divider />
      <div className="logobox">
        <img src={logo} alt="logo" className="logoimag" data-test-id="logoImg" />
      </div>
      {this.state.mobileOpen && <CloseIcon style={{ position: 'fixed', top: '30px', left: '210px', cursor: 'pointer' }} onClick={this.handleclosesidebar} />}
      <div className="settingbox">
      </div>
      <List className="MainListBox">
        <ListItem onClick={this.handleNavigationDashboard} className="listbox1">
          <ListItemIcon><HomeOutlinedIcon className="icondrawer1" /></ListItemIcon>
          <ListItemText className="listtile1" data-test-id="Dashboard">
            Dashboard
          </ListItemText>
        </ListItem>
        <ListItem className="listbox" onClick={this.handleSettingIn}>
          <ListItemIcon><SettingsOutlinedIcon className="icondrawer2" /></ListItemIcon>
          <ListItemText className="listtile" data-test-id="Setting">
            Settings
          </ListItemText>
        </ListItem>
      </List>
      <Box className="userProfile">
        <Box><img src={userpic} className="imageBox" /></Box>
        <Box>
          <Typography className="userName"></Typography>
          <Typography className="userContent">Side subnav item</Typography>
        </Box>
      </Box>
    </DrawerBox>
  )
  buttonMenu = () => (
    <ButtonBox className="buttonbox">
      <IconButton
        className="iconbtn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={this.handleDrawerToggle}
        style={{ marginRight: '24px', color: '#000' }}
      >
        <MenuIcon />

      </IconButton>
    </ButtonBox>
  )
  isSchema = () => {
    return Yup.object().shape({
      taskname: Yup.string().required(' '),
      trademark: Yup.string().required(' '),
      tradeAppNum: Yup.string().required(' '),
      deadline: Yup.string().required(' '),
      jurisdiction: Yup.string().required(' '),
    });
  };

  handleReturn = (errors: string, touched: string, name: string) => {
    return errors[name] && touched[name]
  }

  getFieldColor = (errors, touched, fieldName) => {
    return this.handleReturn(errors, touched, fieldName) ? 'red' : 'initial';
  };
  handleGetCountryData = (item: CountryDatatype, index: number) => {
    return (
      <option data-test-id={`country-${index}`} key={index} style={{ color: '#000' }} value={item?.attributes?.name} >{item?.attributes?.name}</option>
    )
  }

  handleLegalServiceTypeChange = (selectedOption, setFieldValue) => {
    if (selectedOption) {
      setFieldValue("legalServiceType", selectedOption.id);
    }
  };

  isFormEmpty = (values) => {
    return !Object.values(values).some(value => {
      if (typeof value === 'string') {
        return value !== '';
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value != null && value !== '';
    });
  };



  getStyleData = (isFormEmpty) => {
    return {
      color: isFormEmpty ? "#BFBFBF" : "white",
    };
  };

  disableStyle = (isFormEmpty) => {
    return {
      backgroundColor: isFormEmpty ? "#BFBFBF" : "#ffff",
    };
  };
  getJurisdictionColor = (values) => {
    return values.jurisdiction ? 'black' : 'grey';
  };

  handleFormSubmission = (values) => {
    if (this.state.isSaveDraft) {
      this.handleSaveDraft(values);
    } else {
      this.submitData(values, false);
    }
  };

  getInitialValues = () => {
    const { taskData } = this.state;

    return {
      taskname: taskData?.attributes?.task_name || '',
      trademark: taskData?.attributes?.trademark_name || '',
      tradeAppNum: taskData?.attributes?.trademark_application_number || '',
      deadline: taskData?.attributes?.deadline || '',
      legalServiceType: taskData?.attributes?.legel_service_type?.name || '',
      jurisdiction: taskData?.attributes?.jurisdiction || '',
      description: taskData?.attributes?.description || ''
    };
  };



  formikData = () => {
    return (
      <Formik
        data-test-id="userboxForm"
        enableReinitialize={true}
        initialValues={this.getInitialValues()}
        validationSchema={this.state.isSaveDraft ? "" : this.isSchema}
        onSubmit={(values, { resetForm }) => {
          this.handleFormSubmission(values);
          setTimeout(() => {
            resetForm();
          }, 500);
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur }) => {
          return (

            <form className="thirdForm" onSubmit={handleSubmit}>
              <Box >
                <Box className="detailsbox">
                  <Box className="personaldetailtext">
                    <Typography style={{ fontWeight: "700" }}>Tasks info</Typography>
                  </Box>
                  <Box className="personalform">
                    <Box className="lawFirm">
                      <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 400, color: this.getFieldColor(errors, touched, 'tradeAppNum') }}>
                        Task Name*
                      </Typography>
                      <Field as={TextField}
                        data-test-id="taskname"
                        name="taskname"
                        fullWidth
                        error={this.handleReturn(errors, touched, 'taskname')}
                        InputProps={{
                          style: {
                            borderRadius: '12px',
                            fontSize: "16px",
                            fontWeight: 400,
                            height: "44px",
                          },
                        }}
                        style={this.getStyleData(this.isFormEmpty(values))}
                        variant="outlined"
                        placeholder="Enter the taskname name"

                        value={values.taskname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                    </Box>
                    <Box style={{ display: 'flex', flex: 'flex-wrap' }}>
                      <Box className="firstname">
                        <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 400, color: this.getFieldColor(errors, touched, 'tradeAppNum') }}>
                          Trademark Name*
                        </Typography>
                        <Field as={TextField}
                          data-test-id="trademark"
                          name="trademark"
                          style={this.getStyleData(this.isFormEmpty(values))}
                          fullWidth
                          error={this.handleReturn(errors, touched, 'trademark')}
                          InputProps={{
                            style: {
                              borderRadius: '12px',
                              fontSize: "16px",
                              fontWeight: 400,
                              height: "44px",

                            },

                          }}
                          variant="outlined"
                          placeholder="Enter the trademark name"

                          value={values.trademark}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box className="lastname">
                        <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 400, color: this.getFieldColor(errors, touched, 'tradeAppNum') }}>
                          Trademark Application Number*
                        </Typography>
                        <Field as={TextField}
                          name="tradeAppNum"
                          data-test-id="tradeAppNum"
                          style={this.getStyleData(this.isFormEmpty(values))}
                          fullWidth
                          error={this.handleReturn(errors, touched, 'tradeAppNum')}
                          InputProps={{
                            style: {
                              borderRadius: '12px',
                              fontSize: "16px",
                              fontWeight: 400,
                              height: "44px",
                            },

                          }}
                          variant="outlined"
                          placeholder="Enter trademark application number"
                          value={values.tradeAppNum}
                          onChange={handleChange}
                          onBlur={handleBlur}

                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="detailsbox">
                  <Box className="personaldetailtext">
                    <Typography style={{ fontWeight: "700" }}>Legal info</Typography>
                  </Box>
                  <Box className="personalform">
                    <Box style={{ display: 'flex', paddingBottom: '6px' }}>
                      <Box className="firstname">
                        <Typography style={{ fontSize: '16px', fontWeight: 400, color: this.getFieldColor(errors, touched, 'tradeAppNum') }}>
                          Deadline*
                        </Typography>
                        <Field
                          as={TextField}
                          data-test-id="trademark"
                          style={this.getStyleData(this.isFormEmpty(values))}
                          name="deadline"
                          type="date"
                          fullWidth
                          error={this.handleReturn(errors, touched, 'deadline')}
                          InputProps={{
                            style: {
                              borderRadius: '12px',
                              fontSize: "16px",
                              fontWeight: 400,
                              height: "44px",
                              color: values.deadline ? "black" : "grey"
                            },
                          }}
                          variant="outlined"
                          placeholder="Enter the deadline"

                          value={values.deadline}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{
                            min: new Date().toISOString().split('T')[0],
                          }}
                        />


                      </Box>
                      <Box className="lastname">
                        <Typography variant="subtitle1" className="firstNamePlaceHolder">
                          Legal Service Type
                        </Typography>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>

                          <Select
                            name="legalServiceType"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#BFBFBF',
                                fontFamily: 'DM Sans',
                                fontSize: "16px",
                                fontWeight: 400,
                              }),
                            }}
                            data-test-id="legal_services"
                            placeholder="Select a legal service type"
                            options={this.state.optionsShow?.map((item: { value: string; label: string; id: number }) => ({
                              value: item.value,
                              label: item.label,
                              id: item.id,
                            }))}
                            className="selectlegal"
                            onChange={(selectedOption) => this.handleLegalServiceTypeChange(selectedOption, setFieldValue)}
                            value={this.state.optionsShow?.find((option: { id: number; value?: string }) => {
                              return option.id === values.legalServiceType || option.value === values.legalServiceType;
                            }) || null}
                          />

                        </Box>
                      </Box>
                    </Box>
                    <Box className="lawFirm">
                      <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 400, color: this.getFieldColor(errors, touched, 'tradeAppNum') }}>
                        Jurisdiction*
                      </Typography>

                      <select
                        placeholder="Select a jurisdiction"
                        className="selectCountry"
                        style={{
                          width: '100%',
                          border: errors.jurisdiction && touched.jurisdiction ? '1px solid red' : '1px solid #C3D1FF',
                          borderRadius: '12px',
                          fontSize: '16px',
                          fontWeight: 400,
                          outline: 'none',
                          height: '44px',
                          appearance: 'none',
                          WebkitAppearance: 'none',
                          paddingLeft: '11px',
                          color: this.getJurisdictionColor(values)
                        }}
                        data-test-id="country"
                        name="jurisdiction"
                        value={values.jurisdiction}

                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option style={{
                          color: 'grey',
                          backgroundColor: 'white',
                          fontStyle: 'italic',
                          hover:{
                            backgroundColor: "grey",
                            color: "black",
                          }
                        }} value="" >
                          Select a jurisdiction
                        </option>
                        {this.state.countryData?.map(this.handleGetCountryData)}
                      </select>

                    </Box>
                  </Box>
                </Box>
                <Box className="detailsbox2">
                  <Box className="personaldetailtext">
                    <Typography style={{ fontWeight: "700" }}>Notes</Typography>
                  </Box>
                  <Box className="personalform">
                    <Box className="lawFirm">
                      <Typography variant="subtitle1" className="firstNamePlaceHolder">
                        Description
                      </Typography>
                      <TextField
                        name="description"
                        inputProps={{ "data-test-id": "lawfirm" }}
                        InputProps={{
                          style: {
                            paddingBottom: "66px",
                            borderRadius: '12px',
                            fontSize: "16px",
                            fontWeight: 400,
                            borderColor: "blue",
                          },

                        }}
                        style={this.getStyleData(this.isFormEmpty(values))}

                        fullWidth
                        variant="outlined"
                        placeholder="Add any additional information"
                        onChange={handleChange}
                        value={values.description}

                      />
                    
                    </Box>
                    <Box className="Password">
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>


                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="accountsbox">
                  <Box className="personaldetailtext">
                    <Box>
                      <button data-test-id="signup" className="document"  >
                        <Typography style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "1px"
                        }}><AttachFileIcon style={{
                          fontSize: "23px"
                        }} />  Documents Folder</Typography>
                      </button>
                    </Box>
                  </Box>
                  <Box className="accountsactions">
                    <Box className="logouttxt" data-test-id="logoutdBtn" onClick={this.handleClickOpen}>
                      <Box>
                        <button disabled={this.isFormEmpty(values)}
                          style={this.disableStyle(this.isFormEmpty(values))}
                          onClick={this.handleDraftTrue} type="submit" data-test-id="Logintext"
                          className="landingLoginBtn">
                          Save as draft
                        </button>
                      </Box>
                      <Box>
                        <button onClick={this.handleDraftFalse} type="submit" data-test-id="signup" className="landingSignupBtn"  >
                          Submit
                        </button>
                      </Box>

                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    )
  }

  sidebarr = () => {
    return (this.state.mobileOpen &&
      <>
        <div className="offcanvascss" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          {this.drawer()}
        </div>
      </>

    )
  }

  sideBarr2 = () => {
    return (
      this.state.mobileOpen && <div className="offcanvasbackdrop" onClick={this.handleclosesidebar}></div>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <><MainDiv data-test-id="projectTemplatesProfiles" style={{
        maxHeight: this.state.mobileOpen ? '100vh' : '',
        overflow: this.state.mobileOpen ? "hidden" : ""
      }}>
        <Box className="sidebar">
          {this.drawer()}
          {this.buttonMenu()}
          {this.sidebarr()}
        </Box>
        {this.sideBarr2()}
        <Box className="rightbar">
          <Box className="topbox">
            <Typography data-test-id="backbtn" className="titleuser2" onClick={this.handleNavigation} >
              <ArrowBackIcon style={{
                color: "blue",
                cursor: "pointer"
              }} /> Back
            </Typography>
          </Box>
          <Box className="topbox">
            <Typography className="titleuser3">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link onClick={this.handleNavigation} style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#475569",
                  cursor: "pointer"
                }}  >
                  All Tasks
                </Link>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F172A"
                  }}
                  color="textPrimary"
                >
                  {this.state.taskViewId ? "Edit task" : "Add new task"}
                </Typography>

              </Breadcrumbs>

            </Typography>



          </Box>

          <Box className="userdetailbox">
            {this.formikData()}
          </Box>
        </Box>
        <Dialog
          PaperProps={{
            style: {
              width: '59vh',
              height: "auto",
              borderRadius: "12px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }
          }} style={{}} open={this.state.openDialog} onClose={this.handleCloseDelete}
          aria-labelledby="responsive-dialog-title" >

          <Box 
          className=""
          sx={{
            width: "57vh",
            borderRadius: "8px",
            border: "1px solid #D1FAE5",
            height: "215px",
            "@media (max-width: 900px)": {
              width: "53vh",
            },
            "@media (max-width: 575px)": {
              width: "auto",
            },
          }}>
            <DialogTitle id="responsive-dialog-title" style={{
              textAlign: 'right',
              padding: "7px"
            }}><CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleCloseDelete} /></DialogTitle>
            <DialogContent
              style={{
                padding: "0px"
              }}
            >
              <DialogContentText  >
                <Typography style={{
                  backgroundColor: '#ffffff',
                  color: '#0F172A',
                  fontSize: '19px',
                  fontWeight: 400,
                  fontFamily: 'DM Sans',
                  marginBottom: '20px',
                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <img src={right} className="imageBox2" alt="success" />
                  </Box>
                  <Typography style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                    fontSize: "22px",
                    fontFamily: "Dm sans",
                    fontWeight: 400
                  }}>
                    {this.state.responseShow.success ? this.state.responseShow.success : "Task Updated successfully"}
                  </Typography>
                </Typography>
              </DialogContentText>
            </DialogContent></Box>


        </Dialog>


      </MainDiv>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start



const MainDiv = styled('div')({
  "& .custom-datepicker-input": {
    width: "100%",
    border: "1px solid #C3D1FF",
    borderRadius: "12px",
    fontSize: "16px",
    fontWeight: 400,
    outline: "none",
    height: "44px",
    padding: "10px",
  },
  "& .custom-datepicker-input.error": {
    border: "1px solid red"
  },

  "& .custom-datepicker-input::placeholder ": {
    color: '#BFBFBF',
    fontFamily: 'DM Sans',
    fontSize: "16px",
    fontWeight: 400
  },
  "& .offcanvascss": {
    position: 'fixed',
    top: '0px !important',
    bottom: '0px !important',
    left: '0% !important',
    width: '252px !important',
    height: '100vh !important',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  "& .offcanvasbackdrop": {
    position: "fixed",
    top: '0px',
    left: '252px',
    width: 'calc(100% - 252px)',
    height: '100vh',
    zIndex: 999,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  width: '100%',
  height: '100%',
  display: 'flex',
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #C3D1FF',
  },
  "@media (max-width:991px)": {
    display: "block",
  },
  "& .selectCountry": {
    width: '100%',
    border: '1px solid #C3D1FF', borderRadius: "12px",
    marginBottom: '8px', outline: 'none', backgroundColor: '#fff',
    fontSize: '16px', height: "44px",
    backgroundRepeat: 'no-repeat', backgroundPositionX: '95%',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'%23c5cbc6\' height=\'20\' viewBox=\'0 0 24 24\' width=\'20\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M7 10l5 5 5-5z\'/></svg>")',
    fontWeight: 400, fontFamily: "DM Sans",
    backgroundPositionY: 'center', appearance: 'none',
    '&:hover': {
      border: '1px solid #C3D1FF', borderRadius: "12px"
    },
    "&:focus": {
      border: '2px solid #C3D1FF;', borderRadius: "12px"
    },
  },
  "& .selectlegal": {
    width: '100% !important',
    borderRadius: '12px', fontSize: '16px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    height: "44px",
    "& .css-13cymwt-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px"

    },
    "& .css-t3ipsp-control": {
      borderColor: "#C3D1FF !important",
      border: "1px solid",
      borderRadius: "12px",
      fonSize: "16px",
      fontweight: 400,
      outline: "none",
      position: "relative",
      bottom: "3px",
      height: "44px",
      boxShadow: "0px 0px 0px 0px"

    },
    "& .css-1u9des2-indicatorSeparator": {
      backgroundColor: "white"
    },
    "&:focus": {
      border: '1px solid #C3D1FF;', borderRadius: "12px"
    },
    "@media (max-width:500px)": {
      width: '100% !important',
      borderRadius: '12px', fontSize: '8px',
      fontWeight: 200,
      fontFamily: "DM Sans",
      height: "44px",
    },
  },

  "& .landingSignupBtn": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }
  },
  "& .document": {
    backgroundColor: "#355EE7",
    color: "white",
    width: "179px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: 'none',

  },
  "& .landingLoginBtn": {
    backgroundColor: "#fff",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    color: "#355ee7",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: '1px solid #d9d9d9',
    boxShadow: 'none !important',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important'
    }

  },
  "& .disabled": {
    backgroundColor: "#fff",
    width: "145px",
    height: '46px',
    textTransform: "none" as "none",
    borderRadius: "8px",
    padding: "10px",
    color: "grey",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "DM Sans",
    cursor: "pointer",
    border: '1px solid #d9d9d9',
    boxShadow: 'none !important',

  },
  "& .firstNamePlaceHolder": {
    fontSize: "16px",
    fontWeight: 400
  },
  "& .sidebar": {
    height: '100%',
    position: "fixed",

    margin: 0,
    padding: 0,
    top: '0',
    left: '0',
    zIndex: 9999,
    "@media (max-width: 1100px)": {
      width: "300px"
    },
    "@media (max-width: 991px)": {
      width: '100vw',
      height: '80px'
    }
  },
  "& .mainheader": {
    position: 'fixed',
    left: '350px',
    width: '100%'
  },
  "& .logtxtdialog": {
    backgroundColor: "#355EE7",
    color: '#ffffff',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'DM Sans',
    marginLeft: '15px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      border: '2px solid #B9C5FF',
      boxShadow: 'none !important',
    },
  },
  "& .Header": {
    height: '50px',
    fontSize: '20px', padding: '20px',
  },
  "& .rightbar": {
    padding: "10px 0px",
    width: "100%",
    marginLeft: '253px',
    backgroundColor: "#fafafa",
    height: "110vh",
    "@media (max-width: 1100px)": {
      width: 'calc(100% - 300px)',

    },
    "@media (max-width: 991px)": {
      width: 'auto !important',
      marginLeft: '0px', marginTop: '50px',
      padding: "10px 50px",
    }
  },
  "& .userdetailbox": {
    width: '90%',
    padding: "20px",
    backgroundColor: "#ffffff",
    height: "auto",
    marginLeft: "61px",
    marginRight: "38px",
    borderRadius: "20px",
    marginBottom: "10px",

    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    "@media (max-width: 1424px)": {
      width: '89%',
      height: "auto",
      marginLeft: "51px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 1366px)": {
      width: '87%',
      padding: "20px",
      backgroundColor: "#ffffff",
      marginLeft: "61px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      height: "auto",
    },
    "@media (max-width: 1280px)": {
      width: '87%',
      height: "auto",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      borderRadius: "20px",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

    },
    "@media (max-width: 780px)": {
      width: '87%',
      fontSize: "20px",
      height: "auto",
      marginLeft: "31px",
      borderRadius: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",

      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
    },
    "@media (max-width: 600px)": {

      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      width: '87%',
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    "@media (max-width: 520px)": {
      width: '87%',
      height: "auto",
      marginLeft: "24px",
      borderRadius: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      fontSize: "20px",
      padding: "20px",
      backgroundColor: "#ffffff",
    },
  },
  "& .profilebox": {
    height: '156px',
    display: 'flex', borderBottom: '1px solid #D9D9D9',
    "@media (max-width: 450px)": {
      height: '120px',
    },
  },
  "& .profileImg": {
    display: 'flex',
    justifyContent: "center", width: "80%",
  },
  "& .profiletext": {
    fontSize: '16px',
    fontWeight: 400, color: '#8C8C8C', fontFamily: 'DM Sans',
  },
  "& .userimg": {
    height: '120px',
    width: '120px',
    border: '4px solid #ECF1FF',
    borderRadius: '50%',
    "@media (max-width: 450px)": {
      height: '80px',
      width: '80px',
    },
  },
  '& .topbox': {
    display: 'flex',
    width: "auto",
    height: '70px',
    justifyContent: 'space-between',
    "@media (max-width: 991px)": {
      width: "auto !important",
    },
  },
  "& .titleuser": {
    height: '30px',
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser2": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "13px",
    width: '230px',
    fontSize: '27px',
    fontWeight: 700,
    fontFamily: "DM Sans",
    color: '#0F172A',
    marginLeft: "55px",
    marginTop: "13px",
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titleuser3": {
    height: '30px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "5px",
    width: '230px',
    fontSize: '22px',
    fontWeight: 400,
    fontFamily: "DM Sans",
    marginLeft: "80px",
    color: '#000',
    "@media (max-width: 450px)": {
      width: '200px',
      fontSize: '16px',
    },
  },
  "& .titlbtn": {
    height: '44px',
    fontSize: '14px', fontWeight: 400, color: '#355EE7', fontFamily: 'DM Sans',
    width: '163px', border: '1px solid #355EE7', borderRadius: '8px', padding: '10px 12px',
    "@media (max-width: 450px)": {
      height: '40px',
      width: '140px', padding: '7px 9px',
    },
  },
  "& .detailsbox": {
    display: 'flex', marginTop: '20px', paddingBottom: '40px',
    borderBottom: '1px solid #D9D9D9', "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .detailsbox2": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 600px)": {
      display: 'block'
    },

  },
  "& .personaldetailtext": {
    width: '15%', fontSize: '20px', fontWeight: 700,
    fontFamily: 'DM Sans', color: 'black',
    "@media (max-width: 600px)": {
      width: '100%', marginBottom: '20px'
    },
  },
  "& .personalform": {
    width: '88%',
    position: 'relative',
    "@media (max-width: 600px)": { width: '100%' },
  },
  "& .fullName": {
    width: '100%', display: 'flex', paddingBottom: '30px',
    "@media (max-width: 650px)": {
      display: 'block', width: '100%'
    }
  },
  "& .firstname": {
    width: "49%", marginRight: "10px",
    "@media (max-width: 650px)": {
      width: "91% !important", marginBottom: '15px',
    },
  },
  "& .lastname": {
    width: "49%",

    "@media (max-width: 650px)": { width: "91% !important", },
  },
  "& .lawFirm": {
    width: "99%", marginBottom: '15px',
  },
  "& .country": {
    width: "91%", marginBottom: '15px',
  },
  "& .PhoneNumer": {
    width: "91%", marginBottom: '15px',
  },
  "& .Email": {
    width: "91%", marginBottom: '15px',
  },
  "& .Password": {
    width: "91%", marginBottom: '15px', height: "28px",
  },
  "& .passtext": {
    color: "#434343", fontSize: '14px',
    fontWeight: 400, fontFamily: 'DM Sans',
  },
  "& .changepasstext": {
    color: "#6200EA", fontSize: '14px',
    fontWeight: 700, fontFamily: 'DM Sans', cursor: 'pointer', marginTop: '4px',
  },
  "& .accountsbox": {
    display: 'flex', marginTop: '20px',
    "@media (max-width: 697px)": {
      display: 'block',
      marginTop: '10px',
      flexDirection: "column",
      gap: "20px"
    },
  },
  "& .accountdetailtext": {
    width: '20%', color: "#8C8C8C",
    fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    "@media (max-width: 600px)": {
      width: '100%',
      marginBottom: '4px'
    },
  },
  "& .accountsactions": {
    width: '84.3%',
    display: "flex", justifyContent: "flex-end",
    "@media (max-width: 697px)": {
      width: '100%',
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  "& .logouttxt": {
    display: 'flex', alignItems: 'center', margin: '0px 0px 20px 0px', gap: "20px",
    color: "#434343", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .deletetxt": {
    display: 'flex', alignItems: 'center',
    margin: '20px 0px', color: "#DC2626", fontSize: '16px', fontWeight: 400, fontFamily: 'DM Sans',
    cursor: 'pointer',
  },
  "& .errorOutline": {
    '& .MuiFormControl-root': {
      '& fieldset': {
        borderColor: 'red 1px solid',
      },
    },
  },
  "& .MuiInputBase-input": {
    padding: '18.5px 14px',
  }
})


const DrawerBox = styled('div')({
  minHeight: '100vh',
  width: "252px",
  borderRight: '1px solid #E2E8F0',
  "& .logobox": {
    display: 'flex', justifyContent: "start",
    height: "76px",
    padding: '17px 117px 17px 35px'
  },
  "& .logoimag":
  {
    height: '42px',
    width: '118px',
  },
  "& .settingbox":
  {
    display: 'flex',
    height: "65px",
  },
  "& .MainListBox": {
    padding: '0px 16px 8px 16px',
    position: 'relative'
  },
  "& .listbox": {
    height: '56px',
    borderRadius: '8px',
    "&:hover": {
      backgroundColor: "#ECF1FF",
      color: "#355EE7",
      "& .icondrawer2":
      {
        color: "#355EE7",
      },
    },
  },
  "& .listbox1": {
    height: '56px',
    backgroundColor: '#ECF1FF',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '10px'
  },
  "& .icondrawer1": {
    marginBottom: '7px',
    marginLeft: '32px',
    color: '#355EE7'
  },
  "& .icondrawer2": {
    marginLeft: '32px',
    marginBottom: '2px',
  },
  "& .listtile1": {
    fontSize: "12px",
    color: '#355EE7',
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginRight: '38px'
  },
  "& .listtile":
  {
    fontSize: "12px",
    fontFamily: 'Inter',
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: '10px'
  },
  "& .userProfile": {
    display: "flex",
    width: '252px',
    position: 'absolute',
    bottom: '10px',
    left: '32px'
  },
  "& .imageBox": {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '1px solid #F0E5FF',
    marginRight: '10px',
  },
  "& .imageBox2": {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
  },
  "& .userName": {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: 700,
    color: '#0F172A'
  },
  "& .userContent": {
    fontFamily: 'DM Sans',
    fontSize: '11px',
    fontWeight: 400,
    color: '#8C8C8C'
  },
  "@media (max-width: 1100px)":
  {
    zIndex: 999,
    width: '252px',
  },
  "@media (max-width: 991px)":
  {
    zIndex: 999,
    width: '252px',
    display: 'none',
  },
});
const ButtonBox = styled('div')(
  {
    display: 'none',
    "@media (max-width: 991px)":
    {
      width: '100%',
      display: 'block', minHeight: '70px',
      "& .buttonbox":
      {
        height: '70px', width: '100vw'
      },

      "& .iconbtn":
      {
        marginTop: '10px', marginLeft: '10px'
      }
    }
  })

// Customizable Area End