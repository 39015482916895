import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  open: boolean,
  dialogType: string, 
  checked: boolean,
  termsData:string,
  privacyData:string,
  is_shared:string,
  roleData:string,
  isAccepted:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsConditionsApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  termsApiCallId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      open: false,
      dialogType: '', 
      checked: false,
      termsData:"",
      privacyData:"",
      is_shared:'',
      roleData:'',
      isAccepted:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTermsCondition()
    this.getPrivacyPolicy()
    this.setState({is_shared: await getStorageData("is_shared")})
    this.setState({roleData: await getStorageData("roleName")})
    
  } 
  handleChange = (event: { target: { checked: boolean; }; }) => {
    this.setState({ checked: event.target.checked });
};
handleClickOpen = (dialogType: string) => {
    this.setState({ open: true, dialogType });
};
handleClose = () => {
    this.setState({ open: false });
};
handleTwoCondit=()=>{
  this.handleLawyer()
  removeStorageData("is_shared")
  removeStorageData("roleName")
}
handleTrinaryCond = (a:any, b:any, c: any) => {
  return a ? b() : c();
}
handleLawyerCondition=()=>{
return this.state.roleData ==`"lawyer"`||this.state.roleData ==`"Lawyer"`
}
handleNavigation =()=>{
  this.handleTrinaryCond(this.handleTrinaryCond(this.state.is_shared === `"true"`,
  this.handleLawyerCondition, () => {}),
  this.handleTwoCondit,
  this.handleNavigationSignIn)
}
handleLawyer=()=>{

  const message: Message = new Message(getName(MessageEnum.NavigationMessage));

  message.addData(getName(MessageEnum.NavigationTargetMessage),
   "LawyerDashboard");
  message.addData(getName(MessageEnum.NavigationPropsMessage),
   this.props);

  this.send(message);
}
handleNavigationSignIn=()=>{
  removeStorageData("roleName");
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "DashBoard");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}
getTermsCondition = async () => {
  console.log("endpoint")
  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getTermsConditionsApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.termsConditionEndPoint
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
getPrivacyPolicy = async () => {

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getPrivacyPolicyApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.privacyPolicyEndPoint
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
handleConditionalNavigation = async () => {
  const params = new URLSearchParams(window.location.search);
  const tokes = params.get('token');

  if (tokes) {
    await this.clientTermsCondition();
    this.handleClientNavigation();
  } 
  else {
    this.handleNavigation();
  }
}


handleClientNavigation=()=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage),
   "ClientDashboard");
  message.addData(getName(MessageEnum.NavigationPropsMessage),
   this.props);

  this.send(message);
}

clientTermsCondition = async () => {
  const params = new URLSearchParams(window.location.search);
  const ctokes = params.get('token');   
      setStorageData('clientTokes', ctokes);


  const header = {
    "Content-Type": "application/json",
    ...(ctokes && { "token": ctokes }) 
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.termsApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_terms_and_conditions/terms_and_conditions?is_accepted=true"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod 
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  
};

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTermsConditionsApiCallId) {     
        this.setState({termsData:responseJson?.data?.attributes?.description})      
    }   
    if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {     
        this.setState({privacyData:responseJson?.description})
    }   
    if (apiRequestCallId === this.termsApiCallId) {     
      this.setState({isAccepted:true})
  } 
    // Customizable Area End
  }
}
