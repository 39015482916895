import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landingPageData: {
    "data": {
        "id": string,
        "type": string,
        "attributes": {
            "id": number,
            "title": string,
            "description": string,
            "image": {
              url:string
            }
        }
    }
}
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLandingPageDataApiCallId :string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      landingPageData:{
        "data": {
            "id": "2",
            "type": "landing_page",
            "attributes": {
                "id": 2,
                "title": "",
                "description": "",
                "image": {
                  url:''
                }
            }
        }
    }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)  );

    if (apiRequestCallId === this.getLandingPageDataApiCallId) {
      this.setState({landingPageData:responseJson})  
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    super.componentDidMount();

    this.getLandingPage()

  } 
  getLandingPage= async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLandingPageDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_landingpage2/landing_pages"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),"GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
handleSignUp=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 
    "EmailAccountRegistration");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleLogIn=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 
    "EmailAccountLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
