export const user1 = require("../assets/user3.png");
export const user2 = require("../assets/user.jpg");
export const user3 = require("../assets/User2.png");
export const iconsetting =require("../assets/iconsetting.jpg");
export const dollericon =require("../assets/dollericon.jpg");
export const group = require("../assets/group_.svg")
export const Services = require("../assets/services.png")
export const userClient1 = require("../assets/userClient1.jpg")
export const userClient2 = require("../assets/userClient2.jpg")
export const userClient3 = require("../assets/userClient3.png")
export const tableUser = require("../assets/tableUser.png")
export const Peoples = require("../assets/peoples.png")
export const logo = require("../assets/image_logo.svg");
export const userpic = require("../assets/user.jpg");
export const earth =require("../assets/earth.png")
export const bag =require("../assets/bag.png")