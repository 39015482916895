import React from "react";
// Customizable Area Start
import {logo} from './assets'
import { Grid, Typography, Box, styled, CircularProgress } from '@material-ui/core';
import LandingPageController, { Props } from "./LandingPageController.web";
// Customizable Area End


export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {landingPageData} = this.state
    return (
      <>
        <LandingPageAboutHeading>
          {landingPageData?.data?.attributes?.description != "" ?
            <Grid container data-test-id="LandingPage">
              <Grid item xs={12} md={6} className="landingPageFirstGrid">
                <Box className="logoLanding">
                  <img src={logo} alt="Logo" className="landingPageLogo" />
                </Box>
                <Box className="landingPageAbout" >

                  <Typography variant='h4' gutterBottom className="landingPageAboutHeadingText">{landingPageData?.data?.attributes?.title}</Typography>

                  <Box>
                    <Typography paragraph className="landingPageText">
                      {landingPageData?.data.attributes.description?.replace(/[\r\n]+/g, '')}
                    </Typography>
                  </Box>
                  <Box>
                    <button onClick={this.handleSignUp} data-test-id="signup" className="landingSignupBtn"  >
                      Sign up
                    </button>
                  </Box>
                  <Box style={{ marginTop: "20px" }}>
                    <button onClick={this.handleLogIn} data-test-id="login" className="landingLoginBtn">
                      Log in
                    </button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} >
                <img src={landingPageData?.data?.attributes?.image?.url} alt="Example" className="landingPageImage" />
              </Grid>
            </Grid>
            : <span style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}><CircularProgress /></span> }
        </LandingPageAboutHeading>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const LandingPageAboutHeading = styled(Box)({
    "& .landingPageAbout":{
        width:"440px"
    },
    
    "& .landingPageAboutHeadingText":{
        fontSize:"48px",
        fontWeight:"700",
        marginBottom:"20px",
        width:"336px",
        fontFamily:"DM Sans"
    },
    "& .landingPageText":{
        width:"391px",
        fontSize:"22px",
        fontWeight:"400",
        fontFamily:"DM Sans",
        lineHeight:"26.6px",
        marginBottom:"28px"

    },
    "& .landingSignupBtn":{
        backgroundColor:"#355EE7",
        color:"white",
        width:"391px",
        height:'46px',
        textTransform:"none" as "none",
        borderRadius:"8px",
        padding:"10px",
        fontSize:"16px",
        fontWeight:"400",
        fontFamily:"DM Sans",
        cursor:"pointer",
        border:'none',
        '&:hover': {
          border:'2px solid #B9C5FF',
          boxShadow:'none !important'
        }
    },
    "& .landingLoginBtn":{
      backgroundColor:"#fff",
        width:"391px",
        height:'46px',
        textTransform:"none" as "none",
        marginBottom:"26px",
        borderRadius:"8px",
        padding:"10px",
        color:"#355ee7",
        fontSize:"16px",
        fontWeight:"400",
        fontFamily:"DM Sans",
        cursor:"pointer",
        border:'1px solid #d9d9d9',
        boxShadow:'none !important',
        '&:hover': {
          border:'2px solid #B9C5FF',
          boxShadow:'none !important'
        }

    },
    "& .landingPageImage":{
        width:"100%",
        height:"108vh",
    "@media (max-width: 1000px)": {
      height:"100vh",
    }
    },
    "& .landingPageLogo" : {
         position: "absolute", top: "20px", left: "20px", width: "100px", height: "auto" ,marginBottom:"30px"
    },
    "& .landingPageFirstGrid" : {
        display:"flex",justifyContent:"center",alignItems:"center"
    },
    "@media (max-width: 900px)": {
        "& .landingPageFirstGrid": {
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: "center"
        },
        "& .landingPageAboutHeadingText":{
            width:"100%"

        },
        "& .landingPageLogo": {
          position: "relative",
          top: "0",
          left: "0",
          margin: "20px"
        },
        "& .landingPageAbout": {
          width: "90%",
          // marginTop:"70px",
          padding: "0 20px"
        },
        "& .landingPageText": {
          width: "100%",
          fontSize: "18px"
        },
        "& .landingSignupBtn, & .landingLoginBtn": {
          width: "100%"
        },
        "& .landingPageImage": {
          width: "100%",
          height: "auto"
        }
      },
      "@media (min-width: 900px) and (max-width:2500px)": {
        "& .landingPageAbout": {
         marginTop:"70px"
        }
        
      }
    
  });
// Customizable Area End
