import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  mobileOpen: boolean;
  opendialog:boolean;
  openOption:boolean;
  closeOption:boolean;
  roledata: string | undefined;
  userdata: any,
  openOptionIndex: number,
  closebox:boolean;
  noUserData:string;
  deletePopUp: boolean;
  deleteUserData: {
    id: string;
    attributes: {
      user_name: string,
      firm_name: string,
    };
  };
  openDialogAlert:boolean;
  showResponse:{
    error:string,
    success:string
  },
  lawfirmName:string,
  detailsPopUp:boolean,
  showDetails:{
    lastName:string,
    firstName:string
  },
  jurisdiction: {
    emoji: string,
    countryName: string,
  },
  showDetailsCountry:any,
  showDetailsAttachments:any,
  countryData:any
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientLawyerCatalogueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId:string="";
  getCountryDataApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      mobileOpen: false,
      opendialog:false,
      openOption:false,
      closeOption:false,
      roledata: "",
      userdata:[],
      openOptionIndex: -1,
      closebox:false,
      noUserData:'',
      deletePopUp:false,
      deleteUserData: {
        id: "",
        attributes: {
          user_name: "",
          firm_name: "",
        }
      },
      openDialogAlert:false,
      showResponse:{
        error:"",
        success:""
      },
      lawfirmName:"",
      detailsPopUp:false,
      showDetails:{
        firstName:'',
        lastName:'',
    },
      jurisdiction: {
        emoji:"",
        countryName:"",
      },
      showDetailsCountry:[],
      showDetailsAttachments:[],
      countryData:[],
      isLoading:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserProfileApiCallId) {
      if(responseJson?.data){
        this.setState({userdata:responseJson.data})
        this.setState({isLoading:false})
      }
      else if(responseJson?.message){
        this.setState({noUserData:responseJson.message})
        this.setState({isLoading:false})
      }
    }
    if (apiRequestCallId === this.getCountryDataApiCallId) {
        this.setState({countryData:[...responseJson?.data]})
       }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        const navRes = message.getData(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        const {msg} = navRes;
        this.setState({lawfirmName:msg})
        this.getUserData()
      }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCountryList()
    // Customizable Area End
  }

  handleNavigationClientDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
        "AdvancedSearch");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
        this.props);
    
    this.send(message);
    }

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseDetailsPopUp = () =>{
    this.setState({detailsPopUp:false})
  }

  handleDetailsPopUp = async (index:number) => {

    const result = await this.state.countryData.filter((country: any) => (
        country.attributes.name == this.state.userdata[index].attributes.associated_lawyer_details.data.attributes.jurisdiction
    ))

    this.setState({
        showDetails:{
            firstName:this.state.userdata[index].attributes.associated_lawyer_details.data.attributes.first_name,
            lastName:this.state.userdata[index].attributes.associated_lawyer_details.data.attributes.last_name,
        },
        jurisdiction: {
            emoji:result[0].attributes.emoji_flag,
            countryName: result[0].attributes.name
        },
        detailsPopUp:true
    })
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }

  handleCloseBox=()=>{
    if(this.state.closebox){
      this.setState({openOptionIndex:-1,closebox:false})
    }
  }
  
  handleCloseOption=()=>{
    if(this.state.closeOption){
        this.setState({openOption:false,closeOption:false})
    }
  }
  getUserData = async () => {
    this.setState({isLoading:true})
    const clientToken: string = await getStorageData("clientTokes");


    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": clientToken,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientLayerCatalogueEndpoint+this.state.lawfirmName
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // this.setState({roledata:maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token})
  };

  // Customizable Area End
}