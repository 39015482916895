import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

export interface TaskProfile {
  isSelected: boolean;
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: any;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    id: string;
    account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
  };
}
export interface LegalServiceType {
  id: number,
  name: string
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose:boolean
  handleRejectClose:boolean;
  taskhandle: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: string;
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  taskList: ITask[];
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  taskView:{
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: LegalServiceType;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    jurisdiction:string,
    deadline:string,
    description:string
    created_at:string
  };
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  isExpanded: boolean,  
  shouldShowSeeMore: boolean, 
  acceptTask:boolean,
  rejectTask:boolean,
  isTaskPosted:boolean,
  lawyerDialog:boolean,
  selectedLawyers:ApiResponse[],
  loading:boolean,
  dataLoad:boolean

  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TasksController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallIdd="";
  assignLawyerCallId="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTaskAllocatorMessage),
      getName(MessageEnum.NavigationTaskAllocatorId),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      name: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      taskView:{
        id:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:""
            }
        },
      taskViewId:"",
      isExpanded: false, 
      shouldShowSeeMore: false, 
      acceptTask:false,
      rejectTask:false,
      isTaskPosted:false,
      lawyerDialog:false,
      selectedLawyers:[],
      loading:true,
      dataLoad:false

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTaskLists();
    
  }
  
  async componentDidUpdate(prevProps: Readonly<Props>) {
    if(prevProps.taskhandle !== this.props.taskhandle){
      this.taskLoadData()
    }
  }

  taskLoadData = ()=>{
    this.setState({dataLoad:false})
  }

  navigateToTaskList = () => {
    this.props.navigation.navigate("TaskList");
  };

  navigateToTask = () => {
    this.props.navigation.navigate("Task");
  };
  handleSeeMoreClick = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };
 

  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiLawfirmEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawyer");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleNavigateDashboard = () => {
    console.log("vaclled")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
  );

  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    if (apiRequestCallId === this.getTaskListsApiCallId) {
      if (responseJson.data) {
        this.setState({ taskProfile: responseJson.data,
          loading:false
        },
          
        );
      } else {
        this.setState({ taskProfile: [], });
      }
    } 
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson.data) {
        const status = responseJson.data.attributes.status;
        this.setState({ taskView: responseJson.data,
          isTaskPosted: status === "task_posted"
        });
      } else{
        this.setState({ 
          isTaskPosted: false
          
        });
      }
      
    }
    if(apiRequestCallId === this.assignLawyerCallId){
      if (responseJson) {
        this.setState({ selectedLawyers: responseJson.data,
         
        });
      } 
  }

  };


  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };
  handleAcceptTask = () => {
    this.setState((prevState) => ({
      acceptTask: !prevState.acceptTask,
      drawerOpen: false
    }));
  };  
  handleLawerDialog = () => {
    this.setState((prevState) => ({
      lawyerDialog: !prevState.lawyerDialog,
      drawerOpen: false
    }));
    // this.assignLawyer()
  };
  handleCloseLawyerDialog = () => {
    this.setState({lawyerDialog:false});
  };
  handleEditProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationPropsMessage));
  
    message.addData(getName(MessageEnum.NavigationProjectTemplatesWithId), this.state.taskViewId);

    this.send(message);

  }

  handleNavigateAllocator = () => {
    console.log(this.state.taskView.id,this.state.taskViewId,"meri id")
    const message: Message = new Message(getName(MessageEnum.NavigationTaskAllocatorMessage));
      message.addData(getName(MessageEnum.TaskAllocatorMessage), true);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTaskAllocatorId), this.state.taskViewId);
  
      this.send(message);
  }
  handleUpdateCalls = () => {
    this.getTaskLists();
  } 

 handleRejectTask = () => {
    this.setState((prevState) => ({
      rejectTask: !prevState.rejectTask,
      drawerOpen: false
    }));
  };

  handleClosePopup = () => {
    this.setState({ acceptTask: false});
}
handleClosed = () => {
  this.setState({rejectTask: false });
}
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallIdd = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  handleClose = () => {
    this.setState({ open: false });

  };

  toggleDrawer = (task:{id:string}) => {   
    const taskId = String(task.id); 
    
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen, 
      taskViewId: taskId, 
    }));
    this.lawfirmTasks()
    this.assignLawyer()
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false ,isExpanded:false}); 
  };



  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
 
  lawfirmTasks = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  assignLawyer = async () => {
    const signupToken: string = await getStorageData("userdetails");
    
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);

    const header: { "Content-Type": string; token: string | undefined } =
     {  "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token, "Content-Type": "application/json",
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.assignLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.assignLawyerApiEndPoint}${this.state.taskViewId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };  


  // Customizable Area End
}
