import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  mobileOpen: boolean;
  errormsg: string;
  apiImage:File|null;
  roledata: string | undefined;
  editToggel:boolean;
  serviceId:any;
  title:any;
  selectedLawyers:any;
  catalougeData:any,
  listOfServices:any,
  lawFirmData:boolean,
  changeimage: string,
  open:boolean,
  anchorEl:any,
  dataArray: { email: string }[],
  showEmail:boolean,
  isDropdownOpen:boolean,
  isDropdownOpen1:boolean,
  isFocused:boolean,
  associatedLawyers:any,
  searchQuery:string,
  openDialog:boolean,
  savechanges:{firm:boolean,website:boolean},
  userMailId:string,
  EmailErrorMsg:boolean,
  popUpShoww:boolean,
  ShowResponsee:{
    error:string,
    success:string,
  },
  LawfirmNav:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddEditServicesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  getLawFirmApiCallId:string = "";
  servicesApiCallId:string = "";
  getIndivialservicesApiCallId:string= "";
  buttonRef:any;
  listAssociatedLawyersAPICallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      mobileOpen: false,
      apiImage:null,
      anchorEl:null,
      selectedLawyers:[],
      errormsg: "",
      roledata: "",
      title:'',
      isDropdownOpen1:false,
      associatedLawyers:[],
      searchQuery:"",
      editToggel:false,
      isFocused:false,
      isDropdownOpen:false,
      serviceId:'',
      catalougeData:[],
      listOfServices:[],
     lawFirmData:false,
     changeimage:'',
     open:false,
     dataArray: [],
     savechanges:{firm:false,website:false},
     showEmail:false,
     openDialog:false,
     userMailId:"",
     EmailErrorMsg:false,
     popUpShoww:false,
     ShowResponsee:{
      error:"",
      success:"", 
    },
    LawfirmNav:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.buttonRef = React.createRef();

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const title: string = await getStorageData("title");
    const isAdd = await getStorageData('isAdd');
    const serviceId = await getStorageData('serviceId');
    this.setState({editToggel:isAdd,title:title,serviceId:serviceId})
    if(this.state.editToggel){
      this.getListofServiceType()
    }else{
      this.getListofServiceType()
      this.getIndividualServices()
    }

    this.getListofAssociatedLawyersType()
    // Customizable Area End
  }

  // Customizable Area Start


  handleClick = (event:any) => {
    this.setState({open:true,anchorEl:event.currentTarget});
  };

  handleClose = () => {
    this.setState({open:false,anchorEl:null})
  };

  handleClosePopUp = () => {
    this.setState({ openDialog: false });
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };
  handleToggleDropdown=()=>{
    this.setState({isDropdownOpen:!this.state.isDropdownOpen})
  }
  handleToggleDropdown1=()=>{
    this.setState({isDropdownOpen1:!this.state.isDropdownOpen1})
  }

  addLawyer = (lawyer:any, setFieldValue:any) => {
    const { selectedLawyers } = this.state;
    const alreadyAdded = selectedLawyers.some(
      (selected:any) => selected.account_id === lawyer.attributes.associated_lawyer_details.account_id
    );
  
    if (!alreadyAdded) {
      const newLawyers = [...selectedLawyers, lawyer.attributes.associated_lawyer_details];
      this.setState({ selectedLawyers: newLawyers });
      setFieldValue('associatedLawyers', newLawyers);
    }

  };
  

  removeLawyer = (accountId:any, setFieldValue:any) => {
    const { selectedLawyers } = this.state;
    const updatedLawyers = selectedLawyers.filter((lawyer:any) => lawyer.account_id !== accountId);
    this.setState({ selectedLawyers: updatedLawyers });
    setFieldValue('associatedLawyers', updatedLawyers);
  };
  

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
 
  handleServiceManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ServicesManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleLawFirmManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };

  handleAdminLawyer = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage

      ));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage), "AdminLawyerManagement");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(
      message);
  }
  handleDashboard = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage), "DashBoard");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  getListofServiceType = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLawFirmApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ roledata: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token });
  };
  getListofAssociatedLawyersType = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listAssociatedLawyersAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_productdescription/service_managements/associated_lawyers_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ roledata: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token });
  };
  getIndividualServices = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const header = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIndivialservicesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_productdescription/service_managements/individual_service?service_id=${this.state.serviceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ roledata: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token });
  };

  addNewServices = async (values: {  serviceType: any,  serviceName: any,  serviceDescription:any,  additionalInformation: any,  minValue:any,  maxValue:any,  estimateddays:any,
  }) => {
    const signupToken: string = 
    await getStorageData("userdetails");
    const loginToken: string = 
    await getStorageData("token");
    const metaSignUpTokens = 
    JSON.parse(signupToken);
    const metaLoginTokens = 
    JSON.parse(loginToken);

    const maintokens = 
    this.determineMainToken(metaSignUpTokens, metaLoginTokens);
    const header = {
      "token": 
      maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token
    };
    const formData = new FormData();
      formData.append("service_name", values.serviceName);   formData.append("legel_service_type_ids[]", values.serviceType.join(','));
      formData.append("service_description", values.serviceDescription);    formData.append("additional_information", values.additionalInformation);
      formData.append("start_price", values.minValue);    formData.append("end_price", values.maxValue);    formData.append("expected_time_estimate", values.estimateddays);
      this.state.selectedLawyers.forEach((lawyer:any) => {
      formData.append("associated_lawyer_ids[]", lawyer.account_id);
      
    });

    const requestMessage = new Message(    getName(MessageEnum.RestAPIRequestMessage)  );
    this.servicesApiCallId = 
    requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_productdescription/service_managements/service_create`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateServices = async (values: {
    serviceType: any,
    serviceName: any,
    serviceDescription:any,
    additionalInformation: any,
    minValue:any,
    maxValue:any,
    estimateddays:any,
  }) => {
    const signupToken: string = await getStorageData("userdetails");
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintokens = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
    const header = {
      "token": maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token
    };
    const formData = new FormData();
      formData.append("service_name", values.serviceName);
      formData.append("legel_service_type_ids[]", values.serviceType.join(','));
      formData.append("service_description", values.serviceDescription);
      formData.append("additional_information", values.additionalInformation);
      formData.append("start_price", values.minValue);
      formData.append("end_price", values.maxValue);
      formData.append("expected_time_estimate", values.estimateddays);
      this.state.selectedLawyers.forEach((lawyer:any) => {
        formData.append("associated_lawyer_ids[]", lawyer.account_id);
      });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.servicesApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_productdescription/service_managements/update_service?service_id=${this.state.serviceId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleUpdateMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getLawFirmApiCallId) {
      if (responseJson?.errors) {
      this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0]?.token,success:""}})
      }
      else {
        this.setState({ listOfServices: responseJson, lawFirmData: true,changeimage:responseJson?.data?.attributes?.image?.url  })
      }
    }
    if (apiRequestCallId === this.servicesApiCallId) {
      if(responseJson?.data){
        this.setState({ catalougeData:responseJson.data,popUpShoww:true,ShowResponsee:{error:"",success:responseJson?.meta?.message || "Service Updated SuccessFully"}})
        setTimeout(() => {
          this.getListofServiceType()
          this.handleServiceManagement()
        },500)
      }
      else if(responseJson?.errors[0]){
        this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0],success:""}})
      }
       
    }
    if (apiRequestCallId === this.getIndivialservicesApiCallId) {
      if(responseJson?.data){
        this.setState({ catalougeData:responseJson.data,selectedLawyers:responseJson?.data?.attributes?.associated_lawyer_details})
      }
      else if(responseJson?.errors[0]){
        this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0],success:""}})
      }
       
    }
    if (apiRequestCallId === this.listAssociatedLawyersAPICallId){
      if(responseJson?.data){
        this.setState({ associatedLawyers:responseJson?.data})
      }
      else if(responseJson?.message){
        this.setState({associatedLawyers:[],popUpShoww:true,ShowResponsee:{error:responseJson?.message,success:""}})
      }
    }
    // Customizable Area End
  }

  // Customizable Area End
}
