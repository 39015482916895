import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
export interface TaskProfile {
  isSelected: boolean;
  id: string;
  attributes: {
    task_name: string;
    legel_service_type: any;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    id: string;
    account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
  };
}
export interface LegalServiceType {
  id: number,
  name: string
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start


  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  taskView:{
  ids: string;
  attributes: {
    task_name: string;
    legel_service_type: LegalServiceType;
    trademark_name: string;
    trademark_application_number: string;
    state: string;
    status: string;
    jurisdiction:string,
    deadline:string,
    description:string
    created_at:string
  };
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  isExpanded:boolean,
  loading:boolean,
  associatedLawyer: ApiResponse[];
  isTaskPosted:boolean,
  selectedLawyers:ApiResponse[];
  selectedLawyersID: number[]
  updatedSelectedID: number[],
  isAnyLawyerSelected:boolean,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskAllocatorController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getTaskAllocatorApiId = "";
  getAssociatedLawyersId = "";
  patchLawyerCallId = "";
  assignLawyerCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
  
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      updatedSelectedID: [],
      isVisibleModal: false,
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      selectedLawyers:[],
      taskView:{
        ids:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:""
            }
        },
      taskViewId:"",
      isExpanded:false,
      loading:true,
      associatedLawyer:[],
      isTaskPosted:false,
      selectedLawyersID: [],
      isAnyLawyerSelected:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.associatedLawyers()
    }

  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "projectTemplates");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleNavigateDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleNavigateAllocator = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskAllocator");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getAssociatedLawyersId){
     this.handleApiResponse(responseJson)
  }
  if(apiRequestCallId === this.assignLawyerCallId){
   this.handleAssign(responseJson)
}
if(apiRequestCallId === this.patchLawyerCallId){
 this.handlePatchResponse(responseJson)
}
if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
  let taskId = message.getData(getName(MessageEnum.NavigationTaskAllocatorId));
  this.setState({taskViewId:taskId},() =>  this.assignLawyer())
}
  };
  handleApiResponse = (responseJson:any) => {
      if (responseJson.data) {
        this.setState({
          associatedLawyer: responseJson.data,
          loading: false
        });
      }
  };
  handlePatchResponse=(responseJson:any)=>{
    if(responseJson){
      this.setState({ openDialog: true });
      setTimeout(() => {
        this.setState({ openDialog: false });
      }, 800);
    }
  }
  handleAssign=(responseJson:any)=>{
   
    if (responseJson.data) {
      const selectedIds = responseJson?.data.map((element: any) => (
        element.id
      ))
       this.setState({ selectedLawyers: responseJson.data, selectedLawyersID: selectedIds});
    }
  }

  handleLawyerSelect = (associatedId: number) => {
    if(!this.state.updatedSelectedID?.includes(associatedId)){
    const updated = [...this.state.updatedSelectedID, associatedId];
    this.setState({
      updatedSelectedID: updated,
      isAnyLawyerSelected: updated.length > 0
    })
   }else{
    const removedUpdated = this.state.updatedSelectedID.filter((element) => element !== associatedId);
    this.setState({
      updatedSelectedID: removedUpdated
      ,isAnyLawyerSelected: removedUpdated.length > 0, 
    })
   }
   
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  


  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };


  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };
  associatedLawyers = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssociatedLawyersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.associatedLawyersApi}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateLawyers = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.patchApiAllocator}${this.state.taskViewId}&assigned_lawyer_ids[]=${[...this.state.selectedLawyersID, this.state.updatedSelectedID]}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  assignLawyer = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignLawyerCallId = requestMessage.messageId;

    requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.assignLawyerApiEndPoint}${this.state.taskViewId}`
        );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  // Customizable Area End
}
